import React, { useState } from "react";
import { useAuth } from "../api/AuthContext";
import { AdminCreateUserPopup } from "../components/admin/AdminCreateUserPopup";
import { AdminSettings } from "../components/admin/AdminSettings";
import { AdminTopBar } from "../components/admin/AdminTopBar";
import { CompanyList } from "../components/admin/CompanyList";
import { ConfirmPushNotificationPopup } from "../components/admin/ConfirmPushNotificationPopup";
import { UserCreateCompany } from "../components/admin/UserCreateCompany";
import { showError, showSuccess } from "../util";
import useSearchParamsState from "../hooks/useSearchParamsState";

type Props = {
  className?: string;
};

export const Admin: React.FC<Props> = ({ className }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const { api } = useAuth();

  const [selectedPage, setSelectedPage] = useSearchParamsState<number>(
    "page",
    1
  );

  const [createUserPopupOpen, setCreateUserPopupOpen] = useState(false);
  const [userCreateCompanyPopupOpen, setUserCreateCompanyPopupOpen] =
    useState(false);

  const [pushNotificationTitle, setPushNotificationTitle] = useState("");
  const [pushNotificationMessage, setPushNotificationMessage] = useState("");
  const [confirmPushNotificationOpen, setConfirmPushNotificationOpen] =
    useState(false);

  function sendPushConfirmed() {
    setConfirmPushNotificationOpen(false);
    api
      .sendPush({
        title: pushNotificationTitle,
        body: pushNotificationMessage,
      })
      .then(() => {
        showSuccess("Push Nachricht wurde erfolgreich versendet");
      })
      .catch(() => {
        showError("Push Nachricht konnte nicht versendet werden");
      })
      .finally(() => {
        setPushNotificationTitle("");
        setPushNotificationMessage("");
      });
  }

  function cancelPush() {
    setConfirmPushNotificationOpen(false);
  }

  return (
    <div className="flex h-full min-h-screen w-full flex-col justify-start bg-background text-text items-center">
      {confirmPushNotificationOpen && (
        <ConfirmPushNotificationPopup
          cancel={() => cancelPush()}
          confirm={() => sendPushConfirmed()}
        />
      )}

      {isAdmin && createUserPopupOpen && (
        <AdminCreateUserPopup close={() => setCreateUserPopupOpen(false)} />
      )}
      {userCreateCompanyPopupOpen && (
        <UserCreateCompany close={() => setUserCreateCompanyPopupOpen(false)} />
      )}
      <AdminTopBar
        isAdmin={isAdmin}
        openCreateUser={() => setCreateUserPopupOpen(true)}
      />
      <AdminSettings />
      {!createUserPopupOpen && (
        <div className="flex flex-col w-full h-full justify-start items-center px-8">
          <CompanyList
            isAdmin={isAdmin}
            setIsAdmin={setIsAdmin}
            openUserCreateCompanyPopup={() =>
              setUserCreateCompanyPopupOpen(true)
            }
            sendNotification={(title, message) => {
              setPushNotificationTitle(title);
              setPushNotificationMessage(message);
              setConfirmPushNotificationOpen(true);
            }}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
          />
        </div>
      )}
    </div>
  );
};
