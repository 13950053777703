import React from "react";
import { Campaign } from "../../api/data-contracts";
import { imageUrlById } from "../../util";
import { PromotionCard } from "./PromotionCard";

type Props = {
  className?: string;
  campaigns: Campaign[] | undefined;
};

export const PromotionsSection: React.FC<Props> = ({
  className,
  campaigns,
}) => {
  return (
    <div className="relative flex flex-1 flex-col mx-12 md:mx-24 lg:mx-32 xl:mx-48 pb-12 mb-12 border-b border-b-accentLight">
      <div className={"flex flex-col w-full gap-4"}>
        {campaigns && campaigns.length > 0 && (
          <p className="text-left font-semibold text-text text-2xl tracking-wider uppercase">
            Unsere Aktionen
          </p>
        )}
        <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6 gap-8">
          {campaigns &&
            campaigns.length > 0 &&
            campaigns.map((campaign, index) => (
              <PromotionCard
                key={campaign.id}
                imageId={
                  campaign.imageIds.length > 0 && campaign.imageIds[0]
                    ? campaign.imageIds[0]
                    : ""
                }
                title={campaign.title}
                validityDate={
                  "Gültig bis " +
                  new Date(campaign.validUntil).toLocaleDateString("de-DE", {
                    day: "2-digit",
                    month: "long",
                    year: "numeric",
                  })
                }
              />
            ))}
        </div>
      </div>
    </div>
  );
};
