import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../api/AuthContext";
import { PageCategory } from "../../api/data-contracts";
import { imageUrlById } from "../../util";
import { ArrowButton } from "../common/ArrowButton";
import { FilterIcon } from "../common/FilterIcon";

type Props = {
  className?: string;
  name: string;
  description: string;
  categoryIds: string[];
};

export const HeaderSection: React.FC<Props> = ({
  className,
  name,
  description,
  categoryIds,
}) => {
  const { api } = useAuth();
  const navigate = useNavigate();
  function back() {
    navigate(-1);
  }

  const [filters, setFilters] = useState<PageCategory>();
  useEffect(() => {
    api.getList({ size: 100 }).then((res) => {
      setFilters(res.data as PageCategory);
    });
  }, [api]);

  function getFilterWithId(id: string) {
    if (!filters) {
      return null;
    }
    return filters?.content?.find((filter) => filter.id === id);
  }

  return (
    <div className="relative flex flex-1 flex-col px-12 md:px-24 lg:px-32 xl:px-48 desktop:pb-16 mt-8 desktop:mt-0">
      <div className="flex flex-col desktop:flex-row w-full gap-8 desktop:gap-24 md:gap-32">
        <div className="flex flex-col w-full gap-4">
          <p className="text-left font-bold text-text text-3xl tracking-wider uppercase">
            {name}
          </p>
          <div className="flex flex-row flex-wrap gap-2">
            {categoryIds.map((categoryId) => (
              <FilterIcon
                key={categoryId}
                size={10}
                iconPath={imageUrlById(
                  getFilterWithId(categoryId)?.imageId ?? ""
                )}
                color={getFilterWithId(categoryId)?.colorHex ?? ""}
              />
            ))}
          </div>
        </div>
        <p className="font-normal text-text text-lg text-left whitespace-pre-line">
          {description.replaceAll(/\\n/g, "\n")}
        </p>

        <ArrowButton
          onClick={back}
          direction="left"
          className="absolute left-2 md:left-4"
        />
      </div>
    </div>
  );
};
