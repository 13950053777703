import React from "react";
import { Category } from "../../api/data-contracts";
import { FilterTag } from "./FilterTag";

type Props = {
  className?: string;
  tags: Category[];
  onRemove: (filter: Category) => void;
};

export const FilterTagList: React.FC<Props> = ({
  className,
  tags,
  onRemove,
}) => {
  return tags && tags.length > 0 ? (
    <div className="flex flex-wrap gap-2 mt-4">
      {tags.map((filter, index) => (
        <FilterTag
          filter={filter ?? ""}
          removeTag={(filter) => onRemove(filter)}
        />
      ))}
    </div>
  ) : null;
};
