import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuth } from "../api/AuthContext";
import { LoadingIndicator } from "../components/common/LoadingIndicator";
import { showError } from "../util";
export default function Confirm() {
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  const { tokenConfirmCode } = useAuth();

  const confirm = async () => {
    if (code) {
      try {
        await tokenConfirmCode(code);
        window.location.href = "/manage";
      } catch (error) {
        showError(error);
      }
    }
  };

  useEffect(() => {
    confirm();
  }, []);

  return (
    <div className="flex h-screen w-screen items-center justify-center bg-background">
      <LoadingIndicator />
    </div>
  );
}
