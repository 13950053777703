import { toast } from "react-toastify";

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function imageUrlById(id) {
  const baseUrl = process.env.REACT_APP_API_URL;
  return baseUrl + "/api/media/" + id;
}

export function calculateDistanceInKm(lat1, lon1, lat2, lon2) {
  const d = getDistance(lat1, lon1, lat2, lon2);
  if (d < 1) {
    return Math.round(d * 1000) + " m";
  } else {
    return d.toFixed(2) + " km";
  }
}

export function getDistance(lat1, lon1, lat2, lon2) {
  var R = 6371;
  var dLat = deg2rad(lat2 - lat1);
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d;
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

export function validateEmail(email) {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
}

export function asDayDate(text) {
  const date = new Date(text);
  return (
    date.getFullYear() +
    "-" +
    ((date.getMonth() + 1).toString().length === 2
      ? date.getMonth() + 1
      : "0" + (date.getMonth() + 1)) +
    "-" +
    (date.getDate().toString().length === 2
      ? date.getDate()
      : "0" + date.getDate())
  );
}

export function showError(text) {
  toast.error(text, {
    position: "bottom-right",
    theme: "colored",
    autoClose: 10000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    bodyClassName: "text-backgroundSecondary",
    className: "bg-highlight !important font-semibold text-sm m-2 rounded-md",
  });
}

export function showSuccess(text) {
  toast.success(text, {
    position: "bottom-right",
    theme: "colored",
    autoClose: 10000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    bodyClassName: "text-backgroundSecondary",
    className: "bg-accent !important font-semibold text-sm m-2 rounded-md",
  });
}

export function delay(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export function hasNumber(text) {
  return /\d/.test(text);
}

export function hasLowerAndUppercase(text) {
  return /[a-z]/.test(text) && /[A-Z]/.test(text);
}
