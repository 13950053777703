import React from "react";
import { classNames } from "../../util";

type Props = {
  className?: string;
  black?: boolean;
  white?: boolean;
};

export const LoadingIndicator: React.FC<Props> = ({
  className,
  black,
  white,
}) => {
  return (
    <div className={classNames("flex justify-center items-center", className)}>
      <div className="relative animate-spin inline-block w-8 h-8 border-4 border-accent rounded-full">
        <div
          className={classNames(
            "absolute top-1 rotate-45 left-4 h-4 w-4",
            black
              ? "bg-black"
              : white
              ? "bg-backgroundSecondary"
              : "bg-background"
          )}
        ></div>
      </div>
    </div>
  );
};
