import { UploadIcon, XCircleIcon } from "@heroicons/react/solid";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { classNames, imageUrlById, showError } from "../../../util";
import { ChangedIndicator } from "./ChangedIndicator";

type Props = {
  className?: string;
  imageIds: string[] | undefined;
  onChange: (imageIds: string[]) => void;
  wasEdited: boolean;
  images: File[];
  onRemoveId: (imageId: string) => void;
  onRemoveImage: (image: File) => void;
  onAddImage: (image: File) => void;
};

export const CompanyImages: React.FC<Props> = ({
  className,
  imageIds,
  onChange,
  wasEdited,
  images,
  onRemoveId,
  onRemoveImage,
  onAddImage,
}) => {
  const [hasRights, setHasRights] = useState(false);

  return (
    <div className="flex flex-col">
      <p className="text-accent font-semibold text-sm text-left mb-1">
        {"Bilder"}
      </p>
      <div className={"flex flex-row flex-wrap gap-2 w-full"}>
        {/* Server Images */}
        {imageIds &&
          imageIds.length > 0 &&
          imageIds.map((imageId) => (
            <div
              className="relative group cursor-pointer transition-all"
              key={imageId}
            >
              <img
                src={imageUrlById(imageId)}
                className="aspect-auto h-32 rounded-md min-w-[16px]"
                alt="images"
              />

              {/* Remove icon */}
              <div
                className="absolute top-2 right-0 items-center text-accent text-sm gap-2"
                onClick={() => {
                  onChange(imageIds.filter((id) => id !== imageId));
                  onRemoveId(imageId);
                }}
              >
                <div className="h-4 w-4 bg-white rounded-full opacity-0 group-hover:opacity-100 z-0 transition-all" />
                <XCircleIcon className="w-6 h-6 text-transparent hover:opacity-70 group-hover:text-red -translate-x-1 -translate-y-5 transition-all" />
              </div>
            </div>
          ))}

        {/* Local Images */}
        {images &&
          images.length > 0 &&
          images.map((file) => (
            <div
              className="relative group cursor-pointer transition-all"
              key={file.name}
            >
              <img
                src={URL.createObjectURL(file)}
                className="aspect-auto h-32 rounded-md min-w-[16px]"
                alt="images"
              />
              {/* Remove icon */}
              <div
                className="absolute top-2 right-0 items-center text-accent text-sm gap-2"
                onClick={() => {
                  onRemoveImage(file);
                }}
              >
                <div className="h-4 w-4 bg-white rounded-full opacity-0 group-hover:opacity-100 z-0 transition-all" />
                <XCircleIcon className="w-6 h-6 text-transparent hover:opacity-70 group-hover:text-red -translate-x-1 -translate-y-5 transition-all" />
              </div>
            </div>
          ))}

        <motion.div
          className="relative  h-32  flex flex-col justify-center group items-center px-8 bg-backgroundSecondary cursor-not-allowed rounded-md text-accent text-sm gap-4"
          animate={{ opacity: hasRights ? 1 : 0.4 }}
          whileHover={{ opacity: hasRights ? 0.7 : 0.4 }}
          transition={{ duration: 0.1 }}
        >
          <UploadIcon className="w-4 h-4" />
          <div className="flex flex-col gap-0 font-bold">
            <p>Bild</p>
            <p>hochladen</p>
            <p className="opacity-50 text-xs">(max 5MB)</p>
          </div>
          {hasRights && (
            <input
              className={classNames(
                "absolute inset-0 opacity-0 cursor-pointer"
              )}
              type="file"
              accept="image/*"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) {
                  if (file.size < 5000000) {
                    onAddImage(file);
                  } else {
                    showError("Bilder dürfen maximal 5MB haben");
                  }
                }
              }}
            />
          )}
        </motion.div>
        <ChangedIndicator wasEdited={wasEdited} />
      </div>
      <div className="text-text font-semibold text-sm text-left mt-2 w-full flex gap-2 flex-row justify-start items-center">
        <input
          type="checkbox"
          className="h-4 w-4 border-accent ring-0 text-accent rounded-md transition-all cursor-pointer"
          checked={hasRights}
          onChange={() => setHasRights((prev) => !prev)}
        />
        <p
          className="pt-1 cursor-pointer"
          onClick={() => setHasRights((prev) => !prev)}
        >
          Ich bestätige, dass ich im Besitz der Bildrechte für die hochgeladenen
          Fotos bin.
        </p>
      </div>
    </div>
  );
};
