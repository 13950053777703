import React from "react";
import { Searchbar } from "../common/Searchbar";

type Props = {
  className?: string;
  searchTextChanged: (value: string) => void;
};

export const NavigationBarSearch: React.FC<Props> = ({
  className,
  searchTextChanged,
}) => {
  return <Searchbar debouncedUpdate={searchTextChanged} />;
};
