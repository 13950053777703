import React from "react";
import { classNames } from "../../../util";
import { AdminInputTag } from "./AdminInputTag";
import { AdminTag } from "./AdminTag";
import { ChangedIndicator } from "./ChangedIndicator";

type Props = {
  className?: string;
  tags: string[] | undefined;
  onChange: (tags: string[]) => void;
  wasEdited: boolean;
};

export const CompanyTags: React.FC<Props> = ({
  className,
  tags,
  onChange,
  wasEdited,
}) => {
  return (
    <div className="flex flex-col">
      <p className="text-accent font-semibold text-sm text-left mb-1">
        {"Schlagwörter für Suche"}
      </p>
      <div className={classNames("flex flex-row gap-2 flex-wrap")}>
        {tags &&
          tags.length > 0 &&
          tags.map((tag) => (
            <AdminTag
              key={tag}
              name={tag}
              removeTag={(name) =>
                onChange(
                  tags.filter((e) => {
                    return e !== name;
                  })
                )
              }
            />
          ))}
        <AdminInputTag
          addTag={(tag) => {
            onChange([...(tags ?? []), tag]);
          }}
        />
        <ChangedIndicator wasEdited={wasEdited} />
      </div>
    </div>
  );
};
