import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { motion } from "framer-motion";
import React from "react";
import { classNames } from "../../util";

type Props = {
  className?: string;
  direction?: "left" | "right";
  active?: boolean;
  onClick: () => void;
};

export const ArrowButton: React.FC<Props> = ({
  children,
  className,
  direction = "left",
  active = true,
  onClick,
}) => {
  return (
    <motion.div
      className={classNames(
        "flex flex-row items-center gap-2",
        active ? "cursor-pointer hover:opacity-90" : "opacity-50",
        className
      )}
      whileHover={{
        scale: active ? (children ? 1.05 : 1.2) : 1,
        transition: { duration: 0.1 },
      }}
      onClick={() => active && onClick()}
    >
      <div
        className={classNames(
          "flex h-7 w-7 ml-1 rounded-full bg-accent justify-center items-center"
        )}
      >
        {direction === "left" ? (
          <ChevronLeftIcon className="h-6 w-6 text-backgroundSecondary" />
        ) : (
          <ChevronRightIcon className="h-6 w-6 text-backgroundSecondary" />
        )}
      </div>
      <div className="flex justify-center h-7 items-center">{children}</div>
    </motion.div>
  );
};
