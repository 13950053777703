import {
  GlobeAltIcon,
  MailIcon,
  PhoneIcon,
  PlusIcon,
  XCircleIcon,
} from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { Company, Store } from "../../../api/data-contracts";
import { AddStorePopup } from "./AddStorePopup";
import { ChangedIndicator } from "./ChangedIndicator";

type Props = {
  className?: string;
  stores: Store[] | undefined;
  company: Company | undefined;
  onChange: (stores: Store[]) => void;
  wasEdited: boolean;
};

export const CompanyStores: React.FC<Props> = ({
  className,
  stores,
  company,
  onChange,
  wasEdited,
}) => {
  const [addPopupOpen, setAddPopupOpen] = useState(false);

  const [editedStores, setEditedStores] = useState(stores);
  useEffect(() => {
    setEditedStores(stores);
  }, [stores]);

  useEffect(() => {
    onChange(editedStores ?? []);
  }, [editedStores, onChange]);

  return (
    <div className="flex flex-col">
      {/* Add Popup */}
      {addPopupOpen && (
        <AddStorePopup
          close={() => setAddPopupOpen(false)}
          addStore={(newStore) => {
            setEditedStores((prev) => [...(prev ?? []), newStore]);
            setAddPopupOpen(false);
          }}
          isFirst={
            editedStores === undefined ||
            editedStores === null ||
            editedStores?.length === 0
          }
          company={company}
        />
      )}
      <p className="text-accent font-semibold text-sm text-left mb-1">
        {"Standorte"}
      </p>
      <div className={"flex flex-row flex-wrap gap-2 w-full"}>
        {editedStores &&
          editedStores.length > 0 &&
          editedStores.map((store) => (
            <div
              className="group relative flex flex-col items-start gap-1 cursor-pointer text-sm transition-all bg-backgroundSecondary p-4 rounded-md"
              key={store.id}
            >
              <p className="font-bold text-accent text-base">{store.title}</p>
              <div className="flex flex-col items-start">
                <p>{store.street}</p>
                <p>
                  {store.postCode} {store.town}
                </p>
              </div>

              {store.email !== "" && (
                <p className="text-text flex flex-row items-center gap-2">
                  <MailIcon className="w-3 h-3 text-text" />
                  {store.email}
                </p>
              )}
              {store.phone !== "" && (
                <p className="text-text flex flex-row items-center gap-2">
                  <PhoneIcon className="w-3 h-3 text-text" />
                  {store.phone}
                </p>
              )}
              {store.website !== "" && (
                <p className="text-accent flex flex-row items-center gap-2">
                  <GlobeAltIcon className="w-3 h-3" />
                  {store.website}
                </p>
              )}

              {/* Remove icon */}
              <div
                className="absolute top-2 right-0 items-center text-accent text-sm gap-2"
                onClick={() =>
                  setEditedStores((prev) =>
                    prev?.filter((item) => item.id !== store.id)
                  )
                }
              >
                <div className="h-4 w-4 bg-white rounded-full opacity-0 group-hover:opacity-100 z-0 transition-all" />
                <XCircleIcon className="w-6 h-6 text-transparent hover:opacity-70 group-hover:text-red -translate-x-1 -translate-y-5 transition-all" />
              </div>
            </div>
          ))}
        <div
          className="flex flex-col justify-center items-center w-40 min-h-24 bg-backgroundSecondary hover:opacity-70 cursor-pointer text-accent p-4 rounded-md"
          onClick={() => setAddPopupOpen(true)}
        >
          <PlusIcon className="w-4 h-4" />
          <div className="flex flex-col gap-0 font-bold">
            <p>Standort</p>
            <p>hinzufügen</p>
          </div>
        </div>
        <ChangedIndicator wasEdited={wasEdited} />
      </div>
    </div>
  );
};
