import { AnimatePresence, motion } from "framer-motion";
import React, { useRef, useState } from "react";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { classNames } from "../../util";

type Props = {
  className?: string;
  children: React.ReactNode;
  options: {
    label: string;
    onClick: () => void;
  }[];
};

export const ContextMenuButton: React.FC<Props> = ({
  className,
  children,
  options,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const ref = useRef(null);
  useOnClickOutside(ref, () => setIsExpanded(false));

  return (
    <motion.div
      ref={ref}
      className={classNames(
        "relative text-sm md:text-base font-semibold rounded-md md:px-5 px-3 py-2 md:py-1 items-center flex flex-row justify-center transition-all hover:bg-opacity-90 cursor-pointer text-backgroundSecondary bg-accent",
        className
      )}
      onClick={() => setIsExpanded(!isExpanded)}
    >
      {children}
      {isExpanded && (
        <motion.div
          className="absolute top-10 right-0 flex flex-col items-start justify-start h-fit w-fit z-50 rounded-md overflow-hidden"
          initial={{ opacity: 0, y: -50, scaleX: 0, scaleY: 0, originX: 1 }}
          animate={{ opacity: 1, y: 0, scaleX: 1, scaleY: 1 }}
          transition={{ duration: 0.2 }}
        >
          {options.map((option) => (
            <p
              key={option.label}
              className="whitespace-nowrap text-left py-2 px-4 hover:opacity-70 bg-accent w-full"
              onClick={() => {
                option.onClick();
              }}
            >
              {option.label}
            </p>
          ))}
        </motion.div>
      )}
    </motion.div>
  );
};
