import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../api/AuthContext";
import {
  Company,
  PageCategory,
  SpecialOfferDetails,
} from "../../api/data-contracts";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { asDayDate, classNames, imageUrlById } from "../../util";
import { LoadingIndicator } from "../common/LoadingIndicator";
import { PageSelector } from "../common/PageSelector";
import { Searchbar } from "../common/Searchbar";
import { Toggle } from "../common/Toggle";
import { EditFilter } from "./EditFilter";
import { PushNotification } from "./PushNotification";

type Props = {
  className?: string;
  isAdmin: boolean;
  setIsAdmin: (val: boolean) => void;
  openUserCreateCompanyPopup: () => void;
  sendNotification: (title: string, body: string) => void;
  selectedPage: number;
  setSelectedPage: (number: number) => void;
};

export const CompanyList: React.FC<Props> = ({
  className,
  isAdmin,
  setIsAdmin,
  openUserCreateCompanyPopup,
  sendNotification,
  selectedPage,
  setSelectedPage,
}) => {
  const { api, me } = useAuth();

  const [isLoading, setisLoading] = useState(false);

  const [companies, setCompanies] = useState<Company[]>();
  const [searchTextHasChanged, setSearchTextHasChanged] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [totalPages, setTotalPages] = useState(0);

  const [reload, setReload] = useState(0);

  const [updateSpecialOffers, setUpdateSpecialOffers] = useState(0);
  const [specialOffers, setSpecialOffers] = useState<
    SpecialOfferDetails[] | undefined
  >(undefined);

  function changeActiveState(company: Company, newState: boolean) {
    if (newState === true) {
      api.setCompanyActive(company.id).then(() => {
        setReload((prev) => prev + 1);
      });
    } else {
      api.setCompanyInactive(company.id).then(() => {
        setReload((prev) => prev + 1);
      });
    }
  }

  const [filters, setFilters] = useState<PageCategory>();
  useEffect(() => {
    api.getList({ size: 100 }).then((res) => {
      setFilters(res.data as PageCategory);
    });
  }, [api]);

  function getFilterWithId(id: string) {
    if (!filters) {
      return null;
    }
    return filters?.content?.find((filter) => filter.id === id);
  }

  useEffect(() => {
    if (searchTextHasChanged) {
      setSelectedPage(1);
    }
  }, [searchText]);

  useEffect(() => {
    setisLoading(true);
    me.me()
      .then((me) => {
        if (me.data.admin) {
          setIsAdmin(true);
          api
            .companies1({
              searchQuery: searchText,
              page: selectedPage - 1,
              size: 8,
            })
            .then((companies) => {
              setCompanies(companies.data.content as Company[]);
              setTotalPages(companies.data.totalPages ?? 0);
            })
            .catch((error) => {
              console.log(error);
              setCompanies([]);
            })
            .finally(() => {
              setisLoading(false);
            });
          api
            .getActiveOffers({
              headers: {
                platform: "web",
              },
            })
            .then((res) => {
              setSpecialOffers(res.data as SpecialOfferDetails[]);
            });
        } else {
          api
            .companies()
            .then((companies) => {
              setCompanies(companies.data as Company[]);
            })
            .catch((error) => {
              console.log(error);
              setCompanies([]);
            })
            .finally(() => {
              setisLoading(false);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        setCompanies([]);
      });
  }, [
    api,
    me,
    searchText,
    selectedPage,
    reload,
    updateSpecialOffers,
    setIsAdmin,
  ]);

  const navigate = useNavigate();
  function openDetail(id: string) {
    navigate(`/manage/detail/${id}`);
  }

  function selectOffer(selectedOfferID: string | undefined, company: Company) {
    if (specialOffers !== undefined && selectedOfferID !== undefined) {
      const offer = specialOffers.find((o) => o.id === selectedOfferID);
      const companyIds = offer?.companies.map((company) => company.id) ?? [];
      companyIds.push(company.id);

      api
        .updateOffer(offer?.id ?? "", {
          title: offer?.title ?? "",
          //imageId: offer?.imageId ?? "",
          startDate: offer?.startDate ?? "",
          endDate: offer?.endDate ?? "",
          companyIds: companyIds,
        })
        .then((res) => {
          setUpdateSpecialOffers((prev) => prev + 1);
          setFilterSelectorOpenID(undefined);
        })
        .catch((err) => console.log("err", err));
    } else if (specialOffers !== undefined && selectedOfferID === undefined) {
      for (let index = 0; index < specialOffers.length; index++) {
        const offer = specialOffers[index];
        let companyIds = offer?.companies.map((company) => company.id) ?? [];
        companyIds = companyIds.filter((id) => id !== company.id);
        api
          .updateOffer(offer?.id, {
            title: offer.title,
            //imageId: offer.imageId,
            startDate: offer.startDate,
            endDate: offer.endDate,
            companyIds: companyIds,
          })
          .then((res) => {
            setUpdateSpecialOffers((prev) => prev + 1);
            setFilterSelectorOpenID(undefined);
          })
          .catch((err) => console.log("err", err));
      }
    }
  }

  const [filterSelectorOpenID, setFilterSelectorOpenID] = useState<
    string | undefined
  >(undefined);

  function specialOfferTitle(company: Company) {
    const title =
      specialOffers && specialOffers.length > 0
        ? specialOffers?.map((specialOffer) =>
            specialOffer.companies.find((c) => c.id === company.id)
              ? specialOffer.title
              : "Kein Filter"
          )
        : "Kein Filter";

    return title;
  }

  const filterSelectorRef = useRef(null);
  useOnClickOutside(filterSelectorRef, () =>
    setFilterSelectorOpenID(undefined)
  );

  return (
    <div className={"flex flex-col h-full w-full gap-2 mb-16"}>
      <div className={classNames(isAdmin ? "mt-16" : "mt-4", "w-full")}>
        {isAdmin && (
          <div className="grid xl:grid-cols-3 gap-4">
            <EditFilter
              className="xl:col-span-2"
              key={JSON.stringify(specialOffers)}
              update={() => setUpdateSpecialOffers((prev) => prev + 1)}
              filterStartDate={
                specialOffers && specialOffers.length > 0
                  ? asDayDate(specialOffers[0].startDate)
                  : undefined
              }
              filterEndDate={
                specialOffers && specialOffers.length > 0
                  ? asDayDate(specialOffers[0].endDate)
                  : undefined
              }
              filterName={
                specialOffers && specialOffers.length > 0
                  ? specialOffers[0].title
                  : ""
              }
              filterOfferId={
                specialOffers && specialOffers.length > 0
                  ? specialOffers[0].id
                  : undefined
              }
              filterCompanyIds={
                specialOffers && specialOffers.length > 0
                  ? specialOffers[0].companies.map((company) => company.id)
                  : undefined
              }
              filterImageId={
                specialOffers && specialOffers.length > 0
                  ? specialOffers[0].imageId
                  : undefined
              }
              filterId={
                specialOffers && specialOffers.length > 0
                  ? specialOffers[0].id
                  : undefined
              }
              createNew={
                !specialOffers
                  ? undefined
                  : specialOffers.length === 0
                  ? true
                  : false
              }
            />
            <PushNotification
              className="xl:col-span-1"
              sendNotification={sendNotification}
            />
          </div>
        )}
      </div>
      <div
        className={classNames(
          isAdmin ? "mt-8 mb-16" : "",
          "w-full h-full flex gap-4 flex-col"
        )}
      >
        {isAdmin || (companies && companies.length > 0) ? (
          <>
            <div className="flex flex-row justify-between w-full items-start mb-8">
              <p className="text-accent font-bold text-lg">Betriebe</p>
              {isAdmin && (
                <Searchbar
                  debouncedUpdate={(text) => {
                    setSearchTextHasChanged(true);
                    setSearchText(text);
                  }}
                />
              )}
            </div>
            <div className="flex flex-row justify-between w-full items-center pl-4 md:pl-0">
              <div className="flex flex-row md:flex-1 items-center w-32">
                <p className="font-bold text-left md:ml-28">Betriebsname</p>
              </div>
              {isAdmin && (
                <p className="font-bold flex flex-1 md:block text-left">
                  Filter
                </p>
              )}
              {isAdmin && (
                <p className="font-bold md:w-24 w-16 text-left">Aktiv</p>
              )}
            </div>
          </>
        ) : null}
        {isLoading && <LoadingIndicator />}
        {companies && companies.length > 0 ? (
          companies.map((company) => {
            return (
              <div
                className={classNames(
                  "flex flex-row justify-between w-full md:h-16 py-8 md:py-0 items-center bg-backgroundSecondary",
                  isAdmin
                    ? ""
                    : "hover:opacity-70 cursor-pointer transition-all"
                )}
                onClick={() => !isAdmin && openDetail(company.id)}
                key={company.id}
              >
                <div
                  className={classNames(
                    "flex flex-row md:flex-1 items-center w-full",
                    isAdmin
                      ? "cursor-pointer transition-all hover:opacity-70"
                      : ""
                  )}
                  onClick={() => openDetail(company.id)}
                >
                  <div
                    className={classNames(
                      "flex md:w-full ml-4 md:ml-8 items-left md:items-center gap-2 md:gap-8 text-left",
                      isAdmin
                        ? "flex-col md:flex-row w-32"
                        : "flex-row items-center w-full flex-1"
                    )}
                  >
                    {company.imageIds && company.imageIds[0] ? (
                      <img
                        className="h-12 w-12 rounded-md"
                        src={imageUrlById(company.imageIds[0])}
                        alt="company"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = imageUrlById(
                            getFilterWithId(company.categoryIds[0])?.imageId
                          );
                        }}
                      />
                    ) : (
                      <div className="flex h-12 w-12 justify-center items-center bg-accentLight rounded-md">
                        <img
                          className="h-8 w-8"
                          src={imageUrlById(
                            getFilterWithId(company.categoryIds[0])?.imageId
                          )}
                          alt="company"
                        />
                      </div>
                    )}
                    <p className="w-2/3">{company.name}</p>
                  </div>
                </div>
                <div
                  className={
                    isAdmin
                      ? "flex relative w-64 md:flex md:flex-1 text-left mr-16 pr-3 md:ml-20"
                      : ""
                  }
                >
                  {isAdmin && (
                    <div
                      onClick={() =>
                        setFilterSelectorOpenID((prev) =>
                          prev === company.id ? undefined : company.id
                        )
                      }
                      className={classNames(
                        "hover:opacity-70 cursor-pointer select-none",
                        // eslint-disable-next-line
                        specialOfferTitle(company) == "Kein Filter"
                          ? "opacity-30"
                          : ""
                      )}
                    >
                      {specialOfferTitle(company)}
                    </div>
                  )}
                  {isAdmin && filterSelectorOpenID === company.id && (
                    <div
                      className="absolute flex flex-col justify-center items-start gap-2 py-3 px-2 top-6 bg-backgroundSecondary z-10 shadow w-full"
                      ref={filterSelectorRef}
                    >
                      <div
                        className="hover:text-accent cursor-pointer"
                        key={"select_no_filter_key"}
                        onClick={() => selectOffer(undefined, company)}
                      >
                        Kein Filter
                      </div>
                      {specialOffers &&
                        specialOffers.map((offer) => (
                          <div
                            className="hover:text-accent cursor-pointer"
                            key={offer.id}
                            onClick={() => selectOffer(offer.id, company)}
                          >
                            {offer.title}
                          </div>
                        ))}
                    </div>
                  )}
                </div>
                <div className="flex md:w-24 mr-6 md:mr-0">
                  {isAdmin && (
                    <Toggle
                      active={company.active}
                      onChange={(newState) =>
                        changeActiveState(company, newState)
                      }
                    />
                  )}
                </div>
              </div>
            );
          })
        ) : !isLoading && !isAdmin ? (
          <div className="flex flex-col justify-center items-center gap-8 mt-16">
            <p className="text-text opacity-50 font-semibold">
              Du hast noch keinen Betrieb angelegt ...
            </p>
            <div
              className="px-4 py-4 rounded-md bg-accent text-white w-fit font-bold text-xl hover:opacity-70 cursor-pointer"
              onClick={() => openUserCreateCompanyPopup()}
            >
              Erstelle deinen Betrieb
            </div>
          </div>
        ) : (
          !isLoading &&
          isAdmin && (
            <p className="text-text opacity-50 font-semibold">
              Keine Ergebnisse zu dieser Suche...
            </p>
          )
        )}
      </div>
      {isAdmin && companies && companies.length > 0 && (
        <PageSelector
          pages={totalPages}
          activePage={selectedPage}
          setActivePage={(number) => setSelectedPage(number)}
        />
      )}
    </div>
  );
};
