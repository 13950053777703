import React from "react";
import { Store } from "../../api/data-contracts";
import { classNames } from "../../util";

type Props = {
  className?: string;
  stores: Store[] | undefined;
};

export const StoresSection: React.FC<Props> = ({ className, stores }) => {
  return (
    <div
      className={classNames(
        "text-lg text-left relative flex flex-1 flex-col mx-12 md:mx-24 lg:mx-32 xl:mx-48 pb-12 mb-12 border-b border-b-accentLight",
        stores && stores.length > 0 && "mt-12"
      )}
    >
      {stores && stores.length > 0 && (
        <div className="mb-4 text-accent font-semibold text-lg">
          Weitere Standorte
        </div>
      )}
      <div className="flex flex-row gap-8 flex-wrap">
        {stores &&
          stores.length > 0 &&
          stores.map((store) => (
            <div className="bg-background px-4 py-4 rounded-md flex flex-col">
              <p className="text-accent font-semibold  mb-2">{store.title}</p>
              <p className="text-text">{store.street}</p>
              <p className="text-text  mb-2">
                {store.postCode} {store.town}
              </p>
              <p className="text-text">T. {store.phone}</p>
              <p className="text-text">E. {store.email}</p>

              <a
                href={
                  (store?.website ?? "").startsWith("http")
                    ? store?.website
                    : "https://" + store?.website
                }
                className="text-accent font-semibold mt-2 cursor-pointer hover:opacity-70 hover:underline"
              >
                {store.website}
              </a>
            </div>
          ))}
      </div>
    </div>
  );
};
