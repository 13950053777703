import { XIcon } from "@heroicons/react/solid";
import { motion } from "framer-motion";
import React, { useRef } from "react";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";

type Props = {
  className?: string;
  close: () => void;
};

export const HelpPopup: React.FC<Props> = ({ className, close }) => {
  const ref = useRef(null);
  useOnClickOutside(ref, () => close());

  return (
    <motion.div
      className={
        "fixed inset-0 bg-black bg-opacity-50 flex z-50 flex-col items-center justify-center px-4 md:px-16 lg:px-32 py-8 md:py-16"
      }
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <div
        className="rounded-md bg-backgroundSecondary flex flex-col h-full w-full overflow-hidden"
        ref={ref}
      >
        <div className="flex flex-row justify-between px-4 py-4 items-center">
          <div className="w-6 h-6" />
          <h1 className="text-text font-semibold text-xl uppercase">
            Hilfe Video
          </h1>
          <XIcon
            className="w-6 h-6 text-text hover:opacity-70 cursor-pointer"
            onClick={() => close()}
          />
        </div>
        <iframe
          title="Help Video"
          src="https://www.youtube.com/embed/E4YOfZNM9Fw"
          className="flex h-full w-full"
        ></iframe>
      </div>
    </motion.div>
  );
};
