import { XIcon } from "@heroicons/react/solid";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { useAuth } from "../../api/AuthContext";
import { UserCompanyStoreCreate } from "../../api/data-contracts";
import { classNames } from "../../util";
import { AnimatedButton } from "../common/AnimatedButton";
import {
  MultilineTextInputWithoutState,
  TextInputWithoutState,
} from "../common/TextInput";
import { AdminInputTag } from "./detail/AdminInputTag";
import { AdminTag } from "./detail/AdminTag";
import { CompanyCategory } from "./detail/CompanyCategory";

type Props = {
  className?: string;
  close: () => void;
};

export const AdminCreateUserPopup: React.FC<Props> = ({ className, close }) => {
  const { api } = useAuth();

  const [data, setData] = useState<UserCompanyStoreCreate>({
    user: {
      username: "",
      firstName: "",
      lastName: "",
    },
    company: {
      name: "",
      description: "",
      tags: [],
      categoryIds: [],
    },
    store: {
      title: "",
      street: "",
      postCode: "",
      town: "",
      location: {
        lat: 0,
        lng: 0,
      },
    },
  });

  const [tempLocation, setTempLocation] = useState({
    lat: "",
    lng: "",
  });

  function onAdd() {
    api
      .createUserAndCompanyAndStore({
        ...data,
        user: {
          lastName: data.user.lastName !== "" ? data.user.lastName : " ",
          firstName: data.user.firstName !== "" ? data.user.firstName : " ",
          username: data.user.username,
        },
      })
      .then((res) => console.log("res", res))
      .catch((err) => console.log("err", err));
  }

  return (
    <motion.div
      className="fixed inset-0 bg-background z-50 flex flex-col overflow-y-scroll"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.2 }}
    >
      {/* Header */}
      <div className="fixed top-0 flex flex-row justify-between items-center w-full h-16 px-8 z-10 bg-backgroundSecondary shadow">
        <div className="w-6" />
        <div className="flex flex-col items-center text-lg font-bold text-text">
          Nutzer Erstellen
        </div>
        <XIcon
          className="w-6 text-accent cursor-pointer hover:opacity-70"
          onClick={() => close()}
        />
      </div>
      {/* Body */}
      <div className="px-8 py-6 flex gap-4 flex-col items-start bg-backgroundSecondary mt-20 mx-8">
        <p className="text-accent font-bold text-lg">Nutzer</p>
        <div className="flex flex-col md:flex-row gap-8 w-full">
          <TextInputWithoutState
            title={"Vorname"}
            placeholder={"Max"}
            text={data.user.firstName ?? ""}
            setText={(text) =>
              setData((prev) => ({
                ...prev,
                user: {
                  ...prev.user,
                  firstName: text,
                },
              }))
            }
            optional
          />
          <TextInputWithoutState
            title={"Nachname"}
            placeholder={"Mustermann"}
            text={data.user.lastName ?? ""}
            setText={(text) =>
              setData((prev) => ({
                ...prev,
                user: {
                  ...prev.user,
                  lastName: text,
                },
              }))
            }
            optional
          />
        </div>
        <div className="flex flex-col md:flex-row gap-8 w-full">
          <TextInputWithoutState
            title={"Nutzername"}
            placeholder={"max@mustermann.at"}
            text={data.user.username}
            setText={(text) =>
              setData((prev) => ({
                ...prev,
                user: {
                  ...prev.user,
                  username: text,
                },
              }))
            }
          />
          <TextInputWithoutState
            title={"Telefonnummer"}
            placeholder={"+43 660 0000000"}
            text={data.user.phone ?? ""}
            setText={(text) =>
              setData((prev) => ({
                ...prev,
                user: {
                  ...prev.user,
                  phone: text,
                },
              }))
            }
            optional
            type="tel"
          />
        </div>
      </div>
      <div className="px-8 py-4 flex gap-4 flex-col md:flex-row">
        <div className="px-8 py-6 flex gap-4 flex-col justify-start h-fit bg-backgroundSecondary w-full items-start">
          <p className="text-accent font-bold text-lg">Betrieb</p>
          <TextInputWithoutState
            title={"Firmenname"}
            placeholder={"Musterfirma"}
            text={data.company.name}
            setText={(text) =>
              setData((prev) => ({
                ...prev,
                company: {
                  ...prev.company,
                  name: text,
                },
              }))
            }
          />
          <MultilineTextInputWithoutState
            title={"Beschreibung"}
            placeholder={"..."}
            text={data.company.description}
            setText={(text) =>
              setData((prev) => ({
                ...prev,
                company: {
                  ...prev.company,
                  description: text,
                },
              }))
            }
            border
          />
          {/* Categories */}
          <CompanyCategory
            categoryIds={data.company.categoryIds}
            onChange={(categories) =>
              setData((prev) => ({
                ...prev,
                company: {
                  ...prev.company,
                  categoryIds: categories,
                },
              }))
            }
            wasEdited={false}
            alternativeBackground
          />
          {/* Tags */}
          <div className="flex flex-col">
            <p className="text-accent font-semibold text-sm text-left mb-1">
              {"Schlagwörter für Suche"}
            </p>
            <div
              className={classNames(
                "flex flex-col md:flex-row gap-2 flex-wrap"
              )}
            >
              {data.company.tags &&
                data.company.tags.length > 0 &&
                data.company.tags.map((tag) => (
                  <AdminTag
                    key={tag}
                    name={tag}
                    removeTag={(name) =>
                      setData((prev) => ({
                        ...prev,
                        company: {
                          ...prev.company,
                          tags: prev.company.tags.filter((e) => {
                            return e !== name;
                          }),
                        },
                      }))
                    }
                    alternativeBackground
                  />
                ))}
              <AdminInputTag
                addTag={(tag) => {
                  if (data.company.tags.indexOf(tag) === -1) {
                    setData((prev) => ({
                      ...prev,
                      company: {
                        ...prev.company,
                        tags: [...(prev.company.tags ?? []), tag],
                      },
                    }));
                  }
                }}
                alternativeBackground
              />
            </div>
          </div>
        </div>
        <div className="px-8 py-6 flex gap-4 flex-col h-fit justify-start bg-backgroundSecondary w-full items-start">
          <p className="text-accent font-bold text-lg">Standort</p>
          <TextInputWithoutState
            title={"Standortname"}
            placeholder={"Standort XYZ"}
            text={data.store.title}
            setText={(text) =>
              setData((prev) => ({
                ...prev,
                store: {
                  ...prev.store,
                  title: text,
                },
              }))
            }
          />
          <TextInputWithoutState
            title={"Straße & Hausnummer"}
            placeholder={"Musterstraße 1"}
            text={data.store.street}
            setText={(text) =>
              setData((prev) => ({
                ...prev,
                store: {
                  ...prev.store,
                  street: text,
                },
              }))
            }
          />

          <div className="flex flex-col md:flex-row gap-8 w-full">
            <TextInputWithoutState
              title={"PLZ"}
              placeholder={"4020"}
              text={data.store.postCode}
              setText={(text) =>
                setData((prev) => ({
                  ...prev,
                  store: {
                    ...prev.store,
                    postCode: text,
                  },
                }))
              }
            />
            <TextInputWithoutState
              title={"Ort"}
              placeholder={"Linz"}
              text={data.store.town}
              setText={(text) =>
                setData((prev) => ({
                  ...prev,
                  store: {
                    ...prev.store,
                    town: text,
                  },
                }))
              }
            />
          </div>
          <div className="flex flex-col gap-1">
            <div className="flex flex-col md:flex-row gap-8 w-full">
              <TextInputWithoutState
                title={"Breitengrad (Latitude)"}
                validationRegex={/^((\-?|\+?)?\d+(\.\d+)?)/}
                placeholder={"48.12345"}
                text={tempLocation.lat ?? ""}
                setText={(text) => {
                  setTempLocation((prev) => ({
                    ...prev,
                    lat: text.replaceAll(",", "."),
                  }));
                  setData((prev) => ({
                    ...prev,
                    store: {
                      ...prev.store,
                      location: {
                        ...prev.store.location,
                        lat: Number(text === "" ? "0" : text) ?? 0,
                      },
                    },
                  }));
                }}
              />
              <TextInputWithoutState
                title={"Längengrad (Longitude)"}
                validationRegex={/^((\-?|\+?)?\d+(\.\d+)?)/}
                placeholder={"14.12345"}
                text={tempLocation.lng ?? ""}
                setText={(text) => {
                  setTempLocation((prev) => ({
                    ...prev,
                    lng: text.replaceAll(",", "."),
                  }));
                  setData((prev) => ({
                    ...prev,
                    store: {
                      ...prev.store,
                      location: {
                        ...prev.store.location,
                        lng: Number(text === "" ? "0" : text) ?? 0,
                      },
                    },
                  }));
                }}
              />
            </div>
            <p className="text-xs font-normal text-text text-left opacity-50">
              Füge hier bitte Längen- und Breitengrade der Adresse ein damit wir
              deinen Standort auf der Karte anzeigen können. Um diese zu
              ermitteln kannst du diese kostenlose Website verwenden:
              <span
                className="text-accent hover:opacity-70 cursor-pointer"
                onClick={() => window.open("https://www.latlong.net/")}
              >
                {" "}
                https://www.latlong.net/{" "}
              </span>
              . Einfach Adresse eingeben und auf “Find” klicken. Im Anschluss
              dann den Wert von Latitude in das Feld Breitengrade kopieren und
              den Wert von Longitude in das Feld Längengrade.
            </p>
          </div>
          <TextInputWithoutState
            title={"Telefonnummer"}
            placeholder={"+43 660 0000000"}
            text={data.store.phone ?? ""}
            setText={(text) =>
              setData((prev) => ({
                ...prev,
                store: {
                  ...prev.store,
                  phone: text,
                },
              }))
            }
            optional
          />
          <TextInputWithoutState
            title={"Webseite"}
            placeholder={"www.mein-standort.at"}
            text={data.store.website ?? ""}
            setText={(text) =>
              setData((prev) => ({
                ...prev,
                store: {
                  ...prev.store,
                  website: text,
                },
              }))
            }
            optional
          />
          <TextInputWithoutState
            title={"E-Mail"}
            placeholder={"info@mein-standort.at"}
            text={data.store.email ?? ""}
            setText={(text) =>
              setData((prev) => ({
                ...prev,
                store: {
                  ...prev.store,
                  email: text,
                },
              }))
            }
            optional
          />
        </div>
      </div>
      {/* Bottom buttons */}
      <div className="w-full justify-end flex flex-row gap-4 px-8 pb-12">
        <AnimatedButton
          children={<p>Abbrechen</p>}
          onClick={() => close()}
          outline
        />
        <AnimatedButton
          children={<p>Hinzufügen</p>}
          onClick={() => {
            onAdd();
            close();
          }}
          active={
            data.user.username !== "" &&
            //data.user.firstName !== "" &&
            //data.user.lastName !== "" &&
            data.company.name !== "" &&
            data.company.description !== "" &&
            data.company.categoryIds.length > 0 &&
            data.store.title !== "" &&
            data.store.street !== "" &&
            data.store.town !== "" &&
            data.store.postCode !== ""
          }
        />
      </div>
    </motion.div>
  );
};
