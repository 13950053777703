import { PlusIcon, XCircleIcon } from "@heroicons/react/solid";
import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../../api/AuthContext";
import { PageCategory } from "../../../api/data-contracts";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import { classNames, imageUrlById } from "../../../util";
import { FilterIcon } from "../../common/FilterIcon";
import { ChangedIndicator } from "./ChangedIndicator";

type Props = {
  className?: string;
  categoryIds: string[] | undefined;
  onChange: (categoryIds: string[]) => void;
  wasEdited: boolean;
  alternativeBackground?: boolean;
};

export const CompanyCategory: React.FC<Props> = ({
  className,
  categoryIds,
  onChange,
  wasEdited,
  alternativeBackground = false,
}) => {
  const { api } = useAuth();

  const [filters, setFilters] = useState<PageCategory>();
  useEffect(() => {
    api.getList({ size: 100 }).then((res) => {
      setFilters(res.data as PageCategory);
    });
  }, [api]);

  function getFilterWithId(id: string) {
    if (!filters) {
      return null;
    }
    return filters?.content?.find((filter) => filter.id === id);
  }

  const [addPopupOpen, setAddPopupOpen] = useState(false);

  const ref = useRef(null);
  useOnClickOutside(ref, () => setAddPopupOpen(false));

  return (
    <div className="flex flex-col relative">
      {/* Add Popup */}
      {addPopupOpen && (
        <div
          className={classNames(
            "absolute flex flex-col top-16 md:top-6 w-64 md:-right-[272px] z-10 gap-2 p-4 rounded-md shadow",
            alternativeBackground ? "bg-background" : "bg-backgroundSecondary"
          )}
          ref={ref}
        >
          <XCircleIcon
            className="self-end w-5 h-5 first-letter:hover:opacity-70 cursor-pointer text-sm text-text hover:opacity-70 font-light"
            onClick={() => setAddPopupOpen(false)}
          />
          {filters?.content?.map(
            (filter) =>
              !categoryIds?.includes(filter.id) && (
                <div
                  className="flex flex-row items-center justify-start py-2 gap-2 border-b border-b-accentLight last:border-none cursor-pointer hover:opacity-70"
                  key={filter.id}
                  onClick={() => {
                    onChange(categoryIds?.concat(filter.id) ?? [filter.id]);
                    setAddPopupOpen(false);
                  }}
                >
                  <FilterIcon
                    key={filter.id}
                    size={6}
                    iconPath={imageUrlById(filter.imageId)}
                    color={filter.colorHex ?? ""}
                  />
                  <p className="text-sm text-center">{filter.title}</p>
                </div>
              )
          )}
        </div>
      )}

      <p className="text-accent font-semibold text-sm text-left mb-1">
        {"Kategorien"}
      </p>
      <div className="flex flex-row flex-wrap gap-2">
        {categoryIds &&
          categoryIds.length > 0 &&
          categoryIds.map((categoryId, index) => (
            <div
              className={classNames(
                "relative group",
                index === 0 && "rounded-full ring-4 ring-yellow-500",
                categoryIds && categoryIds.length > 1 && "cursor-pointer"
              )}
              key={categoryId}
            >
              <FilterIcon
                key={categoryId}
                size={12}
                iconPath={imageUrlById(
                  getFilterWithId(categoryId)?.imageId ?? ""
                )}
                color={getFilterWithId(categoryId)?.colorHex ?? ""}
              />
              {categoryIds && categoryIds.length > 1 && (
                <div
                  className="absolute top-0 -right-1 items-center text-accent text-sm gap-2"
                  onClick={() =>
                    categoryIds &&
                    categoryIds.length > 1 &&
                    onChange(
                      categoryIds.filter((e) => {
                        return e !== categoryId;
                      })
                    )
                  }
                >
                  <div className="h-4 w-4 bg-white rounded-full opacity-0 group-hover:opacity-100 z-0 transition-all" />
                  <XCircleIcon className="w-6 h-6 text-transparent hover:opacity-70 group-hover:text-red -translate-x-1 z-10 -translate-y-5 transition-all" />
                </div>
              )}
            </div>
          ))}
        {filters &&
          filters.content &&
          categoryIds &&
          categoryIds.length < filters.content?.length && (
            <div
              className={classNames(
                "flex flex-col justify-center items-center rounded-full h-12 w-12 cursor-pointer hover:opacity-70",
                alternativeBackground
                  ? "bg-background"
                  : "bg-backgroundSecondary"
              )}
              onClick={() => setAddPopupOpen(true)}
            >
              <PlusIcon className="h-4 w-4 text-accent" />
            </div>
          )}

        <ChangedIndicator wasEdited={wasEdited} />
      </div>
      <p className="text-text font-normal text-xs mt-2 text-left">
        Die 1. Kategorie ist automatisch deine{" "}
        <span className="text-yellow-500 font-semibold">Hauptkategorie</span>.
        <br />
        Die Hauptkategorie wird z.B. für die Map-Marker in der App benötigt.
        <br />
        Es muss mindestens eine Kategorie gewählt sein.
      </p>
    </div>
  );
};
