import React, { useEffect, useMemo, useState } from "react";
import { useAuth } from "../api/AuthContext";
import { TextInput, TextInputWithoutState } from "../components/common/TextInput";
import { AnimatedButton } from "../components/common/AnimatedButton";
import { classNames, showError, showSuccess, validateEmail } from "../util";
import { motion } from "framer-motion";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UserRegister } from "../components/login/UserRegister";
import { UserCreate } from "../api/data-contracts";
import { HelpPopup } from "../components/login/HelpPopup";
import { XIcon } from "@heroicons/react/solid";
import useSearchParamsState from "../hooks/useSearchParamsState";

type Props = {
  className?: string;
};

export const Login: React.FC<Props> = ({ className }) => {
  const { login } = useAuth();

  const navigate = useNavigate();

  const [email, setEmail] = useSearchParamsState<string>("email","");
  const [password, setPassword] = useState("");

  const { api } = useAuth();

  const [successScreenOpen, setSuccessScreenOpen] = useState(false);

  const [registerData, setRegisterData] = useState<UserCreate>({
    username: "",
    password: "",
    firstName: "",
    lastName: "",
    notification: false,
    newsletter: false,
  });

  const [error, setError] = useState("");

  const pageOptions = useMemo(() => ["Anmelden", "Registrieren"], []);
  const [page, setPage] = useState(pageOptions[0]);

  // ability to go to /login?register=true to instantly switch to register page
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get("register") === "true") {
      setPage(pageOptions[1]);
    }
  }, [pageOptions, searchParams]);

  function handleButtonClick() {
    setError("");
    if (page === pageOptions[0]) {
      login(email, password)
        .then((response) => {
          if ((response as any).access_token) {
            navigate("/manage");
          } else {
            setError(
              (response as any).code.replace(/([a-z](?=[A-Z]))/g, "$1 ")
            );
          }
        })
        .catch((err) => {
          setError(err.error.code.replace(/([a-z](?=[A-Z]))/g, "$1 "));
        });
    } else if (page === pageOptions[1]) {
      api
        .create(registerData)
        .then((res) => {
          setError("");
          if (res.status === 200) {
            setSuccessScreenOpen(true);
            setRegisterData({
              username: "",
              password: "",
              firstName: "",
              lastName: "",
              notification: false,
              newsletter: false,
            });
            // login(registerData.username, registerData.password).then(
            //   (response) => {
            //     console.log(response);
            //     if ((response as any).access_token) {
            //       navigate("/manage");
            //     }
            //   }
            // );
          }
        })
        .catch((err) => {
          setSuccessScreenOpen(false);
          setError(err.error.code.replace(/([a-z](?=[A-Z]))/g, "$1 "));
        });
    }
  }

  function forgotPassword() {
    if (email.length > 0 && validateEmail(email)) {
      api
        .sendResetPasswordLink({ email: email })
        .finally(() => showSuccess("E-Mail wurde versandt!"));
    } else {
      showError("Bitte gib zuerst deine E-Mail ein.");
    }
  }

  const [helpPopupOpen, setHelpPopupOpen] = useState(false);

  return (
    <div className="flex h-screen min-h-screen w-full flex-col justify-center bg-background text-text items-center">
      {successScreenOpen && (
        <div className="h-screen w-screen fixed bg-background z-10 flex justify-center flex-col items-center gap-8 px-8 md:px-32 lg:px-96">
          <p className="text-accent font-bold text-2xl">
            Vielen Dank für deine Registrierung!
          </p>
          <p className="text-text font-bold text-sm max-w-lg">
            Wir haben dir soeben eine Bestätigungsmail geschickt, bitte klicke
            auf den Link in der E-Mail! Falls du die E-Mail nicht erhalten hast,
            überprüfe bitte auch deinen Spam-Ordner.
          </p>
          <div
            className="fixed top-8 right-8 w-8 h-8 hover:opacity-70 cursor-pointer"
            onClick={() => setSuccessScreenOpen(false)}
          >
            <XIcon className="w-8 h-8 text-text" />
          </div>
        </div>
      )}
      {helpPopupOpen && <HelpPopup close={() => setHelpPopupOpen(false)} />}
      <div className="flex flex-row h-24 w-full bg-background justify-between items-center px-16">
        <div
          className="flex h-16 w-16 bg-backgroundSecondary rounded-md justify-center items-center text-backgroundSecondary cursor-pointer hover:opacity-70"
          onClick={() => navigate("/")}
        >
          <img
            src={"/img/gutesfinden_logo_web.svg"}
            className={"h-12 w-12"}
            alt=""
          />
        </div>
        <div className="flex flex-row gap-8">
          <div
            className={classNames(
              "uppercase items-center font-extrabold text-lg text-text cursor-pointer hover:opacity-70 transition-al"
            )}
            onClick={() => setHelpPopupOpen(true)}
          >
            Hilfe
          </div>
          {pageOptions.map((option) => (
            <div
              className={classNames(
                "uppercase items-center font-extrabold text-lg text-text cursor-pointer hover:opacity-70 transition-al"
              )}
              onClick={() => setPage(option)}
            >
              {option}
              {option === page && (
                <motion.div
                  className="h-1 w-full bg-text rounded-full"
                  initial={{ opacity: 0, scaleX: 0 }}
                  animate={{ opacity: 1, scaleX: 1 }}
                  transition={{ duration: 0.1 }}
                />
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="flex w-full h-full justify-center items-center px-8">
        <div className="w-full p-8 md:p-16 lg:w-2/5 bg-backgroundSecondary">
          <div className="text-left">
            <h3 className="text-2xl font-bold">
              Herzlich Willkommen bei Gutes Finden
            </h3>
            <p className="text-sm font-normal ">
              Hier kannst du dich {page === pageOptions[0] && "anmelden"}
              {page === pageOptions[1] && "registrieren"}!
            </p>
          </div>
          <div className="mt-12 flex flex-col gap-8 justify-center items-center">
            {/* Error Banner */}
            {error !== "" && (
              <p className="text-white py-2 px-4 w-full rounded-md bg-red flex gap-4 justify-center items-center">
                <span className="font-bold">{"ERROR! "}</span>{" "}
                <span className="font-bold opacity-90">{error}</span>
              </p>
            )}

            {/* Login */}
            {page === pageOptions[0] && (
              <>
                <TextInputWithoutState
                  setText={(text) => setEmail(text)}
                  text={email}
                  placeholder={"E-Mail"}
                  name="username"
                />
                <TextInput
                  debouncedUpdate={(text) => setPassword(text)}
                  placeholder={"Passwort"}
                  type="password"
                  name="password"
                  debounceDelayInMs={100}
                />
              </>
            )}
            {/* Register */}
            {page === pageOptions[1] && (
              <UserRegister
                data={registerData}
                setData={(newData) => setRegisterData(newData)}
              />
            )}

            <div>
              <AnimatedButton
                onClick={() => {
                  handleButtonClick();
                }}
                className={"md:col-span-2 md:w-48 mx-auto"}
                active={
                  page === pageOptions[0]
                    ? email.length > 0 &&
                      validateEmail(email) &&
                      password.length > 0
                    : registerData.username.length > 0 &&
                      registerData.password.length > 0 &&
                      (registerData?.firstName ?? "").length > 0 &&
                      (registerData?.lastName ?? "").length > 0
                }
              >
                {page === pageOptions[0]
                  ? "Anmelden"
                  : page === pageOptions[1] && "Registrieren"}
              </AnimatedButton>
              {page === pageOptions[0] && (
                <p
                  className="mt-2 text-sm underline text-accent cursor-pointer hover:text-accentLight"
                  onClick={() => forgotPassword()}
                >
                  {"Passwort vergessen?"}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
