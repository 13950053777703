import { XCircleIcon } from "@heroicons/react/solid";
import React from "react";
import { Category } from "../../api/data-contracts";

type Props = {
  className?: string;
  filter: Category;
  removeTag: (filter: Category) => void;
};

export const FilterTag: React.FC<Props> = ({
  className,
  filter,
  removeTag,
}) => {
  return (
    <div
      className="flex flex-row group items-center bg-backgroundSecondary rounded-md px-2 py-0.5 text-accent text-sm gap-2 cursor-pointer hover:opacity-70"
      onClick={() => {
        removeTag(filter);
      }}
    >
      {filter.title}
      <XCircleIcon className="w-4 h-4 text-transparent group-hover:text-red" />
    </div>
  );
};
