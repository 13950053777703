import React, { useState } from "react";
import { classNames } from "../../util";
import { AnimatedButton } from "../common/AnimatedButton";
import { MultilineTextInputWithoutState } from "../common/TextInput";
import { AdminTextInput } from "./AdminTextInput";

type Props = {
  className?: string;
  sendNotification: (title: string, body: string) => void;
};

export const PushNotification: React.FC<Props> = ({
  className,
  sendNotification,
}) => {
  const [push, setPush] = useState({ title: "", message: "" });

  return (
    <div
      className={classNames(
        "flex flex-col relative justify-between gap-4 py-6 md:py-8 md:px-16 px-4 w-full bg-backgroundSecondary shadow-sm rounded-md",
        className
      )}
    >
      <div className="flex flex-row w-full justify-between">
        <p className="text-accent font-bold text-lg text-left">
          Push Benachrichtigung
        </p>
        <AnimatedButton
          children={<p>Senden</p>}
          onClick={() => sendNotification(push.title, push.message)}
          active={push.title.length > 0 && push.message.length > 0}
        />
      </div>
      <AdminTextInput
        title="Titel"
        text={push.title}
        setText={(text) => setPush({ ...push, title: text })}
        placeholder={""}
      />
      <MultilineTextInputWithoutState
        title="Push Nachricht"
        text={push.message}
        setText={(text) => setPush({ ...push, message: text })}
        placeholder={""}
        border
      />
    </div>
  );
};
