import { Popover } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../api/AuthContext";
import { Category, PageCategory } from "../../api/data-contracts";
import { classNames, imageUrlById } from "../../util";
import { FilterIcon } from "../common/FilterIcon";

type Props = {
  className?: string;
  toggleFilter: (filter: Category) => void;
  activeFilters: Category[];
};

export const FilterPopup: React.FC<Props> = ({
  className,
  toggleFilter,
  activeFilters,
}) => {
  const { api } = useAuth();
  const [filters, setFilters] = useState<PageCategory>();

  useEffect(() => {
    api.getList({ size: 100 }).then((res) => {
      setFilters(res.data as PageCategory);
    });
  }, []);

  return (
    <Popover.Panel className="absolute z-10 top-10">
      <div className="flex flex-col bg-backgroundSecondary shadow px-4 pb-2 pt-4">
        <div className="flex flex-row justify-between items-center gap-4 w-60">
          <div className="uppercase items-center font-bold text-lg text-text">
            Filter
          </div>
          <Popover.Button
            as={XCircleIcon}
            className="w-5 h-5 text-gray-400 hover:opacity-70 cursor-pointer"
          />
        </div>
        <div className="flex flex-col mt-4">
          <>
            {filters &&
              filters.content &&
              filters.content.length > 0 &&
              filters.content.map((filter) => {
                return (
                  <FilterPopupListItem
                    name={filter.title ?? ""}
                    onClick={(name) => toggleFilter(filter)}
                    active={
                      activeFilters &&
                      activeFilters.some((f) => f.title === filter.title)
                    }
                    color={filter.colorHex ?? "#000"}
                    key={filter.id}
                    imageId={filter.imageId ?? ""}
                  />
                );
              })}
          </>
        </div>
      </div>
    </Popover.Panel>
  );
};

type FilterPopupListItemProps = {
  className?: string;
  name: string;
  onClick: (name: string) => void;
  active: boolean;
  color: string;
  imageId: string;
};

export const FilterPopupListItem: React.FC<FilterPopupListItemProps> = ({
  className,
  name,
  onClick,
  active,
  color,
  imageId,
}) => {
  return (
    <div
      className={
        "flex h-14 items-center justify-start gap-2 cursor-pointer last:border-0 border-b border-b-accentLight"
      }
      onClick={() => onClick(name)}
    >
      <FilterIcon
        size={8}
        color={color}
        iconPath={imageUrlById(imageId)}
        active={active}
      />
      <p
        className={classNames(
          "text-sm  text-text",
          active ? "font-semibold" : "font-light"
        )}
      >
        {name}
      </p>
    </div>
  );
};
