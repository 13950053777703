import { motion } from "framer-motion";
import React from "react";

type Props = {
  className?: string;
  selected: boolean;
  text: string;
  onClick: () => void;
};

export const NavigationBarText: React.FC<Props> = ({
  className,
  selected,
  text,
  onClick,
}) => {
  return (
    <div
      className="uppercase items-center font-extrabold tracking-wider text-base md:text-lg text-text cursor-pointer hover:opacity-70 transition-all"
      onClick={onClick}
    >
      <p>{text}</p>
      {selected && (
        <motion.div
          className="h-1 w-full bg-text rounded-full"
          initial={{ opacity: 0, scaleX: 0 }}
          animate={{ opacity: 1, scaleX: 1 }}
          transition={{ duration: 0.1 }}
        />
      )}
    </div>
  );
};
