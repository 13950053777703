import { XIcon } from "@heroicons/react/solid";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../api/AuthContext";
import { CompanyCreate } from "../../api/data-contracts";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { classNames } from "../../util";
import { AnimatedButton } from "../common/AnimatedButton";
import {
  MultilineTextInputWithoutState,
  TextInputWithoutState,
} from "../common/TextInput";
import { AdminInputTag } from "./detail/AdminInputTag";
import { AdminTag } from "./detail/AdminTag";
import { CompanyCategory } from "./detail/CompanyCategory";

type Props = {
  className?: string;
  close: () => void;
};

export const UserCreateCompany: React.FC<Props> = ({ className, close }) => {
  const { api } = useAuth();

  const navigate = useNavigate();

  const [error, setError] = useState("");

  const [data, setData] = useState<CompanyCreate>({
    name: "",
    description: "",
    tags: [],
    categoryIds: [],
  });

  function onAdd() {
    api
      .createCompany(data)
      .then((res) => {
        setNewCompany(true);
        navigate("/manage/detail/" + res.data.id);
      })
      .catch((err) => setError(err.message));
  }

  const [newCompany, setNewCompany] = useLocalStorage(
    "gutesfinden.new_company_added",
    false
  );

  return (
    <motion.div
      className="fixed inset-0 bg-background z-50 flex flex-col overflow-y-scroll"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.2 }}
    >
      {/* Header */}
      <div className="fixed top-0 flex flex-row justify-between items-center w-full h-16 px-8 z-10 bg-backgroundSecondary shadow">
        <div className="w-6" />
        <div className="flex flex-col items-center text-lg font-bold text-text">
          Betrieb Erstellen
        </div>
        <XIcon
          className="w-6 text-accent cursor-pointer hover:opacity-70"
          onClick={() => close()}
        />
      </div>

      {/* Error Banner */}
      {error !== "" && (
        <p className="text-white py-2 px-4 w-full rounded-md bg-red flex gap-4 justify-center items-center">
          <span className="font-bold">{"ERROR! "}</span>{" "}
          <span className="font-bold opacity-90">{error}</span>
        </p>
      )}

      {/* Body */}
      <div className="px-8 py-6 flex gap-4 flex-col items-start bg-backgroundSecondary mt-20 mx-8">
        {/* <p className="text-accent font-bold text-lg">Betrieb</p> */}
        <TextInputWithoutState
          title={"Betriebname"}
          placeholder={"Musterfirma"}
          text={data.name}
          setText={(text) =>
            setData((prev) => ({
              ...prev,
              name: text,
            }))
          }
        />
        <MultilineTextInputWithoutState
          title={"Beschreibung"}
          placeholder={"..."}
          text={data.description}
          setText={(text) =>
            setData((prev) => ({
              ...prev,
              description: text,
            }))
          }
          border
        />

        {/* Categories */}
        <CompanyCategory
          categoryIds={data.categoryIds}
          onChange={(categories) =>
            setData((prev) => ({
              ...prev,
              categoryIds: categories,
            }))
          }
          wasEdited={false}
          alternativeBackground
        />

        {/* Tags */}
        <div className="flex flex-col">
          <p className="text-accent font-semibold text-sm text-left mb-1">
            {"Schlagwörter für Suche"}
          </p>
          <div className={classNames("flex flex-row gap-2 flex-wrap")}>
            {data.tags &&
              data.tags.length > 0 &&
              data.tags.map((tag) => (
                <AdminTag
                  key={tag}
                  name={tag}
                  removeTag={(name) =>
                    setData((prev) => ({
                      ...prev,
                      tags: prev.tags.filter((e) => {
                        return e !== name;
                      }),
                    }))
                  }
                  alternativeBackground
                />
              ))}
            <AdminInputTag
              addTag={(tag) => {
                if (data.tags.indexOf(tag) === -1) {
                  setData((prev) => ({
                    ...prev,
                    tags: [...(prev.tags ?? []), tag],
                  }));
                }
              }}
              alternativeBackground
            />
          </div>
        </div>
      </div>
      {/* Bottom buttons */}
      <div className="w-full justify-end flex flex-row gap-4 px-8 pb-12 pt-4">
        <AnimatedButton
          children={<p>Abbrechen</p>}
          onClick={() => close()}
          outline
        />
        <AnimatedButton
          children={<p>Hinzufügen</p>}
          onClick={() => {
            onAdd();
            close();
          }}
          active={
            data.name !== "" &&
            data.description !== "" &&
            data.tags.length > 0 &&
            data.categoryIds.length > 0
          }
        />
      </div>
    </motion.div>
  );
};
