import React from "react";
import { imageUrlById } from "../../util";

type Props = {
  className?: string;
  imageId: string;
  title: string;
  validityDate: string;
};

export const PromotionCard: React.FC<Props> = ({
  className,
  imageId,
  title,
  validityDate,
}) => {
  return (
    <div className="flex flex-col gap-4 shadow">
      {imageId !== "" && (
        <img
          src={imageUrlById(imageId)}
          className="bg-gray-200 h-48 object-cover"
          alt=""
        />
      )}
      <p className="text-text font-semibold text-sm text-left mt-2 px-4">
        {title}
      </p>
      <p className="text-accent font-normal text-sm text-left px-4 pb-2">
        {validityDate}
      </p>
    </div>
  );
};
