import { LocationMarkerIcon } from "@heroicons/react/solid";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  OverlayView,
} from "@react-google-maps/api";
import React, { useCallback, useEffect, useState } from "react";
import { Company, Store } from "../../api/data-contracts";
import { LoadingIndicator } from "../common/LoadingIndicator";

type Props = {
  className?: string;
  mainStore: Store | undefined;
  additionalStores: Store[] | undefined;
};

export const ContactSection: React.FC<Props> = ({
  className,
  mainStore,
  additionalStores,
}) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBRfaCyLQDmld5-Y-T76pkSATWQwr79R8Y",
  });

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const [centerPos, setCenterPos] = useState({
    lat: mainStore?.location.lat ?? 48,
    lng: mainStore?.location.lng ?? 14,
  });

  useEffect(() => {
    setCenterPos({
      lat: mainStore?.location.lat ?? 48,
      lng: mainStore?.location.lng ?? 14,
    });
  }, [mainStore]);

  const [map, setMap] = useState<any>();

  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(undefined);
  }, []);

  return (
    <div className="relative flex flex-1 flex-col mx-12 md:mx-24 lg:mx-32 xl:mx-48">
      <div className={"flex flex-col desktop:flex-row w-full gap-4 md:gap-16"}>
        <div className="flex flex-col gap-4 w-1/2 md:w-1/3 lg:w-1/4">
          <p className="text-left font-semibold text-text text-2xl tracking-wider uppercase mb-4">
            Kontakt
          </p>
          <p className="text-left font-semibold text-accent text-lg uppercase">
            {mainStore?.title}
          </p>
          <p className="text-left font-normal text-text text-lg">
            {mainStore?.street}, {mainStore?.postCode} {mainStore?.town}
          </p>
          <p className="text-left font-normal text-text text-lg">
            T. {mainStore?.phone ?? "-"}
            <br />
            E. {mainStore?.email ?? "-"}
          </p>
          <a
            href={
              (mainStore?.website ?? "").startsWith("http")
                ? mainStore?.website
                : "https://" + mainStore?.website
            }
            className="text-left text-lg text-accent font-semibold cursor-pointer hover:opacity-70 hover:underline"
          >
            {mainStore?.website ?? ""}
          </a>
        </div>
        <div className="flex bg-green-200 w-full h-64 justify-center items-center">
          {isLoaded && mainStore !== undefined ? (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={centerPos}
              zoom={8}
              onLoad={onLoad}
              onUnmount={onUnmount}
            >
              {/* Main Store */}
              <OverlayView
                onLoad={onLoad}
                position={centerPos}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                <div
                  className="flex h-8 w-8 items-center flex-col cursor-pointer"
                  style={{
                    translate: "-50% -50%",
                  }}
                >
                  <LocationMarkerIcon className="w-8 h-8 text-accentLight stroke-1 stroke-text" />
                </div>
              </OverlayView>
              {/* Additional Stores */}
              {additionalStores &&
                additionalStores.length > 0 &&
                additionalStores?.map(
                  (store) =>
                    store.location &&
                    store.location.lat &&
                    store.location.lng && (
                      <OverlayView
                        onLoad={onLoad}
                        position={{
                          lat: store.location.lat,
                          lng: store.location.lng,
                        }}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                      >
                        <div
                          className="flex h-8 w-8 items-center flex-col cursor-pointer"
                          style={{
                            translate: "-50% -50%",
                          }}
                        >
                          <LocationMarkerIcon className="w-8 h-8 text-gray-300 stroke-1 stroke-text" />
                        </div>
                      </OverlayView>
                    )
                )}
            </GoogleMap>
          ) : (
            <LoadingIndicator />
          )}
        </div>
      </div>
    </div>
  );
};
