import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../api/AuthContext";
import { Campaign, Company, Store } from "../api/data-contracts";
import { LoadingIndicator } from "../components/common/LoadingIndicator";
import { MainPageFooter } from "../components/common/MainPageFooter";
import { MainPageNavigation } from "../components/common/MainPageNavigation";
import { ContactSection } from "../components/detail/ContactSection";
import { HeaderImage } from "../components/detail/HeaderImage";
import { HeaderSection } from "../components/detail/HeaderSection";
import { PromotionsSection } from "../components/detail/PromotionsSection";
import { TagSection } from "../components/detail/TagSection";
import { classNames } from "../util";

import { Helmet } from "react-helmet";
//@ts-ignore
import SmartBanner from "react-smartbanner";
import "react-smartbanner/dist/main.css";
import { StoresSection } from "../components/detail/StoresSection";

type Props = {
  className?: string;
};

export const Detailview: React.FC<Props> = ({ className }) => {
  const { api } = useAuth();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);

  const [company, setCompany] = useState<Company>();

  const [campaigns, setCampaigns] = useState<Campaign[]>();

  const [stores, setStores] = useState<Store[]>();

  useEffect(() => {
    if (!id) {
      return;
    }

    setLoading(true);
    api
      .getDetail(id)
      .then((res) => {
        setCompany(res.data as Company);
        //load promotions
        api
          .campaigns(id)
          .then((res) => {
            setCampaigns(res.data as Campaign[]);
          })
          .catch((err) => {
            console.log(err);
            setCampaigns(undefined);
          });
        //load stores
        api
          .stores(id)
          .then((res) => {
            setStores(res.data as Store[]);
          })
          .catch((err) => {
            console.log(err);
            setStores(undefined);
          });
      })
      .catch((err) => {
        console.log(err);
        setCompany(undefined);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [api, id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="overflow-x-hidden">
      <Helmet>
        {/* <!-- For iOS App Smart Banners --> */}
        <meta name="apple-itunes-app" content="app-id=1473939852" />
        <meta
          name="google-play-app"
          content="app-id=at.klimabuendnis.oberoesterreich.gutesfinden"
        />
        {/* <!--  --> */}
      </Helmet>
      <SmartBanner title={"Gutes Finden"} />

      {/* Background everywhere */}
      <div className="bg-backgroundSecondary fixed top-0 -z-10 left-0 w-full h-full flex justify-center items-center" />

      <MainPageNavigation />
      <div
        className={classNames(
          "flex flex-1 flex-col",
          loading
            ? "bg-background justify-center h-screen"
            : "bg-backgroundSecondary"
        )}
      >
        {loading ? (
          <LoadingIndicator />
        ) : (
          <>
            <HeaderImage
              imageId={(company?.imageIds && company.imageIds[0]) ?? ""}
            />
            <HeaderSection
              name={company?.name ?? ""}
              description={company?.description ?? ""}
              categoryIds={company?.categoryIds ?? []}
            />
            <PromotionsSection campaigns={campaigns} />
            <ContactSection
              mainStore={
                stores && stores.length > 0
                  ? stores.find((store) => store.main === true)
                  : undefined
              }
              additionalStores={stores?.filter((store) => store.main === false)}
            />
            <StoresSection
              stores={stores?.filter((store) => store.main === false)}
            />
            <TagSection tags={company?.tags ?? []} />
          </>
        )}
      </div>
      <MainPageFooter />
    </div>
  );
};
