import { PencilAltIcon } from "@heroicons/react/solid";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useDebounce } from "../../hooks/useDebounce";
import { classNames } from "../../util";

type Props = {
  className?: string;
  debouncedUpdate: (value: string) => void;
  placeholder: string;
  type?: string;
  name?: string;
  debounceDelayInMs?: number;
  title?: string;
  initialText?: string;
};

type PropsWithoutState = {
  className?: string;
  placeholder: string;
  type?: string;
  name?: string;
  title?: string;
  text: string;
  setText: (text: string) => void;
  optional?: boolean;
  border?: boolean;
  validationRegex?: RegExp;
};

export const TextInput: React.FC<Props> = ({
  className,
  debouncedUpdate,
  placeholder,
  type = "text",
  name,
  debounceDelayInMs = 300,
  title,
  initialText,
}) => {
  const [text, setText] = useState(initialText || "");

  const debouncedText = useDebounce<string>(text, debounceDelayInMs);

  useEffect(() => {
    debouncedUpdate(debouncedText);
  }, [debouncedText, debouncedUpdate]);

  return (
    <div
      className={classNames(
        "flex flex-col h-full w-full items-start relative",
        className
      )}
    >
      {title && (
        <p className="text-accent font-semibold text-sm text-left mb-1">
          {title}
        </p>
      )}
      <input
        className="w-full h-7 mb-[1px] px-1 bg-transparent border-0 font-normal text-text text-base focus:ring-0 caret-accent placeholder:text-gray-400"
        name={name}
        type={type}
        placeholder={placeholder}
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <div className="h-[1px] w-full bg-gray-400 rounded-full" />
      <AnimatePresence exitBeforeEnter>
        {initialText && initialText !== text && (
          <motion.div
            className="absolute flex flex-row right-0 bottom-1 text-yellow-500 h-7 gap-2 items-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <PencilAltIcon className="w-4 h-4" />
            <p className="text-xs text-yellow-500 h-3 font-semibold uppercase">
              Geändert
            </p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export const TextInputWithoutState: React.FC<PropsWithoutState> = ({
  className,
  placeholder,
  type = "text",
  name,
  title,
  text,
  setText,
  optional = false,
  validationRegex,
}) => {
  return (
    <div
      className={classNames(
        "flex flex-col h-full w-full items-start relative",
        className
      )}
    >
      {title && (
        <div className="text-accent font-semibold text-sm text-left mb-1 flex flex-row gap-2 items-center">
          {title}
          {optional && <p className="text-accent/60 text-xs">(Optional)</p>}
        </div>
      )}
      <input
        className={classNames(
          "w-full h-7 mb-[1px] px-1 bg-transparent border-0 font-normal text-base focus:ring-0 caret-accent placeholder:text-gray-400",
          validationRegex
            ? validationRegex &&
              (text.match(validationRegex) ?? [""])[0] === text
              ? "text-accent"
              : "text-red"
            : "text-text"
        )}
        name={name}
        type={type}
        placeholder={placeholder}
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <div className="h-[1px] w-full bg-gray-400 rounded-full" />
    </div>
  );
};

export const MultilineTextInput: React.FC<Props> = ({
  className,
  debouncedUpdate,
  placeholder,
  title,
  initialText,
}) => {
  const [text, setText] = useState(initialText || "");

  const debouncedText = useDebounce<string>(text, 500);

  useEffect(() => {
    debouncedUpdate(debouncedText);
  }, [debouncedText, debouncedUpdate]);

  return (
    <div
      className={classNames(
        "flex flex-col h-full w-full items-start relative",
        className
      )}
    >
      {title && (
        <p className="text-accent font-semibold text-sm text-left mb-1">
          {title}
        </p>
      )}
      <textarea
        className="flex bg-backgroundSecondary h-24 items-start justify-start text-left w-full border-0 focus:ring-0 caret-accent text-text font-normal text-sm p-2 rounded-md"
        placeholder={placeholder}
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <AnimatePresence exitBeforeEnter>
        {initialText && initialText !== text && (
          <motion.div
            className="absolute flex flex-row right-2 bottom-1 text-yellow-500 h-7 gap-2 items-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <PencilAltIcon className="w-4 h-4" />
            <p className="text-xs text-yellow-500 h-3 font-semibold uppercase">
              Geändert
            </p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export const MultilineTextInputWithoutState: React.FC<PropsWithoutState> = ({
  className,
  setText,
  placeholder,
  title,
  text,
  border = false,
}) => {
  return (
    <div
      className={classNames(
        "flex flex-col h-full w-full items-start relative",
        className
      )}
    >
      {title && (
        <p className="text-accent font-semibold text-sm text-left mb-1">
          {title}
        </p>
      )}
      <textarea
        className={classNames(
          "flex bg-backgroundSecondary h-24 items-start justify-start text-left w-full focus:ring-0 caret-accent text-text font-normal text-sm p-2 rounded-md",
          border ? "border border-gray-400" : "border-0"
        )}
        placeholder={placeholder}
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
    </div>
  );
};
