import React from "react";
import { classNames } from "../../util";

type Props = {
  className?: string;
  size: number;
  color: string;
  iconPath: string;
  active?: boolean;
};

export const FilterIcon: React.FC<Props> = ({
  className,
  size,
  color,
  iconPath,
  active = true,
}) => {
  return (
    <div
      className={classNames(
        "flex rounded-full justify-center items-center",
        "h-" + size,
        "w-" + size
      )}
      style={{
        backgroundColor: active ? color : "",
        filter: active ? "" : "invert(100%)",
      }}
    >
      <img src={iconPath} alt="" />
    </div>
  );
};
