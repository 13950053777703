import { DownloadIcon, PlusIcon } from "@heroicons/react/solid";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../api/AuthContext";
import { MD } from "../../constants";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { classNames } from "../../util";
import { AnimatedButton } from "../common/AnimatedButton";
import { ContextMenuButton } from "../common/ContextMenuButton";
import { LoadingIndicator } from "../common/LoadingIndicator";

type Props = {
  className?: string;
  isAdmin: boolean;
  openCreateUser?: () => void;
};

export const AdminTopBar: React.FC<Props> = ({
  className,
  isAdmin,
  openCreateUser,
}) => {
  const { logout, api } = useAuth();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  function exportCSV(type: "Company" | "Sendinblue") {
    if (type === "Company") {
      setIsLoading(true);
      api
        .getCompaniesCsvFile(
          { exportType: "Active" },
          {
            headers: {
              Accept: "text/csv",
            },
            format: "text",
          }
        )
        .then((response) => {
          console.log(response);
          // Download file, supported by html5 browsers
          var a = document.createElement("a");
          a.setAttribute(
            "href",
            "data:text/plain;charset=utf-8," +
              encodeURIComponent("" + response.data)
          );
          a.setAttribute("download", "companies.csv");
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          a.remove();
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setIsLoading(false);
        });
    } else if (type === "Sendinblue") {
      setIsLoading(true);
      api
        .exportCsv(
          {},
          {
            headers: {
              Accept: "text/csv",
            },
            format: "text",
          }
        )
        .then((response) => {
          console.log(response);
          // Download file, supported by html5 browsers
          var a = document.createElement("a");
          a.setAttribute(
            "href",
            "data:text/plain;charset=utf-8," +
              encodeURIComponent("" + response.data)
          );
          a.setAttribute("download", "sendinblue-contacts.csv");
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          a.remove();
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  const { width } = useWindowDimensions();

  return (
    <div className="relative flex flex-row h-24 w-full bg-backgroundSecondary justify-between items-center px-8 shadow-sm">
      {isLoading && (
        <motion.div
          className="absolute top-0 left-0 flex flex-col items-center justify-center h-screen w-screen bg-black z-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.5 }}
          transition={{ duration: 0.2 }}
        >
          <LoadingIndicator black />
        </motion.div>
      )}
      <div
        className="flex h-16 w-16 bg-backgroundSecondary rounded-md justify-center items-center text-backgroundSecondary cursor-pointer hover:opacity-70"
        onClick={() => navigate("/")}
      >
        <img
          src={"/img/gutesfinden_logo_web.svg"}
          className={"h-12 w-12"}
          alt=""
        />
      </div>
      <div className="flex flex-row gap-4 md:gap-12 items-center">
        {isAdmin && (
          <div className="flex flex-row gap-4 md:gap-4 items-center">
            <ContextMenuButton
              className="gap-2"
              options={[
                {
                  label: "Betriebe exportieren",
                  onClick: () => exportCSV("Company"),
                },
                {
                  label: "Sendinblue-Kontakte exportieren",
                  onClick: () => exportCSV("Sendinblue"),
                },
              ]}
            >
              <DownloadIcon className="w-4" /> {width >= MD && <p>CSV</p>}
            </ContextMenuButton>

            <AnimatedButton
              className="gap-2"
              onClick={() => openCreateUser && openCreateUser()}
            >
              <PlusIcon className="w-4" /> {width >= MD && <p>Neuer Nutzer</p>}
            </AnimatedButton>
          </div>
        )}
        <div
          className={classNames(
            "uppercase items-center font-extrabold text-lg text-text cursor-pointer hover:opacity-70 transition-al"
          )}
          onClick={logout}
        >
          Abmelden
        </div>
      </div>
    </div>
  );
};
