import { SearchIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { useDebounce } from "../../hooks/useDebounce";

type Props = {
  className?: string;
  debouncedUpdate: (value: string) => void;
};

export const Searchbar: React.FC<Props> = ({ className, debouncedUpdate }) => {
  const [searchText, setSearchText] = useState("");

  const debouncedSearchText = useDebounce<string>(searchText, 300);

  useEffect(() => {
    debouncedUpdate(debouncedSearchText);
  }, [debouncedSearchText]);

  return (
    <div className={"flex flex-col h-full w-48 items-center"}>
      <div className="relative flex flex-row gap-2 w-full items-center">
        <input
          className="w-full h-7 mb-[1px] pl-8 bg-transparent tracking-wider border-0 font-bold text-text text-lg focus:ring-0 caret-accent"
          type="text"
          placeholder=""
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <SearchIcon className="absolute left-0 w-6 h-6 text-accent rotate-90" />
      </div>
      <div className="h-[1px] w-full bg-accentLight rounded-full" />
    </div>
  );
};
