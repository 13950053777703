import { motion } from "framer-motion";
import React from "react";
import { AnimatedButton } from "../common/AnimatedButton";

type Props = {
  className?: string;
  cancel: () => void;
  confirm: () => void;
};

export const ConfirmPushNotificationPopup: React.FC<Props> = ({
  className,
  cancel,
  confirm,
}) => {
  return (
    <motion.div
      className="fixed inset-0 bg-black/50 z-50 flex flex-col overflow-y-scroll"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.2 }}
    >
      <div className="m-auto bg-backgroundSecondary p-8 shadow-sm rounded-md gap-4 flex flex-col">
        <h1 className="text-text font-semibold text-sm w-full text-left">
          Bist du sicher, dass du die Push Benachrichtigung jetzt absenden
          willst?
        </h1>
        <div className="flex flex-row gap-4 w-full justify-end">
          <AnimatedButton
            children={<p>Abbrechen</p>}
            onClick={cancel}
            outline
          />
          <AnimatedButton children={<p>Jetzt senden</p>} onClick={confirm} />
        </div>
      </div>
    </motion.div>
  );
};
