import {
  BellIcon,
  PencilAltIcon,
  PlusIcon,
  UploadIcon,
} from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../api/AuthContext";
import {
  SpecialOfferCreate,
  SpecialOfferUpdate,
} from "../../api/data-contracts";
import { MD } from "../../constants";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {
  asDayDate,
  classNames,
  imageUrlById,
  showError,
  showSuccess,
} from "../../util";
import { AnimatedButton } from "../common/AnimatedButton";
import { LoadingIndicator } from "../common/LoadingIndicator";
import { AdminTextInput } from "./AdminTextInput";

type Props = {
  className?: string;
  filterStartDate: string | undefined;
  filterEndDate: string | undefined;
  filterName: string | undefined;
  filterOfferId: string | undefined;
  filterCompanyIds: string[] | undefined;
  filterImageId: string | undefined;
  filterId: string | undefined;
  update: () => void;
  createNew: boolean | undefined;
};

export const EditFilter: React.FC<Props> = ({
  className,
  filterStartDate,
  filterEndDate,
  filterName,
  filterOfferId,
  filterCompanyIds,
  filterImageId,
  filterId,
  update,
  createNew,
}) => {
  const { api } = useAuth();

  const [startDate, setStartDate] = useState<string | undefined>(
    asDayDate(new Date())
  );
  const [endDate, setEndDate] = useState<string | undefined>(
    asDayDate(new Date())
  );
  const [name, setName] = useState(filterName || "");

  const [localFile, setLocalFile] = useState<File | undefined>(undefined);
  const [imageId, setImageId] = useState<string | undefined>(undefined);
  useEffect(() => {
    setImageId(filterImageId);
  }, [filterImageId]);

  console.log("start", filterStartDate, "end", filterEndDate);
  console.log("start2", startDate, "end2", endDate);

  const [imageLoading, setImageLoading] = useState(false);

  useEffect(() => {
    if (filterEndDate !== undefined) {
      setEndDate(filterEndDate);
    }
    if (filterStartDate !== undefined) {
      setStartDate(filterStartDate);
    }
  }, [filterEndDate, filterStartDate]);

  const [prevCompanyIds, setPrevCompanyIds] = useState<string[]>([]);
  useEffect(() => {
    if (filterCompanyIds !== undefined) {
      setPrevCompanyIds(filterCompanyIds);
    }
  }, [filterCompanyIds]);

  function updateOffer() {
    if (filterId !== undefined && createNew === false) {
      if (localFile !== undefined) {
        api
          .setSpecialOfferImage(filterId, { image: localFile })
          .then(() => update())
          .catch((err) => console.log("error", err));
      }

      const newData: SpecialOfferUpdate = {
        title: name ?? "",
        startDate: new Date(startDate ?? "").toISOString(),
        endDate: new Date(endDate ?? "").toISOString(),
        companyIds: prevCompanyIds,
      };

      api
        .updateOffer(filterOfferId ?? "", newData)
        .then((res) => {
          showSuccess("Erfolgreich upgedated!");
          update();
        })
        .catch((err) => showError(err.error.error));
    } else {
      const newData: SpecialOfferCreate = {
        title: name ?? "",
        startDate: new Date(startDate ?? "").toISOString(),
        endDate: new Date(endDate ?? "").toISOString(),
        published: true,
        companyIds: prevCompanyIds,
      };
      api
        .createOffer(newData)
        .then((res) => {
          const id = res.data.id;
          showSuccess("Erfolgreich hinzugefügt!");
          if (localFile !== undefined) {
            api
              .setSpecialOfferImage(id, { image: localFile })
              .catch((err) => console.log("error", err))
              .finally(() => update());
          }
        })
        .catch((err) => showError(err.error.error))
        .finally(() => update());
    }
  }

  function sendPush() {
    if (filterId !== undefined) {
      if (window.confirm("Push senden?")) {
        api
          .sendSpecialOfferPush(filterId)
          .then((res) => {
            console.log(res);
            showSuccess("Push Benachrichtigung erfolgreich versendet!");
          })
          .catch((err) => {
            console.log(err);
            showError("Ein Fehler ist aufgetreten...");
          });
      }
    }
  }

  const { width } = useWindowDimensions();

  return (
    <div
      className={classNames(
        "flex flex-col relative justify-between py-6 md:py-8 md:px-16 px-4 w-full bg-backgroundSecondary shadow-sm rounded-md",
        className
      )}
    >
      <div className="flex md:flex-row flex-col h-full justify-between w-full gap-4 md:gap-0">
        <div className="relative h-32 md:h-48 w-full md:w-1/4 rounded-md group">
          {localFile ? (
            <img
              src={URL.createObjectURL(localFile)}
              alt=""
              className="h-full w-full rounded-md object-cover bg-background shadow"
              onLoad={() => setImageLoading(false)}
            />
          ) : imageId === "" || imageId === null || imageId === undefined ? (
            <div className="h-full w-full flex gap-4 flex-col text-white cursor-pointer group-hover:opacity-0 justify-center items-center bg-accent rounded-md">
              <UploadIcon className="h-6 w-6" />
              <p>Bild hochladen</p>
            </div>
          ) : (
            <img
              src={imageUrlById(imageId)}
              alt=""
              className="h-full w-full rounded-md object-cover bg-background shadow"
              onLoad={() => setImageLoading(false)}
            />
          )}
          {imageLoading ? (
            <div className="h-full w-full flex z-10 justify-center items-center bg-background absolute top-0 rounded-md">
              <LoadingIndicator />
            </div>
          ) : (
            <div className="absolute top-0 h-full w-full flex gap-4 z-10 flex-col opacity-0 group-hover:opacity-70 text-white cursor-pointer justify-center items-center bg-accent rounded-md">
              <UploadIcon className="h-6 w-6" />
              <p>
                Bild hochladen{" "}
                <span className="opacity-50 text-xs">(max 5MB)</span>
              </p>
              <input
                type={"file"}
                className={"h-full w-full absolute cursor-pointer opacity-0"}
                accept="image/*"
                onChange={(e) => {
                  const file = e.target.files?.[0];

                  if (file) {
                    if (file.size < 5000000) {
                      setLocalFile(file);
                    } else {
                      showError("Bilder dürfen maximal 5MB haben");
                    }
                  }
                }}
              />
            </div>
          )}
        </div>
        <div className="w-full md:w-1/4">
          <AdminTextInput
            title="Filtername"
            text={name}
            setText={(text) => setName(text)}
            placeholder={""}
          />
        </div>
        <div className="flex flex-row h-16 gap-8 items-center">
          {startDate && (
            <AdminTextInput
              title="Von"
              text={startDate}
              setText={(text) => setStartDate(text)}
              placeholder={""}
              type="date"
            />
          )}
          <div className="w-8 h-[2px] bg-accent rounded-full" />
          {endDate && (
            <AdminTextInput
              title="Bis"
              text={endDate}
              setText={(text) => setEndDate(text)}
              placeholder={""}
              type="date"
            />
          )}
        </div>
      </div>
      <div className="flex flex-row gap-2 justify-end">
        {filterId !== undefined && (
          <AnimatedButton
            children={
              <div className="flex flex-row gap-2 items-center flex-0 justify-center h-full">
                <BellIcon className="w-4 h-4" />
                {width >= MD ? (
                  <p className="mt-1">Push Benachrichtigung senden</p>
                ) : (
                  <p className="mt-1">Push senden</p>
                )}
              </div>
            }
            onClick={() => sendPush()}
          />
        )}
        {(name !== filterName ||
          startDate !== filterStartDate ||
          endDate !== filterEndDate ||
          localFile !== undefined) && (
          <AnimatedButton
            children={
              createNew ? (
                <div className="flex flex-row gap-2 items-center justify-center h-full">
                  <PlusIcon className="w-4 h-4" />
                  {width >= MD ? (
                    <p className="mt-1">Filter Erstellen</p>
                  ) : (
                    <p className="mt-1">Filter</p>
                  )}
                </div>
              ) : (
                <div className="flex flex-row gap-2 items-center justify-center h-full">
                  <PencilAltIcon className="w-4 h-4" />
                  {width >= MD ? (
                    <p className="mt-1">Änderungen Speichern</p>
                  ) : (
                    <p className="mt-1">Speichern</p>
                  )}
                </div>
              )
            }
            onClick={() => updateOffer()}
            yellow={createNew ? false : true}
          />
        )}
      </div>
    </div>
  );
};
