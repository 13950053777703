import {
  ChevronDownIcon,
  ChevronUpIcon,
  PencilAltIcon,
} from "@heroicons/react/solid";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../api/AuthContext";
import { User } from "../../api/data-contracts";
import { Toggle } from "../common/Toggle";
import { TextInputWithoutState } from "../common/TextInput";
import { AnimatedButton } from "../common/AnimatedButton";
import { delay } from "../../util";
import { LoadingIndicator } from "../common/LoadingIndicator";

type Props = {
  className?: string;
};

export const AdminSettings: React.FC<Props> = ({ className }) => {
  const [notifications, setNotifications] = useState(false);
  const [serviceMails, setServiceMails] = useState(false);
  const [user, setUser] = useState<User | undefined>();

  const { api, me } = useAuth();

  const [email, setEmail] = useState("");
  function updateEmail() {
    setEmailChangeLoading(true);
    api.changeUserEmailAddress({ email }).then(() => {
      setEmailChangeLoading(false);
      setEmailChanged(true);
    });
  }
  const [emailChanged, setEmailChanged] = useState(false);
  const [emailChangeLoading, setEmailChangeLoading] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const getInitialData = async () => {
      const i = await me.me();
      setUser(i.data);
      setNotifications(i.data.notification ?? false);
      setServiceMails(i.data.newsletter ?? false);
      setEmail(i.data.username ?? "");
    };

    getInitialData().catch(console.error);
  }, [me]);

  function updateNotificationSetting(newState: boolean) {
    api
      .update({
        notification: newState,
        newsletter: serviceMails,
        firstName: user?.firstName ?? "",
        lastName: user?.lastName ?? "",
        phone: user?.phone ?? "",
      })
      .then((res) => {
        setServiceMails((prev) => res.data.newsletter ?? prev);
        setNotifications((prev) => res.data.notification ?? prev);
        setUser(res.data);
      })
      .catch((err) => console.log(err));
  }

  function updateServiceMailsSetting(newState: boolean) {
    api
      .update({
        notification: notifications,
        newsletter: newState,
        firstName: user?.firstName ?? "",
        lastName: user?.lastName ?? "",
        phone: user?.phone ?? "",
      })
      .then((res) => {
        setServiceMails((prev) => res.data.newsletter ?? prev);
        setNotifications((prev) => res.data.notification ?? prev);
        setUser(res.data);
      })
      .catch((err) => console.log(err));
  }

  return (
    <div className="flex flex-col py-3 border-t items-end justify-end border-r-background w-full bg-backgroundSecondary px-8 shadow-sm">
      <div
        className="flex flex-row justify-end text-accent font-bold text-lg hover:opacity-70 cursor-pointer select-none gap-3 items-center"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {"Einstellungen"}
        {isOpen && (
          <motion.div
            initial={{ scaleY: "0%" }}
            animate={{ scaleY: "100%" }}
            exit={{ scaleY: "0%" }}
            transition={{ duration: 0.1 }}
          >
            <ChevronUpIcon className="h-4 w-4" />
          </motion.div>
        )}
        {!isOpen && (
          <motion.div
            initial={{ scaleY: "0%" }}
            animate={{ scaleY: "100%" }}
            exit={{ scaleY: "0%" }}
            transition={{ duration: 0.1 }}
          >
            <ChevronDownIcon className="h-4 w-4" />
          </motion.div>
        )}
      </div>
      <AnimatePresence exitBeforeEnter>
        {isOpen && (
          <motion.div
            className="flex flex-col md:flex-row gap-2 md:gap-8 md:items-center"
            initial={{ opacity: 0, height: "0px", marginTop: "0px" }}
            animate={{ opacity: 1, height: "100%", marginTop: "8px" }}
            exit={{ opacity: 0, height: "0px", marginTop: "0px" }}
            transition={{ duration: 0.1 }}
          >
            {emailChangeLoading ? (
              <LoadingIndicator white />
            ) : emailChanged ? (
              <p>Wir haben dir soeben eine Bestätigungs E-Mail gesendet!</p>
            ) : (
              <>
                <div className="flex flex-col">
                  <p className="text-accent font-semibold text-sm text-left mb-1">
                    {"E-Mail Adresse"}
                  </p>
                  <div className="w-64">
                    <TextInputWithoutState
                      placeholder={"max@mustermann.at"}
                      text={email}
                      setText={(val) => setEmail(val)}
                    />
                  </div>
                </div>
                {email !== user?.username && (
                  <AnimatedButton
                    children={
                      <div className="flex flex-row gap-2 items-center justify-center h-full">
                        <PencilAltIcon className="w-4 h-4" />

                        <p className="mt-1">Ändern</p>
                      </div>
                    }
                    onClick={() => updateEmail()}
                    yellow={true}
                  />
                )}
              </>
            )}
            <div className="flex w-full h-[1px] bg-gray-300 my-2 md:w-[1px] md:h-8" />
            <div className="flex flex-row gap-3 items-center">
              <Toggle
                active={notifications}
                onChange={updateNotificationSetting}
              />
              <p className="text-sm font-semibold">Benachrichtigungen</p>
            </div>

            <div className="flex flex-row gap-3 items-center">
              <Toggle
                active={serviceMails}
                onChange={updateServiceMailsSetting}
              />
              <p className="text-sm font-semibold">Service Mails</p>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
