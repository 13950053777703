import { PlusIcon } from "@heroicons/react/solid";
import React, { useRef, useState } from "react";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import { classNames } from "../../../util";

type Props = {
  className?: string;
  addTag: (name: string) => void;
  alternativeBackground?: boolean;
};

export const AdminInputTag: React.FC<Props> = ({
  className,
  addTag,
  alternativeBackground = false,
}) => {
  const [newTagText, setNewTagText] = useState("");

  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    newTagText !== "" && addTag(newTagText);
    setNewTagText("");
  });

  return (
    <div
      className={classNames(
        "flex flex-row justify-center group items-center pl-2 rounded-md text-accent text-sm gap-1",

        alternativeBackground ? "bg-background" : "bg-backgroundSecondary"
      )}
    >
      <input
        className="flex flex-row justify-start items-center w-full h-full bg-background text-start bg-transparent border-0 outline-none font-normal text-text text-sm focus:ring-0 caret-accent placeholder:text-gray-400"
        value={newTagText}
        onChange={(e) => {
          setNewTagText(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            newTagText !== "" && addTag(newTagText);
            setNewTagText("");
          }
        }}
        ref={ref}
        placeholder="Neues Schlagwort"
      />
      <div
        className="relative flex justify-center items-center h-7 w-8 hover:opacity-70 cursor-pointer"
        onClick={() => {
          newTagText !== "" && addTag(newTagText);
          setNewTagText("");
        }}
      >
        <PlusIcon className="w-4 h-4" />
      </div>
    </div>
  );
};
