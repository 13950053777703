/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Campaign,
  CampaignCreate,
  CampaignDetail,
  CampaignSearchQuery,
  Category,
  Company,
  CompanyCreate,
  CompanySearchQuery,
  CompanySuggest,
  CompanyUpdate,
  FavouriteInfo,
  JSONObject,
  PageCategory,
  PageCompany,
  PageCompanyDetail,
  PageStore,
  PageTag,
  PageUser,
  PushCreate,
  SpecialOfferCreate,
  SpecialOfferDetails,
  SpecialOfferUpdate,
  Store,
  StoreCreate,
  StoreDetail,
  StoreSearchQuery,
  StoreUpdate,
  User,
  UserCompanyStoreCreate,
  UserCompanyStoreCreateResponse,
  UserCreate,
  UserEmailUpdate,
  UserUpdate,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Api<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags User
   * @name GetUser
   * @request GET:/api/user
   */
  getUser = (params: RequestParams = {}) =>
    this.request<User, any>({
      path: `/api/user`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name Update
   * @request PUT:/api/user
   * @secure
   */
  update = (
    data: UserUpdate,
    query?: {
      /** The id of the user to update. Only for admins. */
      id?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<User, any>({
      path: `/api/user`,
      method: "PUT",
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name Create
   * @request POST:/api/user
   */
  create = (data: UserCreate, params: RequestParams = {}) =>
    this.request<User, any>({
      path: `/api/user`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name SetEmailAddress
   * @summary Sets a new email address for a user based on the provided token.
   * @request GET:/api/user/email
   */
  setEmailAddress = (
    query?: {
      /** The token received via email in the change email link */
      token?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<string, string>({
      path: `/api/user/email`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * @description No actual change of email address is performed until confirmation link is used
   *
   * @tags User
   * @name ChangeUserEmailAddress
   * @summary Updates the email address of the the authenticated user by sending out a confirmation link.
   * @request PUT:/api/user/email
   * @secure
   */
  changeUserEmailAddress = (data: UserEmailUpdate, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/user/email`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Store
   * @name UpdateStore
   * @request PUT:/api/store/{id}
   * @secure
   */
  updateStore = (id: string, data: StoreUpdate, params: RequestParams = {}) =>
    this.request<Store, any>({
      path: `/api/store/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Store
   * @name DeleteStore
   * @request DELETE:/api/store/{id}
   * @secure
   */
  deleteStore = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/store/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags password-resource
   * @name SendResetPasswordLink
   * @request GET:/api/reset-pw
   */
  sendResetPasswordLink = (
    query: {
      email: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/reset-pw`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags password-resource
   * @name SetPassword
   * @request PUT:/api/reset-pw
   */
  setPassword = (
    query?: {
      token?: string;
      password?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/reset-pw`,
      method: "PUT",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Push
   * @name SetPushToken
   * @summary Sets the provided push token for the provided device id
   * @request PUT:/api/push
   */
  setPushToken = (
    query: {
      pushToken: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/push`,
      method: "PUT",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Favourite
   * @name FavouriteCompany
   * @summary Favourite a company
   * @request PUT:/api/favourite
   */
  favouriteCompany = (
    query: {
      companyId: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/favourite`,
      method: "PUT",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Favourite
   * @name UnfavouriteCompany
   * @summary Unfavourite a company
   * @request DELETE:/api/favourite
   */
  unfavouriteCompany = (
    query: {
      companyId: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/favourite`,
      method: "DELETE",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Company
   * @name GetDetail
   * @summary Find company by ID
   * @request GET:/api/company/{id}
   */
  getDetail = (id: string, params: RequestParams = {}) =>
    this.request<Company, any>({
      path: `/api/company/${id}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Company
   * @name UpdateCompany
   * @request PUT:/api/company/{id}
   * @secure
   */
  updateCompany = (id: string, data: CompanyUpdate, params: RequestParams = {}) =>
    this.request<Company, any>({
      path: `/api/company/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin Special offer
   * @name UpdateOffer
   * @summary Update an existing offer.
   * @request PUT:/api/admin/special-offer/{id}
   * @secure
   */
  updateOffer = (id: string, data: SpecialOfferUpdate, params: RequestParams = {}) =>
    this.request<SpecialOfferDetails, any>({
      path: `/api/admin/special-offer/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin Special offer
   * @name DeleteOffer
   * @summary Delete an offer
   * @request DELETE:/api/admin/special-offer/{id}
   * @secure
   */
  deleteOffer = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/admin/special-offer/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin Special offer
   * @name SendSpecialOfferPush
   * @summary Sends a push notification for the special offer with the provided ID to all users
   * @request PUT:/api/admin/special-offer/{id}/push
   * @secure
   */
  sendSpecialOfferPush = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/admin/special-offer/${id}/push`,
      method: "PUT",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin Company
   * @name SetCompanyInactive
   * @request PUT:/api/admin/company/deactivate/{id}
   * @secure
   */
  setCompanyInactive = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/admin/company/deactivate/${id}`,
      method: "PUT",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin Company
   * @name SetCompanyActive
   * @request PUT:/api/admin/company/activate/{id}
   * @secure
   */
  setCompanyActive = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/admin/company/activate/${id}`,
      method: "PUT",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Store
   * @name List2
   * @request GET:/api/store
   */
  list2 = (
    query?: {
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 20
       */
      size?: number;
      /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<PageStore, any>({
      path: `/api/store`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Store
   * @name CreateStore
   * @request POST:/api/store
   * @secure
   */
  createStore = (data: StoreCreate, params: RequestParams = {}) =>
    this.request<Store, any>({
      path: `/api/store`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Store
   * @name Search
   * @summary Find stores by search params
   * @request POST:/api/store/search
   */
  search = (data: StoreSearchQuery, params: RequestParams = {}) =>
    this.request<StoreDetail[], any>({
      path: `/api/store/search`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Company
   * @name CreateCompany
   * @summary Creates a new company.
   * @request POST:/api/company
   * @secure
   */
  createCompany = (data: CompanyCreate, params: RequestParams = {}) =>
    this.request<Company, any>({
      path: `/api/company`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Company
   * @name SuggestCompany
   * @summary Suggests a new company to be listed
   * @request POST:/api/company/suggest
   */
  suggestCompany = (data: CompanySuggest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/company/suggest`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Company
   * @name List
   * @summary Find companies by search params
   * @request POST:/api/company/search
   */
  list = (
    data: CompanySearchQuery,
    query?: {
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 20
       */
      size?: number;
      /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<PageCompanyDetail, any>({
      path: `/api/company/search`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Company
   * @name AddCompanyImage
   * @summary Adds an image to the company.
   * @request POST:/api/company/image/{id}
   * @secure
   */
  addCompanyImage = (
    id: string,
    data: {
      /** @format binary */
      image: File;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/company/image/${id}`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * No description
   *
   * @tags Company
   * @name DeleteCompanyImage
   * @summary Deletes an image from the company.
   * @request DELETE:/api/company/image/{id}
   * @secure
   */
  deleteCompanyImage = (
    id: string,
    query: {
      imageId: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/company/image/${id}`,
      method: "DELETE",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Campaign
   * @name GetActiveCampaigns
   * @summary Find active campaigns
   * @request POST:/api/campaign
   */
  getActiveCampaigns = (data: CampaignSearchQuery, params: RequestParams = {}) =>
    this.request<CampaignDetail[], any>({
      path: `/api/campaign`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Campaign
   * @name AddCampaignImage
   * @summary Adds an image to the campaign.
   * @request POST:/api/campaign/image/{id}
   * @secure
   */
  addCampaignImage = (
    id: string,
    data: {
      /** @format binary */
      image: File;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/campaign/image/${id}`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * No description
   *
   * @tags Campaign
   * @name CreateCampaign
   * @summary Creates a new campaign.
   * @request POST:/api/campaign/create
   * @secure
   */
  createCampaign = (data: CampaignCreate, params: RequestParams = {}) =>
    this.request<Campaign, any>({
      path: `/api/campaign/create`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin Special offer
   * @name GetAllSpecialOffers
   * @summary Returns all special offers.
   * @request GET:/api/admin/special-offer
   * @secure
   */
  getAllSpecialOffers = (params: RequestParams = {}) =>
    this.request<SpecialOfferDetails[], any>({
      path: `/api/admin/special-offer`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin Special offer
   * @name CreateOffer
   * @summary Create a new offer.
   * @request POST:/api/admin/special-offer
   * @secure
   */
  createOffer = (data: SpecialOfferCreate, params: RequestParams = {}) =>
    this.request<SpecialOfferDetails, any>({
      path: `/api/admin/special-offer`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin Special offer
   * @name SetSpecialOfferImage
   * @summary Sets the image for the special offer.
   * @request POST:/api/admin/special-offer/image/{id}
   * @secure
   */
  setSpecialOfferImage = (
    id: string,
    data: {
      /** @format binary */
      image: File;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/admin/special-offer/image/${id}`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin Push
   * @name SendPush
   * @summary Sends a push notification with the provided content to all app users.
   * @request POST:/api/admin/push
   * @secure
   */
  sendPush = (data: PushCreate, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/admin/push`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin
   * @name CreateUserAndCompanyAndStore
   * @summary Creates a new user and the company and main store managed by the user. Sends out the invitation email to the created user. The link is valid for 7 days.
   * @request POST:/api/admin/create
   * @secure
   */
  createUserAndCompanyAndStore = (data: UserCompanyStoreCreate, params: RequestParams = {}) =>
    this.request<UserCompanyStoreCreateResponse, any>({
      path: `/api/admin/create`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags version-resource
   * @name Version
   * @request GET:/api/version
   */
  version = (params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/version`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name Companies
   * @request GET:/api/user/companies
   * @secure
   */
  companies = (params: RequestParams = {}) =>
    this.request<Company[], any>({
      path: `/api/user/companies`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags tag-resource
   * @name List1
   * @request GET:/api/tag
   */
  list1 = (
    query?: {
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 20
       */
      size?: number;
      /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
      sort?: string[];
      tagType?: "Company" | "Campaign" | "Suggestion";
      searchQuery?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<PageTag, any>({
      path: `/api/tag`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Store
   * @name GetByCompanyId
   * @request GET:/api/store/{companyId}
   */
  getByCompanyId = (companyId: string, params: RequestParams = {}) =>
    this.request<Store[], any>({
      path: `/api/store/${companyId}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Special offer
   * @name GetActiveOffers
   * @summary Get active offers
   * @request GET:/api/special-offer
   */
  getActiveOffers = (params: RequestParams = {}) =>
    this.request<SpecialOfferDetails[], any>({
      path: `/api/special-offer`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Special offer
   * @name GetSpecialOffer
   * @summary Get special offer by id
   * @request GET:/api/special-offer/{id}
   */
  getSpecialOffer = (id: string, params: RequestParams = {}) =>
    this.request<SpecialOfferDetails, any>({
      path: `/api/special-offer/${id}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags other-resource
   * @name GetMemoryStatistics
   * @request GET:/api/mem-stat
   */
  getMemoryStatistics = (params: RequestParams = {}) =>
    this.request<JSONObject, any>({
      path: `/api/mem-stat`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags media-resource
   * @name Image
   * @request GET:/api/media/{id}
   */
  image = (id: string, params: RequestParams = {}) =>
    this.request<string[], any>({
      path: `/api/media/${id}`,
      method: "GET",
      format: "blob",
      ...params,
    });
  /**
   * @description Logs
   *
   * @tags logging-resource
   * @name Log
   * @request GET:/api/logging
   */
  log = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/logging`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags other-resource
   * @name Host
   * @request GET:/api/host
   */
  host = (params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/host`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags other-resource
   * @name Status
   * @request GET:/api/health
   */
  status = (params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/health`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Company
   * @name User
   * @request GET:/api/company/{id}/user
   */
  user = (id: string, params: RequestParams = {}) =>
    this.request<User, any>({
      path: `/api/company/${id}/user`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Company
   * @name Stores
   * @request GET:/api/company/{id}/stores
   */
  stores = (id: string, params: RequestParams = {}) =>
    this.request<Store[], any>({
      path: `/api/company/${id}/stores`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Company
   * @name FavouriteInfo
   * @request GET:/api/company/{id}/favourite-info
   */
  favouriteInfo = (id: string, params: RequestParams = {}) =>
    this.request<FavouriteInfo, any>({
      path: `/api/company/${id}/favourite-info`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Company
   * @name Categories
   * @request GET:/api/company/{id}/categories
   */
  categories = (id: string, params: RequestParams = {}) =>
    this.request<Category[], any>({
      path: `/api/company/${id}/categories`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Company
   * @name Campaigns
   * @request GET:/api/company/{id}/campaigns
   */
  campaigns = (id: string, params: RequestParams = {}) =>
    this.request<Campaign[], any>({
      path: `/api/company/${id}/campaigns`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Category
   * @name GetList
   * @summary Find categories
   * @request GET:/api/category
   */
  getList = (
    query?: {
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 20
       */
      size?: number;
      /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<PageCategory, any>({
      path: `/api/category`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Category
   * @name GetDetail1
   * @summary Find category by ID
   * @request GET:/api/category/{id}
   */
  getDetail1 = (id: string, params: RequestParams = {}) =>
    this.request<Category, any>({
      path: `/api/category/${id}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Campaign
   * @name GetDetail2
   * @summary Find campaign by ID
   * @request GET:/api/campaign/{id}
   */
  getDetail2 = (id: string, params: RequestParams = {}) =>
    this.request<CampaignDetail, any>({
      path: `/api/campaign/${id}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Campaign
   * @name DeleteCampaign
   * @request DELETE:/api/campaign/{id}
   * @secure
   */
  deleteCampaign = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/campaign/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin
   * @name Users
   * @request GET:/api/admin/users
   * @secure
   */
  users = (
    query?: {
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 20
       */
      size?: number;
      /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<PageUser, any>({
      path: `/api/admin/users`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin User
   * @name ExportCsv
   * @summary Exports users (managing a company) as a .csv file that can be imported in SendInBlue.
   * @request GET:/api/admin/user/csv
   * @secure
   */
  exportCsv = (
    query?: {
      delimiter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<File, any>({
      path: `/api/admin/user/csv`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin Company
   * @name Companies1
   * @request GET:/api/admin/company
   * @secure
   */
  companies1 = (
    query?: {
      searchQuery?: string;
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 20
       */
      size?: number;
      /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<PageCompany, any>({
      path: `/api/admin/company`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin Company
   * @name GetCompany
   * @request GET:/api/admin/company/{id}
   * @secure
   */
  getCompany = (id: string, params: RequestParams = {}) =>
    this.request<Company, any>({
      path: `/api/admin/company/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin Company
   * @name DeleteCompany
   * @request DELETE:/api/admin/company/{id}
   * @secure
   */
  deleteCompany = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/admin/company/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin Company
   * @name GetCompaniesCsvFile
   * @summary Gets all companies as .csv
   * @request GET:/api/admin/company/csv
   * @secure
   */
  getCompaniesCsvFile = (
    query?: {
      exportType?: "All" | "Active" | "Inactive";
      delimiter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<File, any>({
      path: `/api/admin/company/csv`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin
   * @name DeleteUser
   * @request DELETE:/api/admin/user/{id}
   * @secure
   */
  deleteUser = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/admin/user/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}
