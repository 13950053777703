import React from "react";
import { classNames } from "../../util";
import { ChangedIndicator } from "./detail/ChangedIndicator";

type Props = {
  className?: string;
  text: string | undefined;
  setText: (value: string) => void;
  placeholder: string;
  type?: string;
  name?: string;
  title?: string;
  hasChanged?: boolean;
};

export const AdminTextInput: React.FC<Props> = ({
  className,
  text,
  setText,
  placeholder,
  type = "text",
  name,
  title,
  hasChanged = false,
}) => {
  return (
    <div
      className={classNames(
        "flex flex-col h-full w-full items-start relative",
        className
      )}
    >
      {title && (
        <p className="text-accent font-semibold text-sm text-left mb-1">
          {title}
        </p>
      )}
      <input
        className="w-full h-7 mb-[1px] px-1 bg-transparent border-0 font-normal text-text text-base focus:ring-0 caret-accent placeholder:text-gray-400"
        name={name}
        type={type}
        placeholder={placeholder}
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <div className="h-[1px] w-full bg-gray-400 rounded-full" />
      <ChangedIndicator wasEdited={hasChanged} isAbsolute />
    </div>
  );
};

export const AdminMultilineTextInput: React.FC<Props> = ({
  className,
  text,
  setText,
  placeholder,
  type = "text",
  name,
  title,
  hasChanged = false,
}) => {
  return (
    <div
      className={classNames(
        "flex flex-col h-full w-full items-start relative",
        className
      )}
    >
      {title && (
        <p className="text-accent font-semibold text-sm text-left mb-1">
          {title}
        </p>
      )}
      <textarea
        className="flex bg-backgroundSecondary h-24 items-start justify-start text-left w-full border-0 focus:ring-0 caret-accent text-text font-normal text-sm p-2 rounded-md"
        placeholder={placeholder}
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <ChangedIndicator wasEdited={hasChanged} isAbsolute />
    </div>
  );
};
