import React from "react";
import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import { Overview } from "./pages/Overview";
import { Admin } from "./pages/Admin";
import { Login } from "./pages/Login";
import { Detailview } from "./pages/Detailview";
import { AnimatePresence } from "framer-motion";
import { AnimatedOpacityDiv } from "./components/common/AnimatedOpacityDiv";
import AuthGuard from "./pages/AuthGuard";
import { AdminDetail } from "./pages/AdminDetail";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ResetPassword from "./pages/ResetPassword";
import UpdateEmail from "./pages/UpdateEmail";

import GA4React from "ga-4-react";

import CookieConsent from "react-cookie-consent";
import { useLocalStorage } from "./hooks/useLocalStorage";
import Confirm from "./pages/Confirm";

function App() {
  const location = useLocation();

  const [cookiesAllowed, setCookiesAllowed] = useLocalStorage(
    "gutesfinden.cookies_allowed",
    false
  );

  if (cookiesAllowed) {
    try {
      setTimeout((_: any) => {
        const ga4react = new GA4React(
          process.env.REACT_APP_GOOGLE_MEASUREMENT_ID!
        );
        ga4react.initialize().catch((err) => console.log(err));
      }, 4000);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className="App flex flex-col">
      <CookieConsent
        location="bottom"
        buttonText="Zustimmen"
        declineButtonText="Ablehnen"
        cookieName="Cookies"
        extraCookieOptions={{ domain: process.env.REACT_APP_COOKIE_URI! }}
        enableDeclineButton
        onDecline={() => setCookiesAllowed(false)}
        onAccept={() => setCookiesAllowed(true)}
        style={{
          background: "#fff",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}
        buttonStyle={{
          color: "#fff",
          fontSize: "16px",
          background: "#809133",
        }}
        declineButtonStyle={{
          color: "#809133",
          fontSize: "16px",
          background: "#fff",
        }}
        expires={180}
      >
        <p className="text-text text-sm">
          Wir verwenden Cookies, um Ihnen die bestmögliche Nutzungserfahrung zu
          bieten. Sie stimmen der Nutzung von Cookies und unseren
          Datenschutzbestimmungen zu.{" "}
          <span className="font-semibold underline">
            <a href="https://www.gutesfinden.at/datenschutz/">Mehr dazu</a>
          </span>
        </p>
      </CookieConsent>
      <ToastContainer limit={6} />
      <AnimatePresence exitBeforeEnter>
        <Routes location={location} key={location.pathname}>
          <Route path="reset-password" element={<ResetPassword />} />

          <Route
            path="/"
            element={
              <AnimatedOpacityDiv key={"page_overview"}>
                <Overview />
              </AnimatedOpacityDiv>
            }
          />

          <Route
            path="/detail/:id/"
            element={
              <AnimatedOpacityDiv key={"page_detail"}>
                <Detailview />
              </AnimatedOpacityDiv>
            }
          />

          <Route
            path="login"
            element={
              <AnimatedOpacityDiv key={"page_login"}>
                <Login />
              </AnimatedOpacityDiv>
            }
          />

          <Route
            path="confirm"
            element={
              <AnimatedOpacityDiv key={"page_confirm"}>
                <Confirm />
              </AnimatedOpacityDiv>
            }
          />

<Route
            path="update-email"
            element={
              <AnimatedOpacityDiv key={"page_confirm"}>
                <UpdateEmail />
              </AnimatedOpacityDiv>
            }
          />

          <Route
            path="manage"
            element={
              <AuthGuard>
                <AnimatedOpacityDiv key={"page_admin"}>
                  <Admin />
                </AnimatedOpacityDiv>
              </AuthGuard>
            }
          />
          <Route
            path="manage/detail/:id/"
            element={
              <AuthGuard>
                <AnimatedOpacityDiv key={"page_admin_deatil"}>
                  <AdminDetail />
                </AnimatedOpacityDiv>
              </AuthGuard>
            }
          />
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default App;
