import React, { useRef, useState } from "react";
import { Company, Store } from "../../../api/data-contracts";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import { AnimatedButton } from "../../common/AnimatedButton";
import { TextInputWithoutState } from "../../common/TextInput";
import { Toggle } from "../../common/Toggle";

type Props = {
  className?: string;
  close: () => void;
  addStore: (store: Store) => void;
  isFirst: boolean;
  company: Company | undefined;
};

export const AddStorePopup: React.FC<Props> = ({
  className,
  close,
  addStore,
  isFirst,
  company,
}) => {
  const ref = useRef(null);
  useOnClickOutside(ref, () => close());

  const [localStore, setLocalStore] = useState<Store>({
    id:
      (Math.random() * 100000000000000000).toString(16).replace(".", "") +
      "_localID",
    title: "",
    street: "",
    postCode: "",
    town: "",
    phone: "",
    website: "",
    email: "",
    main: isFirst,
    companyId: company?.id ?? "",
    location: {
      lat: 0,
      lng: 0,
    },
  });

  const [tempLocation, setTempLocation] = useState({
    lat: "",
    lng: "",
  });

  return (
    <div className="fixed inset-0 bg-black bg-opacity-20 z-20 flex overflow-scroll">
      <div
        className="flex flex-col h-fit m-auto w-full max-w-2xl bg-backgroundSecondary rounded-md p-4 overflow-scroll"
        ref={ref}
      >
        <div className="flex w-full flex-col items-start md:col-span-1 gap-6">
          {/* Header */}
          <p className="flex text-text text-base font-bold flex-row gap-2">
            Neuen Standort hinzufügen
          </p>
          <TextInputWithoutState
            title={"Standortname"}
            placeholder={"Standort XYZ"}
            text={localStore.title}
            setText={(text) =>
              setLocalStore((prev) => ({
                ...prev,
                title: text,
              }))
            }
          />
          <TextInputWithoutState
            title={"Straße & Hausnummer"}
            placeholder={"Musterstraße 1"}
            text={localStore.street}
            setText={(text) =>
              setLocalStore((prev) => ({
                ...prev,
                street: text,
              }))
            }
          />
          <div className="flex flex-col gap-1">
            <div className="flex flex-row gap-4 w-full">
              <TextInputWithoutState
                title={"PLZ"}
                placeholder={"4020"}
                text={localStore.postCode}
                setText={(text) =>
                  setLocalStore((prev) => ({
                    ...prev,
                    postCode: text,
                  }))
                }
              />
              <TextInputWithoutState
                title={"Ort"}
                placeholder={"Linz"}
                text={localStore.town}
                setText={(text) =>
                  setLocalStore((prev) => ({
                    ...prev,
                    town: text,
                  }))
                }
              />
            </div>
            <div className="flex flex-row gap-4 w-full">
              <TextInputWithoutState
                title={"Breitengrad (Latitude)"}
                validationRegex={/^((\-?|\+?)?\d+(\.\d+)?)/}
                placeholder={"48.12345"}
                text={tempLocation.lat ?? ""}
                setText={(text) => {
                  setTempLocation((prev) => ({
                    ...prev,
                    lat: text.replaceAll(",", "."),
                  }));
                  setLocalStore((prev) => ({
                    ...prev,
                    location: {
                      ...prev.location,
                      lat: Number(text === "" ? "0" : text) ?? 0,
                    },
                  }));
                }}
              />
              <TextInputWithoutState
                title={"Längengrad (Longitude)"}
                validationRegex={/^((\-?|\+?)?\d+(\.\d+)?)/}
                placeholder={"14.12345"}
                text={tempLocation.lng ?? ""}
                setText={(text) => {
                  setTempLocation((prev) => ({
                    ...prev,
                    lng: text.replaceAll(",", "."),
                  }));
                  setLocalStore((prev) => ({
                    ...prev,
                    location: {
                      ...prev.location,
                      lng: Number(text === "" ? "0" : text) ?? 0,
                    },
                  }));
                }}
              />
            </div>
            <p className="text-xs font-normal text-text text-left opacity-50">
              Füge hier bitte Längen- und Breitengrade der Adresse ein damit wir
              deinen Standort auf der Karte anzeigen können. Um diese zu
              ermitteln kannst du diese kostenlose Website verwenden:
              <span
                className="text-accent hover:opacity-70 cursor-pointer"
                onClick={() => window.open("https://www.latlong.net/")}
              >
                {" "}
                https://www.latlong.net/{" "}
              </span>
              . Einfach Adresse eingeben und auf “Find” klicken. Im Anschluss
              dann den Wert von Latitude in das Feld Breitengrade kopieren und
              den Wert von Longitude in das Feld Längengrade.
            </p>
          </div>
          <TextInputWithoutState
            title={"Telefonnummer"}
            placeholder={"+43 660 0000000"}
            text={localStore.phone ?? ""}
            setText={(text) =>
              setLocalStore((prev) => ({
                ...prev,
                phone: text,
              }))
            }
            optional
          />
          <div className="flex flex-row gap-4 w-full">
            <TextInputWithoutState
              title={"Webseite"}
              placeholder={"www.mein-standort.at"}
              text={localStore.website ?? ""}
              setText={(text) =>
                setLocalStore((prev) => ({
                  ...prev,
                  website: text,
                }))
              }
              optional
            />
            <TextInputWithoutState
              title={"E-Mail"}
              placeholder={"info@mein-standort.at"}
              text={localStore.email ?? ""}
              setText={(text) =>
                setLocalStore((prev) => ({
                  ...prev,
                  email: text,
                }))
              }
              optional
            />
          </div>
          <div>
            <div className="text-accent font-semibold text-sm text-left mb-1 flex flex-row gap-2 items-center">
              Hauptsitz?
            </div>
            <Toggle
              active={localStore.main}
              onChange={(active) =>
                setLocalStore((prev) => ({
                  ...prev,
                  main: active,
                }))
              }
            />
          </div>
          {/* Bottom buttons */}
          <div className="w-full justify-end flex flex-row gap-4">
            <AnimatedButton
              children={<p>Abbrechen</p>}
              onClick={() => close()}
              outline
            />
            <AnimatedButton
              children={<p>Hinzufügen</p>}
              onClick={() => {
                addStore(localStore);
              }}
              active={
                localStore.postCode !== "" &&
                localStore.town !== "" &&
                localStore.street !== "" &&
                localStore.title !== ""
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
