import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../api/AuthContext";
import { LoadingIndicator } from "../components/common/LoadingIndicator";

export default function UpdateEmail() {
  const { api } = useAuth();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const email = searchParams.get("email");
  const navigate = useNavigate();
  const goToLoginPage = () => navigate(`/login?email=${email}`);

  const [text, setText] = useState("Email erfolgreich geändert. Bitte melde dich mit deiner neuen Email-Adresse an. Dein Passwort ist unverändert.")

  const [countdown, setCountdown] = useState(5)

  useEffect(() => {
    api.setEmailAddress({token:token??""}).then(() => {
      setText("Email erfolgreich geändert. Bitte melde dich mit deiner neuen Email-Adresse an. Dein Passwort ist unverändert.")
      setTimeout(() => {
        setCountdown(4)
      }, 1000);
      setTimeout(() => {
        setCountdown(3)
      }, 2000);
      setTimeout(() => {
        setCountdown(2)
      }, 3000);
      setTimeout(() => {
        setCountdown(1)
      }, 4000);
      setTimeout(() => {
        goToLoginPage()
      }, 5000);
    }).catch((err) => {
      setText("ERROR "+JSON.stringify(err))
    })
  }, [token])
  

  return (
    <div
      className={
        "flex flex-col justify-center items-center h-screen w-full bg-background"
      }
    >
      <div className="fixed top-0 items-center bg-backgroundSecondary w-full h-16 pl-8">
        <img
          src="/img/gutesfinden_logo_web.svg"
          alt="Logo"
          className="h-8 mt-4"
        />
      </div>

      <div className="p-16 w-2/3">
        <div className="flex flex-col w-full h-full items-center">
          {text === "" ? <LoadingIndicator /> : <p>{text}</p>}
          {text !== "" && !text.includes("ERROR") && <p className="mt-4 font-semibold text-sm">Du wirst in {countdown} Sekunden weitergeleitet!</p>}
        </div>
      </div>
    </div>
  );
}
