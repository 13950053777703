import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  GoogleMap,
  OverlayView,
  useJsApiLoader,
  DirectionsRenderer,
} from "@react-google-maps/api";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { LoadingIndicator } from "../common/LoadingIndicator";
import { AnimatePresence, motion } from "framer-motion";
import {
  ChevronRightIcon,
  LocationMarkerIcon,
  SearchIcon,
} from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../api/AuthContext";
import { Store, StoreDetail } from "../../api/data-contracts";
import { usePosition } from "../../hooks/usePosition";
import { useDebounce } from "../../hooks/useDebounce";
import { classNames, getDistance } from "../../util";

type Props = {
  className?: string;
};

export const Map: React.FC<Props> = ({ className, children }) => {
  const google = window.google;
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY!,
  });

  const [searchText, setSearchText] = useState("");

  const [googleProposals, setGoogleProposals] = useState<
    google.maps.GeocoderResult[] | undefined
  >(undefined);

  const debouncedSearchText = useDebounce(searchText, 300);

  useEffect(() => {
    if (isLoaded && google) {
      console.log("fetching google proposals", google.maps.version);
      console.log(isLoaded);
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: searchText }, (results, status) => {
        if (
          status === google.maps.GeocoderStatus.OK &&
          results &&
          results.length > 0
        ) {
          setGoogleProposals(results);
        } else {
          console.error("error fetching directions", results, status);
          setGoogleProposals(undefined);
        }
      });
    }
  }, [debouncedSearchText, google, searchText, isLoaded]);

  const { latitude, longitude, positionError } = usePosition();

  const { api } = useAuth();

  const [directionResult, setDirectionResult] = useState<
    google.maps.DirectionsResult | undefined
  >(undefined);

  function cancelNavigation() {
    setDirectionResult(undefined);
  }

  function navigateToLocation(to: { lat: number; lng: number }) {
    if (google) {
      const directionsService = new google.maps.DirectionsService();
      directionsService.route(
        {
          destination: { lat: to.lat, lng: to.lng },
          origin: { lat: latitude ?? 48, lng: longitude ?? 14 },
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            setDirectionResult(result ?? undefined);
            setFullMarker(undefined);
          } else {
            console.error("error fetching directions", result, status);
          }
        }
      );
    }
  }

  const { height } = useWindowDimensions();
  const bottomMargin = 64;
  const containerStyle = {
    width: "100%",
    height: `${height - 96 - bottomMargin}px`,
  };

  const [centerPos, setCenterPos] = useState({
    lat: latitude ?? 48,
    lng: longitude ?? 14,
  });

  const [searchPos, setSearchPos] = useState({
    lat: latitude ?? 48,
    lng: longitude ?? 14,
    distance: 10,
  });

  const debouncedSearchPos = useDebounce<{
    lat: number;
    lng: number;
    distance: number;
  }>(searchPos, 1000);

  useEffect(() => {
    setCenterPos({ lat: latitude ?? 48, lng: longitude ?? 14 });
    setSearchPos({ lat: latitude ?? 48, lng: longitude ?? 14, distance: 10 });
  }, [latitude, longitude]);

  const navigate = useNavigate();

  const [map, setMap] = useState<any>();

  const [fullMarker, setFullMarker] = useState<string | undefined>(undefined);

  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(undefined);
  }, []);

  const [markers, setMarkers] = useState<StoreDetail[]>([]);
  useEffect(() => {
    api
      .search({
        near: {
          latitude: debouncedSearchPos.lat,
          longitude: debouncedSearchPos.lng,
          maxDistanceKm: debouncedSearchPos.distance,
        },
      })
      .then((res) => {
        setMarkers(res.data);
      });
  }, [api, debouncedSearchPos]);

  function searchGoogle() {
    if (isLoaded && google) {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: searchText }, (results, status) => {
        if (
          status === google.maps.GeocoderStatus.OK &&
          results &&
          results.length > 0
        ) {
          const location = results[0].geometry.location;
          setSearchPos({
            lat: location.lat(),
            lng: location.lng(),
            distance: 10,
          });
          setCenterPos({ lat: location.lat(), lng: location.lng() });
          setSearchText("");
        } else {
          console.error("error fetching directions", results, status);
        }
      });
    }
  }

  return isLoaded && google ? (
    <>
      <div className="relative">
        {/* Cancel Navigation */}
        {directionResult && (
          <div
            className="flex justify-center items-center absolute rounded-sm shadow-md top-[10px] h-10 right-[60px] z-10 px-3 bg-white cursor-pointer text-gray-600  hover:text-gray-900"
            onClick={() => cancelNavigation()}
          >
            <p className="font-medium text-lg">Abbrechen</p>
          </div>
        )}

        {/* Search Card */}
        <div className="absolute flex flex-row pr-2 pl-4 py-2 gap-2 top-[10px] left-[10px] h-10 w-64 bg-white z-10 rounded-sm shadow-md">
          <input
            className="border-0 h-full w-full text-gray-900 font-normal text-base focus:ring-0 ring-0 outline-none caret-accent placeholder:text-gray-400"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Suche"
          />
          {/* divider */}
          <div className="h-full w-[1px] bg-gray-300" />
          {/* Search Button */}
          <SearchIcon
            className="w-6 h-6 text-gray-600 cursor-pointer hover:opacity-70"
            onClick={() => {
              searchGoogle();
              setGoogleProposals(undefined);
            }}
          />
        </div>
        {googleProposals && googleProposals.length > 0 && (
          <div className="absolute top-[55px] left-[10px] w-64 bg-white z-10">
            {googleProposals.map((proposal) => (
              <div
                className="min-h-10 py-2 cursor-pointer text-left hover:bg-gray-200 hover:text-gray-900 border-b border-b-gray-200 last:border-none items-center justify-start flex flex-row px-4 text-gray-700"
                onClick={() => {
                  searchGoogle();
                  setGoogleProposals(undefined);
                }}
              >
                {proposal.formatted_address}
              </div>
            ))}
          </div>
        )}
      </div>
      {/* Map */}
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={centerPos}
        zoom={13}
        onBoundsChanged={() => {
          if (map?.map) {
            // handle bounds
            const m = map?.map;
            const bounds = m.getBounds();
            if (bounds) {
              const ne = bounds.getNorthEast();
              const sw = bounds.getSouthWest();
              const distance = getDistance(
                ne.lat(),
                ne.lng(),
                sw.lat(),
                sw.lng()
              );

              //handle searchpos
              setSearchPos({
                lat: m.center.lat(),
                lng: m.center.lng(),
                distance: distance / 2 ?? 10,
              });
            }
          }
        }}
        options={{
          minZoom: 13,
          styles: [
            {
              featureType: "poi",
              elementType: "labels",
              stylers: [{ visibility: "off" }],
            },
            {
              featureType: "transit",
              elementType: "labels",
              stylers: [{ visibility: "off" }],
            },
          ],
          mapTypeControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
          },
        }}
        onLoad={onLoad}
        onDrag={() => setFullMarker(undefined)}
        onUnmount={onUnmount}
        mapContainerClassName="max-h-[60vh] mb-8 bg-accentLight"
      >
        {/* Child components, such as markers, info windows, etc. */}
        {directionResult && <DirectionsRenderer directions={directionResult} />}

        {markers.map((marker) => (
          <Marker
            key={JSON.stringify(marker)}
            marker={marker}
            onLoad={onLoad}
            fullMarker={fullMarker}
            setCenterPos={setCenterPos}
            map={map}
            onClick={(id) => {
              setFullMarker(id);
            }}
            onNavigateClick={(lat, lng) => {
              navigateToLocation({ lat: lat, lng: lng });
            }}
            onFullMarkerClick={(companyId) => {
              navigate(`/detail/${companyId}`);
            }}
            color={
              marker.categories &&
              marker.categories.length > 0 &&
              marker.categories[0]
                ? marker.categories[0].colorHex
                : "#D1DD77"
            }
            locationAvailable={
              (latitude !== undefined || latitude !== null) &&
              (longitude !== undefined || longitude !== null) &&
              !positionError
            }
          />
        ))}
      </GoogleMap>
    </>
  ) : (
    <LoadingIndicator className="h-[60vh]" />
  );
};

type MarkerProps = {
  className?: string;
  marker: Store;
  onLoad: (map: any) => void;
  fullMarker: string | undefined;
  setCenterPos: (pos: { lat: number; lng: number }) => void;
  map: any;
  onClick: (id: string) => void;
  onNavigateClick: (lat: number, lng: number) => void;
  onFullMarkerClick: (companyId: string) => void;
  color: string;
  locationAvailable: boolean;
};

export const Marker: React.FC<MarkerProps> = ({
  className,
  marker,
  onLoad,
  fullMarker,
  setCenterPos,
  map,
  onClick,
  onNavigateClick,
  onFullMarkerClick,
  color,
  locationAvailable,
}) => {
  return (
    <AnimatePresence>
      {fullMarker === marker.id ? (
        // Big Marker
        <OverlayView
          onLoad={onLoad}
          position={{
            lat: marker.location.lat ?? 48,
            lng: marker.location.lng ?? 14,
          }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <motion.div
            className={classNames(
              "absolute flex flex-grow gap-2 z-10 bg-backgroundSecondary items-center flex-col overflow-hidden"
            )}
            // whileHover={{
            //   scale: fullMarker === marker.id ? 1.05 : 0,
            //   opacity: fullMarker === marker.id ? 0.9 : 0,
            //   transition: { duration: 0.1 },
            // }}

            initial={{
              //opacity: 0,
              //scale: 0,
              //borderRadius: "50%",
              translateX: "-50%",
              translateY: "-50%",
            }}
            animate={{
              opacity: fullMarker === marker.id ? 1 : 0,
              scale: fullMarker === marker.id ? 1 : 0,
              borderRadius: fullMarker === marker.id ? 0 : "50%",
            }}
            exit={{
              opacity: 0,
              scale: 0,
              borderRadius: "50%",
            }}
            transition={{ duration: 0.4 }}
          >
            {/* {marker.id !== "" ? (
              <img
                src={imageUrlById(marker.id)}
                alt="Header"
                className="h-16 w-full object-cover"
              />
            ) : (
              <div className="h-16 w-full bg-accentLight" />
            )} */}
            <div
              className="cursor-pointer pl-6 pr-2 py-4 max-w-64 min-w-32 gap-4 hover:opacity-70 flex flex-grow flex-row justify-center items-center"
              onClick={() => onFullMarkerClick(marker.companyId)}
            >
              <div className="flex flex-col flex-grow flex-shrink">
                <p className="flex justify-start items-start text-left text-text font-bold text-sm ">
                  {marker.title}
                </p>
                <div className="flex flex-row text-accent text-xs font-normal justify-between text-left flex-1">
                  <p>{marker.street}</p>
                </div>
              </div>
              <ChevronRightIcon className="h-6 flex-grow-0 flex-shrink-0 text-text/50" />
            </div>
            {locationAvailable && (
              <div
                className="bg-accent w-full h-12 hover:opacity-70 justify-center cursor-pointer text-white text-sm font-semibold flex px-6 items-center"
                onClick={() =>
                  onNavigateClick(
                    marker.location.lat ?? 48,
                    marker.location.lng ?? 14
                  )
                }
              >
                Navigieren
                {/* <ReplyIcon className="w-3 h-3 -scale-x-100" /> */}
              </div>
            )}
          </motion.div>
        </OverlayView>
      ) : (
        // Small Marker
        <OverlayView
          onLoad={onLoad}
          position={{
            lat: marker.location.lat ?? 48,
            lng: marker.location.lng ?? 14,
          }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <motion.div
            className="flex h-8 w-8 items-center flex-col cursor-pointer"
            whileHover={{
              scale: fullMarker !== marker.id ? 1.2 : 0,
              opacity: fullMarker !== marker.id ? 0.9 : 0,
              transition: { duration: 0.1 },
            }}
            onClick={() => {
              // setCenterPos({
              //   lat: marker.location.lat ?? 48,
              //   lng: marker.location.lng ?? 14,
              // });
              //map.map.setZoom(14);
              onClick(marker.id);
            }}
            initial={{
              //opacity: 0,
              //scale: 0,
              translateX: "-50%",
              translateY: "-50%",
            }}
            animate={{
              opacity: fullMarker !== marker.id ? 1 : 0,
              scale: fullMarker !== marker.id ? 1 : 0,
            }}
            exit={{
              opacity: 0,
              scale: 0,
            }}
            transition={{ duration: 0.4 }}
          >
            <LocationMarkerIcon
              className={classNames("w-8 h-8 stroke-1 stroke-text")}
              style={{ color: color }}
            />
          </motion.div>
        </OverlayView>
      )}
    </AnimatePresence>
  );
};
