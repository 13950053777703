import React, { useEffect, useState } from "react";
import { useAuth } from "../../api/AuthContext";
import { Category, PageCompanyDetail } from "../../api/data-contracts";
import { usePosition } from "../../hooks/usePosition";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { calculateDistanceInKm, classNames, imageUrlById } from "../../util";
import { AnimatedButton } from "../common/AnimatedButton";
import { LoadingIndicator } from "../common/LoadingIndicator";
import { PageSelector } from "../common/PageSelector";
import { Card } from "./Card";

type Props = {
  className?: string;
  openSuggestScreen: () => void;
  searchText: string;
  activeFilters: Category[];
  selectedPage: number;
  setSelectedPage: (number: number) => void;
};

export const CardGrid: React.FC<Props> = ({
  className,
  openSuggestScreen,
  searchText,
  activeFilters,
  selectedPage,
  setSelectedPage,
}) => {
  const { api } = useAuth();

  const [cards, setCards] = useState<PageCompanyDetail>();

  const { latitude, longitude, positionError } = usePosition();

  const [loading, setLoading] = useState(true);

  const defaultLon = 14;
  const defaultLat = 48;

  const { width } = useWindowDimensions();

  const [initialLoaded, setInitialLoaded] = useState(false);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    if (initialLoaded) {
      if (selectedPage !== 1) {
        setSelectedPage(1);
      } else {
        setUpdate(!update);
      }
    } else {
      setInitialLoaded(true);
    }
  }, [searchText]);

  useEffect(() => {
    setLoading(true);
    api
      .list(
        {
          text: searchText,
          categoryIds: activeFilters.map((f) => f.id),
          near: {
            latitude: !positionError && latitude ? latitude : defaultLat,
            longitude: !positionError && longitude ? longitude : defaultLon,
            //maxDistanceKm: 150,
          },
        },
        {
          page: selectedPage - 1,
          size: width >= 1280 && width < 1536 ? 16 : 18,
        },
        {
          headers: {
            platform: "web",
            "device-id":
              "faa9fabaeefd59f94db62e0f62311f767e2f1b49509b81bacf0ce873dacb9817c74db2bb517729f05c2aed4c725651ccb54899e77033175b1f40a2f6886aa43b",
          },
        }
      )
      .then((res) => {
        setCards(res.data as PageCompanyDetail);
      })
      .catch((err) => {
        console.log("error");
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    activeFilters,
    api,
    latitude,
    longitude,
    positionError,
    update,
    selectedPage,
    width,
  ]);

  return (
    <div className="relative flex flex-col mb-16 top-0 min-h-screen">
      <div
        className={classNames(
          "grid gap-8 w-full mb-8",
          cards && cards.content && cards.content.length > 0
            ? "md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6"
            : ""
        )}
      >
        {cards && cards.content && cards.content.length > 0 ? (
          cards.content.map((card, index) => (
            <Card
              key={index}
              name={card.name ?? ""}
              location={card.mainStore?.street ?? ""}
              distance={
                card.mainStore?.location.lat !== 0 &&
                card.mainStore?.location.lng !== 0
                  ? !positionError &&
                    longitude !== undefined &&
                    latitude !== undefined
                    ? calculateDistanceInKm(
                        latitude,
                        longitude,
                        card.mainStore?.location.lat,
                        card.mainStore?.location.lng
                      )
                    : ""
                  : undefined
              }
              imageUrl={
                card.imageIds && card.imageIds[0]
                  ? imageUrlById(card.imageIds[0])
                  : ""
              }
              id={card.id}
              categoryImageId={
                card.categories && card.categories[0]
                  ? card.categories[0].imageId
                  : undefined
              }
              categoryColor={
                card.categories && card.categories[0]
                  ? card.categories[0].colorHex
                  : undefined
              }
            />
          ))
        ) : loading ? (
          <div className="flex flex-col gap-6 mt-32 items-center justify-center text-text">
            <LoadingIndicator />
          </div>
        ) : (
          <div className="flex flex-col gap-6 mt-32 items-center justify-center text-text">
            <p className="text-lg font-bold uppercase">
              Es gab leider keine Ergebnisse zu deiner Suche ...
            </p>
            <AnimatedButton onClick={() => openSuggestScreen()}>
              Betrieb vorschlagen?
            </AnimatedButton>
          </div>
        )}
      </div>
      {cards && cards.content && cards.content.length > 0 && (
        <PageSelector
          pages={cards.totalPages ?? 0}
          activePage={Number(selectedPage)}
          setActivePage={(number) => setSelectedPage(number)}
        />
      )}
    </div>
  );
};
