import React, { useState } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { MobilePageNavigation } from "./MobilePageNavigation";
import { motion } from "framer-motion";
import { classNames } from "../../util";

type Props = {
  className?: string;
};

export const MainPageNavigation: React.FC<Props> = ({ className }) => {
  const { width } = useWindowDimensions();

  return width >= 1400 ? (
    <div
      className={
        "flex flex-row justify-between items-center pl-[16px] pr-[36px] gap-[0px]  h-[100px] w-full bg-backgroundSecondary"
      }
    >
      <img
        src={"/img/gutesfinden_logo_web.svg"}
        className="aspect-square w-[100px] hover:opacity-70 cursor-pointer mb-[1px]"
        alt="Logo"
        onClick={() => window.open("https://www.gutesfinden.at/", "_self")}
      />
      <div className="flex flex-row items-start gap-8 mt-[6px]">
        <DesktopNavigationButton
          title={"KONSUMENT:INNEN"}
          submenus={[
            { title: "Gutes Finden", link: "https://app.gutesfinden.at/" },
            {
              title: "Aktiv werden",
              link: "https://www.gutesfinden.at/aktiv-werden/",
            },
            {
              title: "Gütesiegel",
              link: "https://gutesfinden.at/guetesiegel/",
            },
            {
              title: "Fußabdruck",
              link: "https://gutesfinden.at/fussabdruck/",
            },
            { title: "Termine", link: "https://gutesfinden.at/termine/" },
          ]}
        />
        <DesktopNavigationButton
          title={"ERNÄHRUNGSINITIATIVEN"}
          submenus={[
            {
              title: "Appetit auf Gutes",
              link: "https://gutesfinden.at/appetit-auf-gutes/",
            },
            {
              title: "FoodCoops und SoLaWis",
              link: "https://gutesfinden.at/foodcoops-und-solawis/",
            },
            { title: "Portraits", link: "https://gutesfinden.at/portraits/" },
            {
              title: "Alle Lebensmittelinitiativen in OÖ",
              link: "https://gutesfinden.at/alle-lebensmittelinitiativen-in-ooe/",
            },
            {
              title: "Gründungsinfos",
              link: "https://gutesfinden.at/gruendungsinfos/",
            },
          ]}
        />
        <DesktopNavigationButton
          title={"BILDUNGSEINRICHTUNGEN"}
          submenus={[
            {
              title: "Besser Essen",
              link: "https://gutesfinden.at/besser-essen/",
            },
            {
              title: "Klimabündnis Bildungseinrichtung",
              link: "https://gutesfinden.at/klimabuendnis-bildungseinrichtung/",
            },
          ]}
        />
        <DesktopNavigationButton
          title={"BETRIEBE"}
          submenus={[
            {
              title: "Gutes Finden Registrierung",
              link: "https://www.gutesfinden.at/gutes_finden_registrierung/",
            },
            {
              title: "Klimabündnis Betrieb",
              link: "https://gutesfinden.at/klimabuendnis-betrieb/",
            },
          ]}
        />
        <DesktopNavigationButton
          title={"GEMEINDEN"}
          submenus={[
            {
              title: "Fair Trade Gemeinden",
              link: "https://gutesfinden.at/fair-trade-gemeinden/",
            },
            {
              title: "Appetit auf Gutes- Beratung",
              link: "https://gutesfinden.at/appetit-auf-gutes-beratung/",
            },
            {
              title: "Klimabündnis Gemeinde",
              link: "https://gutesfinden.at/klimabuendnis-gemeinde/",
            },
          ]}
          align={"right"}
        />
      </div>
    </div>
  ) : (
    <MobilePageNavigation />
  );
};

const DesktopNavigationButton: React.FC<{
  title: string;
  submenus: { title: string; link: string }[];
  align?: "left" | "right";
}> = ({ title, submenus, align = "left" }) => {
  const [showSubmenus, setShowSubmenus] = useState(false);

  return (
    <div
      className="relative font-familyBold tracking-[0.199em] text-xl text-text cursor-pointer"
      onMouseOver={() => {
        setShowSubmenus(true);
      }}
      onMouseLeave={() => {
        setShowSubmenus(false);
      }}
    >
      {title}

      <motion.div
        className={classNames(
          "absolute z-10 bg-backgroundSecondary rounded-3xl flex flex-col justify-start  whitespace-nowrap",
          align === "right"
            ? "-right-4 items-end text-right"
            : "-left-4 items-start text-left"
        )}
        initial={{
          height: 0,
          y: -40,
          scaleY: 0,
          transformOrigin: "top",
          paddingLeft: 0,
          paddingRight: 0,
          opacity: 0,
        }}
        animate={{
          height: showSubmenus ? "auto" : 0,
          y: showSubmenus ? 0 : -40,
          paddingLeft: showSubmenus ? 16 : 0,
          paddingRight: showSubmenus ? 16 : 0,
          scaleY: showSubmenus ? 1 : 0,
          opacity: showSubmenus ? 1 : 0,
          transition: {
            duration: showSubmenus ? 0.3 : 0, // Add animation duration only when showing
          },
        }}
      >
        {submenus.map((submenu) => {
          return (
            <motion.div
              className="text-lg my-2.5 first:mt-6 last:mb-6"
              animate={{
                opacity: showSubmenus ? 1 : 0,
                transition: {
                  duration: 0,
                },
              }}
              onClick={() => {
                window.open(submenu.link, "_self");
              }}
            >
              {submenu.title}
            </motion.div>
          );
        })}
      </motion.div>
    </div>
  );
};
