import React from "react";
import { AdminMultilineTextInput } from "../../admin/AdminTextInput";

type Props = {
  className?: string;
  description: string | undefined;
  onChange: (name: string) => void;
  wasEdited: boolean;
};

export const CompanyDescription: React.FC<Props> = ({
  className,
  description,
  onChange,
  wasEdited,
}) => {
  return (
    <div className={"flex w-full lg:w-1/2"}>
      <AdminMultilineTextInput
        title={"Beschreibung"}
        setText={(text) => onChange(text)}
        placeholder={"..."}
        text={description}
        hasChanged={wasEdited}
      />
    </div>
  );
};
