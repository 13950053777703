import { XCircleIcon } from "@heroicons/react/solid";
import React from "react";
import { classNames } from "../../../util";

type Props = {
  className?: string;
  name: string;
  removeTag: (name: string) => void;
  alternativeBackground?: boolean;
};

export const AdminTag: React.FC<Props> = ({
  className,
  name,
  removeTag,
  alternativeBackground = false,
}) => {
  return (
    <div
      className={classNames(
        "flex flex-row group items-center  rounded-md px-2 py-0.5 text-accent text-sm gap-2 cursor-pointer hover:opacity-70",
        alternativeBackground ? "bg-background" : "bg-backgroundSecondary"
      )}
      onClick={() => {
        removeTag(name);
      }}
    >
      {name}
      <XCircleIcon className="w-4 h-4 group-hover:text-red" />
    </div>
  );
};
