import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function useSearchParamsState<T extends string | number | boolean>(
  paramName: string,
  defaultValue: T
): [T, (newValue: T) => void] {
  const location = useLocation();
  const navigate = useNavigate();

  const urlSearchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const getParamValue = () => {
    const paramValue = urlSearchParams.get(paramName);
    return paramValue !== null ? (paramValue as T) : defaultValue;
  };

  const setParamValue = (newValue: T) => {
    const newSearchParams = new URLSearchParams(urlSearchParams.toString());
    if (newValue === defaultValue) {
      newSearchParams.delete(paramName);
    } else {
      newSearchParams.set(paramName, newValue.toString());
    }

    // Use navigate to update the URL
    navigate({ search: `?${newSearchParams.toString()}` });
  };

  useEffect(() => {
    const handlePopState = () => {
      const newParamValue = getParamValue();
      setParamValue(newParamValue);
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [paramName, defaultValue]);

  const paramValue = getParamValue();

  return [paramValue, setParamValue];
}

export default useSearchParamsState;
