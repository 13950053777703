import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Category } from "../api/data-contracts";
import { AnimatedOpacityDiv } from "../components/common/AnimatedOpacityDiv";
import { MainPageFooter } from "../components/common/MainPageFooter";
import { MainPageNavigation } from "../components/common/MainPageNavigation";
import { CardGrid } from "../components/overview/CardGrid";
import { HeaderImage } from "../components/overview/HeaderImage";
import { Map } from "../components/overview/Map";
import { NavigationBar } from "../components/overview/NavigationBar";
import { Suggest } from "../components/overview/Suggest";
import { useLocalStorage } from "../hooks/useLocalStorage";
import useSearchParamsState from "../hooks/useSearchParamsState";

import { Helmet } from "react-helmet";
//@ts-ignore
import SmartBanner from "react-smartbanner";
import "react-smartbanner/dist/main.css";

type Props = {
  className?: string;
};

export const Overview: React.FC<Props> = ({ className }) => {
  const [currentTab, setCurrentTab] = useState(0);

  const [activeFilters, setActiveFilters] = useLocalStorage<Category[]>(
    "activeFilters",
    []
  );

  const [searchText, setSearchText] = useSearchParamsState<string>(
    "search",
    ""
  );

  const [selectedPage, setSelectedPage] = useSearchParamsState<number>(
    "page",
    1
  );

  return (
    <div className="overflow-x-hidden">
      <Helmet>
        {/* <!-- For iOS App Smart Banners --> */}
        <meta name="apple-itunes-app" content="app-id=1473939852" />
        <meta
          name="google-play-app"
          content="app-id=at.klimabuendnis.oberoesterreich.gutesfinden"
        />
        {/* <!--  --> */}
      </Helmet>

      {/* Background everywhere */}
      <div className="bg-background flex flex-col fixed top-0 -z-10 left-0 w-full h-full" />
      <SmartBanner title={"Gutes Finden"} />

      <MainPageNavigation />
      <HeaderImage />
      <h4 className="p-8 flex flex-col display-linebreak text-center text-xl text-text md:mx-24 lg:mx-32 xl:mx-48 mt-8">
        Mit „Gutes Finden“ könnt ihr klimafreundliche Betriebe in eurem Umfeld erkunden. Entdeckt Bio-Direktvermarkter:innen, Modeläden oder Reparaturbetriebe in ganz Oberösterreich und findet vegetarische, faire oder plastikfreie Angebote in eurer Umgebung!
      </h4>
      <div className="bg-background flex flex-1 flex-col gap-8 md:mx-24 lg:mx-32 xl:mx-48 mt-8">
        <NavigationBar
          currentTab={currentTab}
          onTabChange={(index) => setCurrentTab(index)}
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
          searchTextChanged={(val) => {
            setSearchText(val);
          }}
          setSelectedPage={setSelectedPage}
        />

        <AnimatePresence exitBeforeEnter>
          {currentTab === 0 && (
            <AnimatedOpacityDiv key={"page_overview_0"} className={"mx-4"}>
              <CardGrid
                openSuggestScreen={() => setCurrentTab(2)}
                searchText={searchText}
                activeFilters={activeFilters}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
            </AnimatedOpacityDiv>
          )}
          {currentTab === 1 && (
            <AnimatedOpacityDiv key={"page_overview_1"}>
              <Map />
            </AnimatedOpacityDiv>
          )}
          {currentTab === 2 && (
            <AnimatedOpacityDiv key={"page_overview_2"} className={"mx-4"}>
              <Suggest />
            </AnimatedOpacityDiv>
          )}
        </AnimatePresence>
      </div>
      <MainPageFooter />
    </div>
  );
};
