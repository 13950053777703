import { motion } from "framer-motion";
import React from "react";
import { classNames } from "../../util";

type Props = {
  className?: string;
  children: React.ReactNode;
  onClick: () => void;
  active?: boolean;
  yellow?: boolean;
  red?: boolean;
  outline?: boolean;
};

export const AnimatedButton: React.FC<Props> = ({
  className,
  children,
  onClick,
  active = true,
  yellow = false,
  red = false,
  outline = false,
}) => {
  return (
    <motion.div
      className={classNames(
        "text-sm md:text-base font-semibold rounded-md md:px-5 px-3 py-2 md:py-1 items-center flex flex-row justify-center transition-all",
        active && "hover:opacity-90",
        active
          ? outline
            ? "border border-accent text-accent cursor-pointer"
            : "cursor-pointer text-backgroundSecondary"
          : "cursor-not-allowed text-backgroundSecondary",
        className,
        !active
          ? "bg-gray-400 "
          : yellow
          ? "bg-yellow-500"
          : red
          ? "bg-red"
          : outline
          ? "bg-transparent"
          : "bg-accent"
      )}
      whileHover={{
        scale: active ? 1.05 : 1,
        transition: { duration: 0.1 },
      }}
      onClick={() => active && onClick()}
    >
      {children}
    </motion.div>
  );
};
