import React, { useEffect, useState } from "react";
import { useAuth } from "../../api/AuthContext";
import { showSuccess } from "../../util";
import { AnimatedButton } from "../common/AnimatedButton";
import {
  MultilineTextInputWithoutState,
  TextInputWithoutState,
} from "../common/TextInput";
import { SuggestTag } from "./SuggestTag";

type Props = {
  className?: string;
};

export const Suggest: React.FC<Props> = ({ className }) => {
  const [selectedTags, setSelectedTags] = useState([false, false, false]);

  const [infos, setInfos] = useState({
    companyName: "",
    reason: "",
    myContact: "",
    location: "",
    //telephone: "",
    email: "",
    website: "",
  });

  const { api } = useAuth();
  function send() {
    api
      .suggestCompany({
        name: infos.companyName,
        location: infos.location,
        email: infos.email,
        website: infos.website,
        reason:
          infos.reason + " Gewählte Tags:" + selectedTags[0]
            ? "Super Sortiert"
            : "---" + selectedTags[1]
            ? "Top Qualität"
            : "---" + selectedTags[2]
            ? "Vorbildcharakter"
            : "---",
        contactEmail: infos.myContact,
      })
      .then((res) => {
        showSuccess(
          "Deine Empfehlung wurde an uns gesendet und der vorgeschlagene Betrieb wird informiert. Danke, dass du gemeinsam mit uns Gutes findest!"
        );

        setInfos({
          companyName: "",
          reason: "",
          myContact: "",
          location: "",
          //telephone: "",
          email: "",
          website: "",
        });
      });
  }

  return (
    <div
      className={
        "grid md:grid-cols-2 justify-center items-center gap-8 lg:gap-16 mb-16"
      }
    >
      <div className="flex w-full flex-col items-start md:col-span-1">
        {/* Header */}
        <p className="text-text text-base font-bold">
          Grund für die Empfehlung (optional)
        </p>
        <p className="text-text text-sm font-normal mb-2 text-left">
          Warum sollte gerade dieser Betrieb bei Gutes Finden auffindbar sein?
        </p>

        {/* Input field */}
        <MultilineTextInputWithoutState
          className="mb-6"
          placeholder="Hier kannst du, wenn du willst, ein paar Worte zum Betrieb schreiben und unten einen Rückfragekontakt angeben."
          setText={(text) =>
            setInfos((prev) => ({
              ...prev,
              reason: text,
            }))
          }
          text={infos.reason}
          optional
        />

        {/* Tags */}
        <div className="mb-6 flex flex-row gap-2">
          <SuggestTag
            name={"Super sortiert"}
            onClick={() =>
              setSelectedTags((prev) => [!prev[0], prev[1], prev[2]])
            }
            selected={selectedTags[0]}
          />
          <SuggestTag
            name={"Top Qualität"}
            onClick={() =>
              setSelectedTags((prev) => [prev[0], !prev[1], prev[2]])
            }
            selected={selectedTags[1]}
          />
          <SuggestTag
            name={"Vorbildcharakter"}
            onClick={() =>
              setSelectedTags((prev) => [prev[0], prev[1], !prev[2]])
            }
            selected={selectedTags[2]}
          />
        </div>

        <TextInputWithoutState
          setText={(text) =>
            setInfos((prev) => ({
              ...prev,
              myContact: text,
            }))
          }
          placeholder={"Meine E-Mail für Rückfragen (optional)"}
          text={infos.myContact}
        />
      </div>

      <div className="flex w-full flex-col items-start md:col-span-1 gap-6">
        {/* Header */}
        <p className="flex text-text text-base font-bold flex-row gap-2">
          Betriebsinfos
        </p>

        <TextInputWithoutState
          setText={(text) =>
            setInfos((prev) => ({
              ...prev,
              companyName: text,
            }))
          }
          placeholder={"Betriebsname"}
          className={""}
          text={infos.companyName}
        />

        <TextInputWithoutState
          setText={(text) =>
            setInfos((prev) => ({
              ...prev,
              location: text,
            }))
          }
          text={infos.location}
          placeholder={"Ort (optional)"}
          optional
        />

        {/* <TextInput
          debouncedUpdate={(text) => console.log(text)}
          placeholder={"Telefon"}
        /> */}

        <TextInputWithoutState
          setText={(text) =>
            setInfos((prev) => ({
              ...prev,
              email: text,
            }))
          }
          placeholder={"E-Mail (optional)"}
          text={infos.email}
          optional
        />
        <TextInputWithoutState
          setText={(text) =>
            setInfos((prev) => ({
              ...prev,
              website: text,
            }))
          }
          placeholder={"Website (optional)"}
          text={infos.website}
          optional
        />
      </div>
      <AnimatedButton
        onClick={() => send()}
        className={"md:col-span-2 md:w-48 mx-auto"}
        active={infos.companyName.length > 0}
      >
        Absenden
      </AnimatedButton>
    </div>
  );
};
