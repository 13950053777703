import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { motion } from "framer-motion";
import React from "react";
import { classNames } from "../../util";
import { ArrowButton } from "./ArrowButton";

type Props = {
  className?: string;
  pages: number;
  activePage: number;
  setActivePage: (page: number) => void;
};

export const PageSelector: React.FC<Props> = ({
  className,
  pages,
  activePage,
  setActivePage,
}) => {
  let pageArray: number[] = [];
  for (let index = 1; index <= pages; index++) {
    pageArray.push(index);
  }

  return (
    <div
      className={
        "flex h-full w-full justify-center items-center gap-2 select-none"
      }
    >
      <ArrowButton
        onClick={() => setActivePage(activePage - 1)}
        direction="left"
        active={activePage > 1}
      />

      {/* Max 6 pages are displayed */}
      {pages <= 6 &&
        pageArray.map((pageNumber, index) => {
          return (
            <PageSelectorItem
              number={pageNumber}
              activePage={activePage}
              onClick={() => setActivePage(pageNumber)}
            />
          );
        })}

      {/* More than 6 pages are displayed and the user is on one of the first 2 pages */}
      {pages > 6 && Number(activePage) < 5 && (
        <div className="flex flex-row gap-1">
          <PageSelectorItem
            number={1}
            activePage={Number(activePage)}
            onClick={() => setActivePage(1)}
          />
          <PageSelectorItem
            number={2}
            activePage={Number(activePage)}
            onClick={() => setActivePage(2)}
          />
          <PageSelectorItem
            number={3}
            activePage={Number(activePage)}
            onClick={() => setActivePage(3)}
          />
          <PageSelectorItem
            number={4}
            activePage={Number(activePage)}
            onClick={() => setActivePage(4)}
          />
          <PageSelectorItem
            number={5}
            activePage={Number(activePage)}
            onClick={() => setActivePage(5)}
          />
          <div className="w-4 h-6 text-gray-400 items-center justify-center">
            ...
          </div>
          <PageSelectorItem
            number={pages}
            activePage={Number(activePage)}
            onClick={() => setActivePage(pages)}
          />
        </div>
      )}

      {/* More than 6 pages are displayed and the user is on one of the middle pages */}
      {pages > 6 && Number(activePage) >= 5 && Number(activePage) < pages - 3 && (
        <div className="flex flex-row gap-1">
          <PageSelectorItem
            number={1}
            activePage={Number(activePage)}
            onClick={() => setActivePage(1)}
          />
          <div className="w-4 h-6 text-gray-400 items-center justify-center">
            ...
          </div>
          <PageSelectorItem
            number={Number(activePage) - 1}
            activePage={Number(activePage)}
            onClick={() => setActivePage(activePage - 1)}
          />
          <PageSelectorItem
            number={Number(activePage)}
            activePage={Number(activePage)}
            onClick={() => setActivePage(activePage)}
          />
          <PageSelectorItem
            number={Number(activePage) + 1}
            activePage={Number(activePage)}
            onClick={() => setActivePage(Number(activePage) + 1)}
          />
          <div className="w-4 h-6 text-gray-400 items-center justify-center">
            ...
          </div>
          <PageSelectorItem
            number={pages}
            activePage={Number(activePage)}
            onClick={() => setActivePage(pages)}
          />
        </div>
      )}

      {/* More than 6 pages are displayed and the user is on one of the last 4 pages, 7 is a special case where both would show - so hide this */}
      {pages > 6 && Number(activePage) > pages - 4 && Number(activePage) !== 4 && (
        <div className="flex flex-row gap-1">
          <PageSelectorItem
            number={1}
            activePage={Number(activePage)}
            onClick={() => setActivePage(1)}
          />
          <div className="w-4 h-6 text-gray-400 items-center justify-center">
            ...
          </div>
          <PageSelectorItem
            number={pages - 4}
            activePage={Number(activePage)}
            onClick={() => setActivePage(pages - 4)}
          />
          <PageSelectorItem
            number={pages - 3}
            activePage={Number(activePage)}
            onClick={() => setActivePage(pages - 3)}
          />
          <PageSelectorItem
            number={pages - 2}
            activePage={Number(activePage)}
            onClick={() => setActivePage(pages - 2)}
          />
          <PageSelectorItem
            number={pages - 1}
            activePage={Number(activePage)}
            onClick={() => setActivePage(pages - 1)}
          />
          <PageSelectorItem
            number={pages}
            activePage={Number(activePage)}
            onClick={() => setActivePage(pages)}
          />
        </div>
      )}

      <ArrowButton
        onClick={() => setActivePage(Number(activePage) + 1)}
        direction="right"
        active={Number(activePage) < pages}
      />
    </div>
  );
};

type PageSelectorItemProps = {
  className?: string;
  number: number;
  activePage: number;
  onClick: () => void;
};

export const PageSelectorItem: React.FC<PageSelectorItemProps> = ({
  className,
  number,
  activePage,
  onClick,
}) => {
  return (
    <motion.div
      className={classNames(
        "flex h-6 font-bold justify-center items-center",
        number === activePage ? "text-accent" : "text-text cursor-pointer",
        number < 10 && "w-4",
        number >= 10 && number < 100 && "w-6",
        number >= 100 && number < 1000 && "w-8"
      )}
      onClick={onClick}
      whileHover={{
        scale: number !== activePage ? 1.2 : 1,
        transition: { duration: 0.1 },
      }}
      key={number}
    >
      {number}
    </motion.div>
  );
};
