import React from "react";
import { classNames } from "../../util";

type Props = {
  className?: string;
  active: boolean | undefined;
  onChange: (active: boolean) => void;
};

export const Toggle: React.FC<Props> = ({ className, active, onChange }) => {
  return (
    <div
      className={classNames(
        "relative w-10 h-6 rounded-full cursor-pointer",
        active ? "bg-accent" : "bg-gray-300"
      )}
      onClick={() => onChange(!active)}
    >
      <div
        className={classNames(
          "absolute h-4 w-4 top-1 rounded-full transition-all left-1 bg-backgroundSecondary",
          active && "translate-x-4"
        )}
      />
    </div>
  );
};
