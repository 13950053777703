import React from "react";
import { imageUrlById } from "../../util";

type Props = {
  className?: string;
  imageId: string;
};

export const HeaderImage: React.FC<Props> = ({ className, imageId }) => {
  return imageId ? (
    <img
      className={"flex h-96 w-full bg-gray-200 mb-4 md:mb-8 object-cover"}
      src={imageUrlById(imageId)}
      alt="header"
    />
  ) : (
    <div className="w-full h-8" />
  );
};
