import React from "react";

type Props = {
  className?: string;
  name: string;
};

export const Tag: React.FC<Props> = ({ className, name }) => {
  return (
    <div className="flex flex-row group items-center justify-center bg-accentVeryLight rounded-md px-3 py-1 tracking-wider text-accent text-base">
      {name}
    </div>
  );
};
