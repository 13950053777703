import { PencilAltIcon } from "@heroicons/react/solid";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { classNames } from "../../../util";

type Props = {
  className?: string;
  wasEdited: boolean;
  isAbsolute?: boolean;
};

export const ChangedIndicator: React.FC<Props> = ({
  className,
  wasEdited,
  isAbsolute,
}) => {
  return (
    <AnimatePresence exitBeforeEnter>
      {wasEdited && (
        <motion.div
          className={classNames(
            "flex flex-row text-yellow-500 h-7 gap-2 items-center",
            isAbsolute && "absolute right-2 bottom-0"
          )}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          key="ChangedIndicator"
        >
          <PencilAltIcon className="w-4 h-4" />
          <p className="text-xs text-yellow-500 h-3 font-semibold uppercase">
            Geändert
          </p>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
