import React from "react";
import { Tag } from "../common/Tag";

type Props = {
  className?: string;
  tags: string[];
};

export const TagSection: React.FC<Props> = ({ className, tags }) => {
  return (
    <div className="relative flex flex-1 flex-col px-12 md:px-24 lg:px-32 xl:px-48 mb-16">
      <div className="flex flex-wrap gap-2 w-full">
        {tags.map((tag, index) => (
          <Tag name={tag} key={tag + index} />
        ))}
      </div>
    </div>
  );
};
