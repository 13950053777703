import React from "react";
import { classNames } from "../../util";

type Props = {
  className?: string;
  name: string;
  onClick: (name: string) => void;
  selected: boolean;
};

export const SuggestTag: React.FC<Props> = ({
  className,
  name,
  onClick,
  selected,
}) => {
  return (
    <div
      className={classNames(
        "flex flex-row group items-center  rounded-md px-2 py-0.5  text-sm cursor-pointer hover:opacity-70",
        selected
          ? "bg-accent text-backgroundSecondary"
          : "bg-backgroundSecondary text-gray-400"
      )}
      onClick={() => {
        onClick(name);
      }}
    >
      {name}
    </div>
  );
};
