import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { classNames } from "../../util";

type Props = {
  className?: string;
};

export const MobilePageNavigation: React.FC<Props> = ({ className }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const [submenuIndex, setSubmenuIndex] = useState(-1);

  return (
    <>
      <AnimatePresence>
        {menuOpen && (
          <motion.div
            initial={{ left: "100%" }}
            animate={{ left: "0px" }}
            exit={{ left: "100%" }}
            transition={{ duration: 0.5 }}
            className="fixed h-screen w-full z-40 bg-[#D1DD77] px-[22px] flex flex-col justify-start items-center"
          >
            {/* ---- MENU OPTIONS */}
            <div className="flex flex-col justify-between h-full w-full items-center gap-4 mt-6">
              <div className="flex flex-col justify-start w-full gap-[11px]">
                <MobileNavigationButton
                  title={"KONSUMENT:INNEN"}
                  submenus={[
                    {
                      title: "Gutes Finden",
                      link: "https://app.gutesfinden.at/",
                    },
                    {
                      title: "Aktiv werden",
                      link: "https://www.gutesfinden.at/aktiv-werden/",
                    },
                    {
                      title: "Gütesiegel",
                      link: "https://gutesfinden.at/guetesiegel/",
                    },
                    {
                      title: "Fußabdruck",
                      link: "https://gutesfinden.at/fussabdruck/",
                    },
                    {
                      title: "Termine",
                      link: "https://gutesfinden.at/termine/",
                    },
                  ]}
                  isOpen={submenuIndex === 0}
                  onToggle={() => {
                    setSubmenuIndex((prev) => {
                      if (prev === 0) {
                        return -1;
                      } else {
                        return 0;
                      }
                    });
                  }}
                />
                <MobileNavigationButton
                  title={"ERNÄHRUNGSINITIATIVEN"}
                  submenus={[
                    {
                      title: "Appetit auf Gutes",
                      link: "https://gutesfinden.at/appetit-auf-gutes/",
                    },
                    {
                      title: "FoodCoops und SoLaWis",
                      link: "https://gutesfinden.at/foodcoops-und-solawis/",
                    },
                    {
                      title: "Portraits",
                      link: "https://gutesfinden.at/portraits/",
                    },
                    {
                      title: "Alle Lebensmittelinitiativen in OÖ",
                      link: "https://gutesfinden.at/alle-lebensmittelinitiativen-in-ooe/",
                    },
                    {
                      title: "Gründungsinfos",
                      link: "https://gutesfinden.at/gruendungsinfos/",
                    },
                  ]}
                  isOpen={submenuIndex === 1}
                  onToggle={() => {
                    setSubmenuIndex((prev) => {
                      if (prev === 1) {
                        return -1;
                      } else {
                        return 1;
                      }
                    });
                  }}
                />
                <MobileNavigationButton
                  title={"BILDUNGSEINRICHTUNGEN"}
                  submenus={[
                    {
                      title: "Besser Essen",
                      link: "https://gutesfinden.at/besser-essen/",
                    },
                    {
                      title: "Klimabündnis Bildungseinrichtung",
                      link: "https://gutesfinden.at/klimabuendnis-bildungseinrichtung/",
                    },
                  ]}
                  isOpen={submenuIndex === 2}
                  onToggle={() => {
                    setSubmenuIndex((prev) => {
                      if (prev === 2) {
                        return -1;
                      } else {
                        return 2;
                      }
                    });
                  }}
                />
                <MobileNavigationButton
                  title={"BETRIEBE"}
                  submenus={[
                    {
                      title: "Gutes Finden Registrierung",
                      link: "https://www.gutesfinden.at/gutes_finden_registrierung/",
                    },
                    {
                      title: "Klimabündnis Betrieb",
                      link: "https://gutesfinden.at/klimabuendnis-betrieb/",
                    },
                  ]}
                  isOpen={submenuIndex === 3}
                  onToggle={() => {
                    setSubmenuIndex((prev) => {
                      if (prev === 3) {
                        return -1;
                      } else {
                        return 3;
                      }
                    });
                  }}
                />
                <MobileNavigationButton
                  title={"GEMEINDEN"}
                  submenus={[
                    {
                      title: "Fair Trade Gemeinden",
                      link: "https://gutesfinden.at/fair-trade-gemeinden/",
                    },
                    {
                      title: "Appetit auf Gutes- Beratung",
                      link: "https://gutesfinden.at/appetit-auf-gutes-beratung/",
                    },
                    {
                      title: "Klimabündnis Gemeinde",
                      link: "https://gutesfinden.at/klimabuendnis-gemeinde/",
                    },
                  ]}
                  isOpen={submenuIndex === 4}
                  onToggle={() => {
                    setSubmenuIndex((prev) => {
                      if (prev === 4) {
                        return -1;
                      } else {
                        return 4;
                      }
                    });
                  }}
                />
              </div>

              {/* small links */}
              <div className="flex flex-col gap-2 mb-2 mt-4 tracking-[0.06em]">
                <p
                  className="items-center text-base text-text text-center cursor-pointer hover:opacity-70 transition-all"
                  onClick={() =>
                    window.open("https://gutesfinden.at/ueberuns/", "_self")
                  }
                >
                  Über uns
                </p>
                <p
                  className="items-center text-base text-text text-center cursor-pointer hover:opacity-70 transition-all"
                  onClick={() =>
                    window.open(
                      "https://www.gutesfinden.at/downloads/",
                      "_self"
                    )
                  }
                >
                  Downloads & Presse
                </p>
                <p
                  className="items-center text-base text-text text-center cursor-pointer hover:opacity-70 transition-all"
                  onClick={() =>
                    window.open(
                      "https://www.gutesfinden.at/impressum/",
                      "_self"
                    )
                  }
                >
                  Impressum
                </p>
                <p
                  className="items-center text-base text-text text-center cursor-pointer hover:opacity-70 transition-all"
                  onClick={() =>
                    window.open(
                      "https://www.gutesfinden.at/datenschutz/",
                      "_self"
                    )
                  }
                >
                  Datenschutz
                </p>
              </div>
            </div>
            {/* ---- END MENU OPTIONS */}
          </motion.div>
        )}
      </AnimatePresence>
      <div
        className={
          "flex h-[75px] w-full bg-backgroundSecondary justify-center items-center px-[22px] relative"
        }
      >
        <img
          src={"/img/gutesfinden_logo_web.svg"}
          className="h-[74px] w-[74px] md:mb-[7px] ml-[1px] hover:opacity-70 cursor-pointer"
          alt="Logo"
          onClick={() => window.open("https://www.gutesfinden.at/", "_self")}
        />

        <div
          className={classNames(
            "w-[23px] h-[23px] mb-[2px] mr-[2px] mt-[1px] cursor-pointer flex flex-col justify-between z-50 right-6 top-6",
            menuOpen ? "fixed" : "absolute"
          )}
          onClick={() => setMenuOpen((prev) => !prev)}
        >
          <motion.div
            animate={{
              rotate: menuOpen ? 45 : 0,
              width: menuOpen ? "125%" : "100%",
              translateX: menuOpen ? "5px" : "0px",
              translateY: menuOpen ? "-1px" : "0px",
            }}
            transition={{ duration: 0.5 }}
            className="rounded-full h-[3px] bg-black origin-top-left"
          />

          <AnimatePresence>
            {!menuOpen && (
              <motion.div
                className="w-full rounded-full h-[3px] bg-black"
                initial={{ scaleX: "0%" }}
                animate={{ scaleX: "100%" }}
                exit={{ scaleX: "0%" }}
                transition={{ duration: 0.5 }}
              />
            )}
          </AnimatePresence>
          <motion.div
            animate={{
              rotate: menuOpen ? -45 : 0,
              width: menuOpen ? "125%" : "100%",
              translateX: menuOpen ? "2px" : "0px",
              translateY: menuOpen ? "-1px" : "0px",
            }}
            transition={{ duration: 0.5 }}
            className="rounded-full h-[3px] bg-black origin-top-left"
          />
        </div>
      </div>
    </>
  );
};

const MobileNavigationButton: React.FC<{
  title: string;
  submenus: { title: string; link: string }[];
  isOpen: boolean;
  onToggle: () => void;
}> = ({ title, submenus, isOpen, onToggle }) => {
  return (
    <div className="w-full flex flex-col items-center justify-center text-text">
      <h1
        className="font-familyBold tracking-[0.175em] mt-[1px] text-lg mb-[5px] text-text cursor-pointer"
        onClick={() => {
          onToggle();
        }}
      >
        {title}
      </h1>

      <div className="bg-background/70 w-full py-1.5 rounded-3xl px-0">
        <AnimatePresence>
          {submenus.map((submenu) => {
            return (
              isOpen && (
                <motion.div
                  className="text-base cursor-pointer font-familyBold tracking-extraWide"
                  initial={{
                    height: 0,
                    scaleY: 0,
                    opacity: 0,
                  }}
                  animate={{
                    height: "auto",
                    scaleY: 1,
                    opacity: 1,

                    transition: {
                      duration: 0.2,
                    },
                  }}
                  exit={{
                    height: 0,
                    scaleY: 0,
                    opacity: 0,
                    transition: {
                      duration: 0.2,
                    },
                  }}
                  onClick={() => {
                    window.open(submenu.link, "_self");
                  }}
                >
                  <p className="my-2.5">{submenu.title}</p>
                </motion.div>
              )
            );
          })}
        </AnimatePresence>
      </div>
    </div>
  );
};
