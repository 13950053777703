import { ChevronDownIcon } from "@heroicons/react/solid";
import React from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";

type Props = {
  className?: string;
};

export const HeaderImage: React.FC<Props> = ({ className }) => {
  const { height } = useWindowDimensions();

  return (
    <div className={"flex h-auto w-full relative"}>
      <img
        src={"/img/HeaderImage.jpg"}
        alt="Header"
        className="h-full w-full object-cover object-center"
      />
      <div className="absolute bottom-4 left-0 right-0 flex flex-row justify-center items-center">
        <div
          className="w-8 h-8 flex justify-center items-center  z-10 shadow-md cursor-pointer hover:text-white text-accentLight"
          onClick={() =>
            window.scrollTo({ top: height * 0.75, behavior: "smooth" })
          }
        >
          <ChevronDownIcon className="w-6 h-6" />
        </div>
      </div>
    </div>
  );
};
