import React from "react";
import { AdminTextInput } from "../../admin/AdminTextInput";

type Props = {
  className?: string;
  name: string | undefined;
  onChange: (name: string) => void;
  wasEdited: boolean;
};

export const CompanyName: React.FC<Props> = ({
  className,
  name,
  onChange,
  wasEdited,
}) => {
  return (
    <div className={"flex w-full lg:w-1/2"}>
      <AdminTextInput
        title={"Betriebname"}
        setText={(text) => onChange(text)}
        placeholder={"Muster Hof"}
        text={name}
        hasChanged={wasEdited}
      />
    </div>
  );
};
