import React from "react";
import { FilterIcon } from "@heroicons/react/solid";
import { motion } from "framer-motion";
import { Popover } from "@headlessui/react";
import { FilterPopup } from "./FilterPopup";
import { Category } from "../../api/data-contracts";

type Props = {
  className?: string;
  isFiltering: boolean;
  toggleFilter: (filter: Category) => void;
  activeFilters: Category[];
};

export const NavigationBarFilter: React.FC<Props> = ({
  className,
  isFiltering,
  toggleFilter,
  activeFilters,
}) => {
  return (
    <Popover className="relative">
      {/* <Popover.Overlay className="fixed inset-0 bg-black opacity-30" /> */}
      <Popover.Button className="flex flex-row gap-2 uppercase tracking-wider font-bold text-lg text-text items-center justify-center cursor-pointer hover:opacity-70 transition-all outline-none">
        <div className="flex justify-center items-center h-5 w-5 bg-accent rounded-full">
          <FilterIcon className="h-3 w-3 text-backgroundSecondary" />
        </div>
        <div className="flex flex-col">
          <div className="flex flex-row gap-2 items-center">Filter</div>
          {isFiltering && (
            <motion.div
              className="h-1 w-full bg-text rounded-full"
              initial={{ opacity: 0, scaleX: 0 }}
              animate={{ opacity: 1, scaleX: 1 }}
              transition={{ duration: 0.1 }}
            />
          )}
        </div>
      </Popover.Button>

      <FilterPopup
        toggleFilter={(filter) => toggleFilter(filter)}
        activeFilters={activeFilters}
      />
    </Popover>
  );
};
