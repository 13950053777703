import React from "react";
import { UserCreate } from "../../api/data-contracts";
import { TextInputWithoutState } from "../common/TextInput";
import { Toggle } from "../common/Toggle";

type Props = {
  className?: string;
  data: UserCreate;
  setData: (value: React.SetStateAction<UserCreate>) => void;
};

export const UserRegister: React.FC<Props> = ({ className, data, setData }) => {
  return (
    <div className={"flex flex-col gap-4 h-full w-full"}>
      <TextInputWithoutState
        title={"E-Mail"}
        placeholder={"max@mustermann.at"}
        text={data.username ?? ""}
        setText={(text) =>
          setData((prev) => ({
            ...prev,
            username: text,
          }))
        }
      />
      <TextInputWithoutState
        title={"Passwort"}
        placeholder={"************"}
        text={data.password ?? ""}
        setText={(text) =>
          setData((prev) => ({
            ...prev,
            password: text,
          }))
        }
        type={"password"}
      />
      <div className="flex flex-row gap-8 w-full">
        <TextInputWithoutState
          title={"Vorname"}
          placeholder={"Max"}
          text={data.firstName ?? ""}
          setText={(text) =>
            setData((prev) => ({
              ...prev,
              firstName: text,
            }))
          }
        />
        <TextInputWithoutState
          title={"Nachname"}
          placeholder={"Mustermann"}
          text={data.lastName ?? ""}
          setText={(text) =>
            setData((prev) => ({
              ...prev,
              lastName: text,
            }))
          }
        />
      </div>
      <TextInputWithoutState
        title={"Telefonnummer"}
        placeholder={"+43 660 000000"}
        text={data.phone ?? ""}
        setText={(text) =>
          setData((prev) => ({
            ...prev,
            phone: text,
          }))
        }
        type={"tel"}
      />
      <div className="flex flex-row gap-8 w-full">
        <div className="w-full flex flex-col">
          <div className="text-accent font-semibold text-sm text-left mb-1 flex flex-row gap-2 items-center">
            Benachrichtigungen
          </div>
          <Toggle
            active={data.notification}
            onChange={(active) =>
              setData((prev) => ({
                ...prev,
                notification: active,
              }))
            }
          />
        </div>
        <div className="w-full flex flex-col">
          <div className="text-accent font-semibold text-sm text-left mb-1 flex flex-row gap-2 items-center">
            E-Mail Newsletter
          </div>
          <Toggle
            active={data.newsletter}
            onChange={(active) =>
              setData((prev) => ({
                ...prev,
                newsletter: active,
              }))
            }
          />
        </div>
      </div>
    </div>
  );
};
