import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../api/AuthContext";
import { AnimatedButton } from "../components/common/AnimatedButton";
import {
  TextInputWithoutState,
} from "../components/common/TextInput";
import { hasLowerAndUppercase, hasNumber } from "../util";

export default function ResetPassword() {
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [loading, setLoading] = useState(false);
  const { api } = useAuth();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const navigate = useNavigate();
  const goToLoginPage = () => navigate("/login");

  return (
    <div
      className={
        "flex flex-col justify-center items-center h-screen w-full bg-background"
      }
    >
      <div className="fixed top-0 items-center bg-backgroundSecondary w-full h-16 pl-8">
        <img
          src="/img/gutesfinden_logo_web.svg"
          alt="Logo"
          className="h-8 mt-4"
        />
      </div>

      <div className="bg-backgroundSecondary p-16 w-2/3">
        <div className="flex flex-col w-full h-full items-center">
          <div className="flex flex-col w-full items-center gap-6">
            <TextInputWithoutState
              title={"Neues Passwort"}
              placeholder={"Neues Passwort"}
              text={password}
              setText={(text) => setPassword(text)}
              type={"password"}
            />
            {(password.length < 10 ||
              !hasNumber(password) ||
              !hasLowerAndUppercase(password)) && (
              <div className="flex flex-col items-start gap-0.5 w-full ">
                {password.length < 10 && (
                  <p className="text-red text-xs font-semibold">
                    • Mindestens 10 Zeichen
                  </p>
                )}
                {!hasLowerAndUppercase(password) && (
                  <p className="text-red text-xs font-semibold">
                    • Groß und Kleinschreibung fehlt
                  </p>
                )}

                {!hasNumber(password) && (
                  <p className="text-red text-xs font-semibold">• Zahl fehlt</p>
                )}
              </div>
            )}
            <TextInputWithoutState
              title={"Passwort wiederholen"}
              placeholder={"Neues Passwort Wiederholen"}
              text={password2}
              setText={(text) => setPassword2(text)}
              type={"password"}
            />
            {password !== password2 && password2 !== "" && (
              <p className="text-red text-xs font-semibold text-left w-full">
                Passwörter stimmen nicht überein!
              </p>
            )}
            <AnimatedButton
              onClick={async () => {
                setLoading(true);
                try {
                  await api.setPassword({
                    token: token ?? undefined,
                    password: password,
                  });
                  goToLoginPage();
                  setLoading(false);
                } catch (response: any) {
                  console.log(response);
                  setLoading(false);
                }
              }}
              active={
                password === password2 &&
                password !== "" &&
                hasNumber(password) &&
                hasLowerAndUppercase(password) &&
                password.length >= 10 &&
                token !== undefined &&
                token !== null &&
                token?.length > 0
              }
              className={"w-full"}
            >
              {"Passwort speichern"}
            </AnimatedButton>
          </div>
        </div>
      </div>
    </div>
  );
}
