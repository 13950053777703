import { useState, useEffect } from "react";

export const usePosition = () => {
  const [position, setPosition] = useState<{
    latitude: number;
    longitude: number;
  } | null>();
  const [positionError, setPositionError] = useState<string>();

  const onChange = ({ coords }: any) => {
    setPosition({
      latitude: coords.latitude,
      longitude: coords.longitude,
    });
  };
  const onError = (positionError: any) => {
    setPositionError(positionError.message);
  };
  useEffect(() => {
    const geo = navigator.geolocation;
    if (!geo) {
      setPositionError("Geolocation is not supported");
      return;
    }
    let watcher = geo.watchPosition(onChange, onError);
    return () => geo.clearWatch(watcher);
  }, []);
  return { ...position, positionError };
};
