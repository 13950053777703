import { PencilAltIcon, TrashIcon } from "@heroicons/react/solid";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../api/AuthContext";
import {
  Company,
  LocationDto,
  Store,
  StoreCreate,
} from "../api/data-contracts";
import { AdminTopBar } from "../components/admin/AdminTopBar";
import { CompanyCategory } from "../components/admin/detail/CompanyCategory";
import { CompanyDescription } from "../components/admin/detail/CompanyDescription";
import { CompanyImages } from "../components/admin/detail/CompanyImages";
import { CompanyName } from "../components/admin/detail/CompanyName";
import { CompanyStores } from "../components/admin/detail/CompanyStores";
import { CompanyTags } from "../components/admin/detail/CompanyTags";
import { AnimatedButton } from "../components/common/AnimatedButton";
import { ArrowButton } from "../components/common/ArrowButton";
import { LoadingIndicator } from "../components/common/LoadingIndicator";
import { MD } from "../constants";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { useOnClickOutside } from "../hooks/useOnClickOutside";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { classNames, showError } from "../util";

type Props = {
  className?: string;
};

export const AdminDetail: React.FC<Props> = ({ className }) => {
  const { api, me } = useAuth();
  const { id } = useParams();

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    me.me().then((me) => {
      if (me.data.admin) {
        setIsAdmin(true);
      }
    });
  }, [me]);

  const [loading, setLoading] = useState(true);

  const [company, setCompany] = useState<Company>();
  const [editedCompany, setEditedCompany] = useState<Company>();

  const [stores, setStores] = useState<Store[]>();
  const [editedStores, setEditedStores] = useState<Store[]>();

  const [nameEdited, setNameEdited] = useState(false);
  const [descriptionEdited, setDescriptionEdited] = useState(false);
  const [tagsEdited, setTagsEdited] = useState(false);

  const [imagesEdited, setImagesEdited] = useState(false);
  const [imagesToRemoveIds, setImagesToRemoveIds] = useState<string[]>([]);
  const [imagesToUpload, setImagesToUpload] = useState<File[]>([]);

  const [categoriesEdited, setCategoriesEdited] = useState(false);
  const [storesEdited, setStoresEdited] = useState(false);

  useEffect(() => {
    if (!id) {
      return;
    }

    setLoading(true);
    api
      .getDetail(id)
      .then((res) => {
        setCompany(res.data as Company);
        setEditedCompany(res.data as Company);
      })
      .catch((err) => {
        console.log(err);
        setCompany(undefined);
      });

    api
      .stores(id)
      .then((res) => {
        setStores(res.data as Store[]);
        setEditedStores(res.data as Store[]);
      })
      .catch((err) => {
        console.log(err);
        setStores(undefined);
        setEditedStores(undefined);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [api, id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  function back() {
    navigate(-1);
  }

  function nameChanged(name: string) {
    setEditedCompany((prev) => ({
      ...prev!,
      ...{ id: prev?.id ?? "", name: name },
    }));
  }

  function descriptionChanged(description: string) {
    setEditedCompany((prev) => ({
      ...prev!,
      ...{ id: prev?.id ?? "", description: description },
    }));
  }

  function tagsChanged(tags: string[]) {
    setEditedCompany((prev) => ({
      ...prev!,
      ...{ id: prev?.id ?? "", tags: tags },
    }));
  }

  function imagesChanged(imageIds: string[]) {
    setEditedCompany((prev) => ({
      ...prev!,
      ...{ id: prev?.id ?? "", imageIds: imageIds },
    }));
  }

  function categoriesChanged(categoryIds: string[]) {
    setEditedCompany((prev) => ({
      ...prev!,
      ...{ id: prev?.id ?? "", categoryIds: categoryIds },
    }));
  }

  function storesChanged(stores: Store[]) {
    setEditedStores(stores);
  }

  useEffect(() => {
    setNameEdited(
      JSON.stringify(editedCompany?.name) !== JSON.stringify(company?.name)
    );
    setDescriptionEdited(
      JSON.stringify(editedCompany?.description) !==
        JSON.stringify(company?.description)
    );
    setTagsEdited(
      JSON.stringify(editedCompany?.tags) !== JSON.stringify(company?.tags)
    );
    setImagesEdited(imagesToRemoveIds.length > 0 || imagesToUpload.length > 0);
    setCategoriesEdited(
      JSON.stringify(editedCompany?.categoryIds) !==
        JSON.stringify(company?.categoryIds)
    );
    setStoresEdited(JSON.stringify(stores) !== JSON.stringify(editedStores));
  }, [
    editedCompany,
    company,
    imagesToRemoveIds.length,
    imagesToUpload.length,
    stores,
    editedStores,
  ]);

  async function save() {
    if (!company?.id) {
      return;
    }

    await api.updateCompany(company.id, {
      name: editedCompany?.name ?? "",
      description: editedCompany?.description ?? "",
      tags: editedCompany?.tags ?? [],
      categoryIds: editedCompany?.categoryIds ?? [],
    });
    //setEditedCompany(undefined);

    // Upload new imgaes
    for (let i = 0; i < imagesToUpload.length; i++) {
      await api
        .addCompanyImage(company?.id, { image: imagesToUpload[i] })
        .catch((error) => showError(error.toString()));
    }
    setImagesToUpload([]);

    //remove old images
    for (let i = 0; i < imagesToRemoveIds.length; i++) {
      await api.deleteCompanyImage(company?.id, {
        imageId: imagesToRemoveIds[i],
      });
    }
    setImagesToRemoveIds([]);

    const storesToDelete: Store[] = (stores ?? []).filter(
      (store) => !(editedStores ?? []).includes(store)
    );
    //remove old stores
    for (let i = 0; i < storesToDelete.length; i++) {
      await api.deleteStore(storesToDelete[i].id);
    }

    let storesToUpload: Store[] = (editedStores ?? []).filter(
      (store) => !(stores ?? []).includes(store)
    );
    //upload new stores
    for (let i = 0; i < storesToUpload.length; i++) {
      let newStore: StoreCreate = {
        title: storesToUpload[i].title,
        street: storesToUpload[i].street,
        postCode: storesToUpload[i].postCode,
        town: storesToUpload[i].town,
        phone: storesToUpload[i].phone,
        email: storesToUpload[i].email,
        website: storesToUpload[i].website,
        location: storesToUpload[i].location as LocationDto,
        companyId: storesToUpload[i].companyId,
      };
      await api.createStore(newStore);
    }

    setNameEdited(false);
    setDescriptionEdited(false);
    setTagsEdited(false);
    setImagesEdited(false);
    setCategoriesEdited(false);
    setStoresEdited(false);
  }

  const [openDeleteConfirmModal, setOpenDeleteConfirmModal] = useState(false);

  const confirmModalRef = useRef(null);
  useOnClickOutside(confirmModalRef, () => setOpenDeleteConfirmModal(false));

  function deleteCompany() {
    company?.id &&
      api.deleteCompany(company?.id).then(() => {
        navigate("/manage");
      });
  }

  const { width } = useWindowDimensions();

  const [newCompany, setNewCompany] = useLocalStorage(
    "gutesfinden.new_company_added",
    false
  );

  return (
    <div>
      {/* Background everywhere */}
      <div className="bg-background fixed top-0 -z-10 left-0 w-full h-full flex justify-center items-center" />

      {/* New company popup */}
      {newCompany && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-50 flex flex-col items-center justify-center"
          onClick={() => setNewCompany(false)}
        >
          <div className="p-8 mx-16 bg-backgroundSecondary text-text font-normal">
            <p className="text-lg font-semibold mb-2">
              Danke für die Erstellung deines Betriebes
            </p>
            <p>Wir werden deine Daten so schnell wie möglich prüfen</p>
            <p>und deinen Betrieb anschließend für die Apps freigeben.</p>
            <div className="h-4" />
            <AnimatedButton
              children={<p>Ok</p>}
              onClick={() => setNewCompany(false)}
            />
          </div>
        </div>
      )}

      {openDeleteConfirmModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex flex-col items-center justify-center">
          <div
            className="py-4 px-6 rounded bg-backgroundSecondary flex flex-col gap-2"
            ref={confirmModalRef}
          >
            <p className="text-text font-bold text-xl">
              Bist du dir sicher, dass du den Betrieb löschen willst?
            </p>
            <p className="text-text opacity-70 text-lg">
              Nachdem der Betrieb gelöscht wurde können <br />
              die Daten nicht wiederhergestellt werden.
            </p>
            <div className="w-full justify-center flex flex-row gap-4 pt-4">
              <AnimatedButton
                children={<p>Abbrechen</p>}
                onClick={() => setOpenDeleteConfirmModal(false)}
                outline
              />
              <AnimatedButton
                children={<p>Ja, Betrieb löschen</p>}
                onClick={() => {
                  deleteCompany();
                  setOpenDeleteConfirmModal(false);
                }}
                active={true}
                red
              />
            </div>
          </div>
        </div>
      )}

      <div
        className={classNames(
          "flex flex-1 flex-col bg-background",
          loading && "justify-center h-screen"
        )}
      >
        {loading ? (
          <LoadingIndicator />
        ) : (
          <>
            <AdminTopBar isAdmin={false} />
            <div className="flex flex-col justify-start gap-8 w-full px-4 overflow-x-hidden md:px-16 pb-16 pt-8">
              <div className="flex flex-row justify-between h-12">
                <ArrowButton onClick={back} direction="left">
                  <p className="text-sm text-text font-bold items-center align-middle">
                    Zur Übersicht
                  </p>
                </ArrowButton>
                {(nameEdited ||
                  descriptionEdited ||
                  tagsEdited ||
                  imagesEdited ||
                  storesEdited ||
                  categoriesEdited) && (
                  <div className="flex flex-0 items-center">
                    <AnimatedButton
                      children={
                        <div className="flex flex-row gap-2 items-center justify-center h-full">
                          <PencilAltIcon className="w-4 h-4" />
                          {width >= MD ? (
                            <p className="mt-1">Änderungen Speichern</p>
                          ) : (
                            <p className="mt-1">Speichern</p>
                          )}
                          <p className="mt-1"></p>
                        </div>
                      }
                      onClick={() => save()}
                      yellow
                    />
                  </div>
                )}
              </div>
              <CompanyName
                name={editedCompany?.name}
                onChange={(name) => nameChanged(name)}
                wasEdited={nameEdited}
              />
              <CompanyDescription
                description={editedCompany?.description}
                onChange={(name) => descriptionChanged(name)}
                wasEdited={descriptionEdited}
              />
              <div className="flex flex-0">
                <CompanyCategory
                  categoryIds={editedCompany?.categoryIds}
                  onChange={(categoryIds) => categoriesChanged(categoryIds)}
                  wasEdited={categoriesEdited}
                />
              </div>
              <CompanyImages
                imageIds={editedCompany?.imageIds}
                onChange={(imageIds) => imagesChanged(imageIds)}
                onRemoveId={(id) =>
                  setImagesToRemoveIds((prev) => [...prev, id])
                }
                images={imagesToUpload}
                onRemoveImage={(image) =>
                  setImagesToUpload((prev) => prev.filter((i) => i !== image))
                }
                onAddImage={(image) =>
                  setImagesToUpload((prev) => [...prev, image])
                }
                wasEdited={imagesEdited}
              />
              <CompanyTags
                tags={editedCompany?.tags}
                onChange={(tags) => tagsChanged(tags)}
                wasEdited={tagsEdited}
              />
              <CompanyStores
                stores={stores}
                company={company}
                onChange={(stores) => storesChanged(stores)}
                wasEdited={storesEdited}
              />
              {isAdmin && (
                <div
                  className="text text-red font-semibold py-4 text-start hover:opacity-70 cursor-pointer flex flex-row gap-1 items-center"
                  onClick={() => setOpenDeleteConfirmModal(true)}
                >
                  <TrashIcon className="w-4 pb-1" />
                  Betrieb löschen
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
